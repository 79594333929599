import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef } from "react";
import AppStore from "../../../assets/svgs/GoldtagAppSvgs/AppStore";
import GoogleStore from "../../../assets/svgs/GoldtagAppSvgs/GoogleStore";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import {
  boxFrame,
  customGoldtag,
  customSubTitle,
  indir,
  regular643838,
} from "./TopTypoCSS";
import {
  appStrGgleStrBox,
  whitesemibold16,
} from "../../../assets/styles/GeneralStyles";

function TopTypoComponent(props) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const leftTextRef = useRef(null);
  const rightTextRef = useRef(null);
  const box1Ref = useRef(null);
  const box2Ref = useRef(null);
  const goldtagTypo = useRef(null);
  const indrBox = useRef(null);

  useGSAP(() => {
    const tl = gsap.timeline({ defaults: { ease: "sine.inOut" } });

    if (box1Ref.current && box2Ref.current) {
      tl.fromTo(
        [box1Ref.current, box2Ref.current],
        { opacity: 0 },
        { opacity: 1, duration: 3 }
      );
    }

    gsap.from(leftTextRef.current, {
      opacity: 0,
      x: -200,
      duration: 0.6,
      ease: "circ.inOut",
    });

    gsap.from(rightTextRef.current, {
      opacity: 0,
      x: 200,
      duration: 0.6,
      ease: "circ.inOut",
    });

    if (goldtagTypo.current) {
      gsap.from(goldtagTypo.current, {
        opacity: 0,
        y: -100,
        duration: 2,
        ease: "circ.inOut",
      });
    }

    if (indrBox.current) {
      tl.fromTo([indrBox.current], { opacity: 0 }, { opacity: 1, duration: 1 });
    }
  }, [box1Ref, box2Ref, goldtagTypo, indrBox]);

  return (
    <>
      {/* -----------------Birikimin Geleceği--------------- */}
      <Typography sx={regular643838} ref={leftTextRef}>
        Birikimin Geleceği
      </Typography>
      <Typography sx={customGoldtag} ref={goldtagTypo}>
        Goldtag
      </Typography>
      <Box ref={rightTextRef}>
        <Typography sx={customSubTitle}>
          Birikim yapmanın yeni ve dijital uygulaması.
        </Typography>
        <Typography sx={customSubTitle}>Türk liranıza değer katın!</Typography>
      </Box>

      {/* -------------Store icons---------------- */}
      {!isMobile ? (
        <Box sx={boxFrame}>
          <Box sx={appStrGgleStrBox} ref={box1Ref}>
            <AppStore />
          </Box>
          <Box sx={appStrGgleStrBox} ref={box2Ref}>
            <GoogleStore />
          </Box>
        </Box>
      ) : (
        <Box sx={indir} ref={indrBox}>
          <Typography sx={whitesemibold16}>Uygulamayı İndir</Typography>
        </Box>
      )}
    </>
  );
}

export default TopTypoComponent;
