export const headerBox = {
  width: "222px",
  height: "40px",
  background: "rgb(247,242,233)",
  background:
    "linear-gradient(90deg, rgba(247,242,233,1) 0%, rgba(243,238,228,1) 100%)",
  borderRadius: "20px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  alignSelf: "center",
  marginTop: { xs: "32px", sm: "120px", md: "120px" },
};

export const headerTitle = {
  fontSize: { xs: "12px", sm: "14px", md: "14px" },
  color: "##383838",
  fontFamily: "Regular",
};
