import * as React from "react";
const LocationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#D6B87C"
      fillRule="evenodd"
      d="m12 21 .708.706a1 1 0 0 1-1.417 0l-.006-.007-.017-.017-.062-.063a49.562 49.562 0 0 1-3.496-4.014c-.892-1.15-1.804-2.45-2.497-3.734C4.535 12.612 4 11.248 4 10c0-4.539 3.592-8 8-8 4.408 0 8 3.461 8 8 0 1.248-.535 2.612-1.213 3.87-.693 1.286-1.604 2.585-2.497 3.735a49.571 49.571 0 0 1-3.496 4.014l-.062.063-.017.017-.006.006L12 21Zm0-8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default LocationIcon;
