export const secureContainer = {
  marginTop: { xs: "40px", sm: "80px", md: "80px" },
  alignSelf: "center",
  display: "flex",
  flexDirection: { xs: "column", sm: "row", md: "row" },
  marginLeft: { xs: "0px", sm: "170px", md: "170px" },
  marginBottom: { xs: 6, sm: 0, md: 0 },
  alignItems: "flex-start",
  justifyContent: "flex-start",
};

export const leftFrame = {
  display: "flex",
  flexDirection: "column",
  alignSelf: "center",
  width: { xs: "343px", sm: "90%", md: "90%" },
  marginBottom: "auto",
};

export const title = {
  fontFamily: "Semibold",
  lineHeight: { xs: "31.2px", sm: "64px", md: "64px" },
  color: "#383838",
  fontSize: { xs: "24px", sm: "48px", md: "48px" },
  width: { xs: "343px", sm: "568px", md: "568px" },
  marginTop: "16px",
  marginBottom: "16px",
};

export const subText = {
  fontFamily: "Regular",
  lineHeight: { xs: "20px", sm: "28px", md: "28px" },
  textAlign: "justify",
  color: "#383838",
  fontSize: { xs: "14px", sm: "18px", md: "18px" },
  width: { xs: "343px", sm: "570px", md: "570px" },
};

export const frame34 = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginTop: "24px",
  width: { xs: "343px", sm: "570px", md: "570px" },
};

export const imageStyle = {
  marginRight: "10px",
  width: { xs: "85.83px", sm: "103px", md: "103px" },
  height: { xs: "20px", sm: "26px", md: "26px" },
};

export const bigtitle = {
  fontFamily: "Medium",
  lineHeight: "26px",
  fontSize: { xs: "20px", sm: "32px", md: "32px" },
  color: "#383838",
  textAlign: "center",
  marginBottom: "8px",
};

export const subTtle = {
  fontFamily: "Regular",
  lineHeight: "26px",
  fontSize: { xs: "14px", sm: "16px", md: "16px" },
  color: "#383838",
  textAlign: "center",
};

export const marginTop48 = {
  display: "flex",
  flexDirection: "row",
  marginTop: "48px",
};

export const imgS = {
  position: "absolute",
  alignSelf: "center",
};

export const secIm = { marginLeft: "100px" };
