import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useRef } from "react";

import { useNavigate } from "react-router-dom";
import { ArrowDownK, ArrowUpK } from "../../assets/svgs/FooterSvgs";
import {
  regular12,
  regular14,
  regular16,
} from "../../assets/styles/defaultFonts";
import {
  amountStyle,
  bigCon,
  boxContainer,
  boxStyleBorderMobile,
  boxStyleMobile,
  dvdr,
  frm,
  inCon,
  incoNbor,
  intit,
  limcon,
  mobbox,
  mobcontainer,
  mobtit,
  mrgn,
  sectionSel,
  stn,
  stndvdr,
  stnframe,
  stnframe2,
  stnframe3,
  subPageTitle,
  subTitleStyleMobile,
  subtt,
  titccb382,
  titleStyleMobile,
  ucrtsz,
} from "./UcretlerVeLimitlerCSS";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

function UcretlerLimitler(props) {
  const navigate = useNavigate();

  const [state, setState] = React.useState("İşlemÜcretleri");
  const [limitState, setLimitState] = React.useState("Standart");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (item) => {
    setAnchorEl(null);
    setLimitState(item.toString());
  };

  const boxRef1 = useRef(null);

  const boxRef2 = useRef(null);
  const boxRef3 = useRef(null);
  const boxRef4 = useRef(null);
  const boxRef5 = useRef(null);

  const leftTextRef = useRef(null);
  const secRef = useRef(null);

  useGSAP(() => {
    gsap.from(leftTextRef.current, {
      opacity: 0,
      x: -200,
      duration: 0.6,
      ease: "circ.inOut",
    });

    gsap.from(secRef.current, {
      opacity: 0,
      duration: 2,
      ease: "circ.inOut",
    });

    gsap.from(boxRef1.current, {
      opacity: 0,
      x: -300,
      duration: 1,
      ease: "power3.in",

      scrollTrigger: {
        trigger: boxRef1.current,
        start: "center 100%",
        toggleActions: "play none none none",
      },
    });

    gsap.from(boxRef2.current, {
      opacity: 0,
      x: 300,
      duration: 1,

      ease: "power3.in",
      scrollTrigger: {
        trigger: boxRef2.current,
        start: "center bottom",
        toggleActions: "play none none none",
      },
    });
    gsap.from(boxRef3.current, {
      opacity: 0,
      x: -300,
      duration: 1,

      ease: "power3.in",

      scrollTrigger: {
        trigger: boxRef4.current,
        start: "center bottom",
        toggleActions: "play none none none",
      },
    });
    gsap.from(boxRef4.current, {
      opacity: 0,
      x: 300,
      duration: 1,

      ease: "power3.in",
      scrollTrigger: {
        trigger: boxRef4.current,
        start: "center bottom",
        toggleActions: "play none none none",
      },
    });

    gsap.from(boxRef5.current, {
      opacity: 0,
      x: -300,
      duration: 1,

      ease: "power3.in",
      scrollTrigger: {
        trigger: boxRef5.current,
        start: "center bottom",
        toggleActions: "play none none none",
      },
    });
  }, []);

  return (
    <>
      {isMobile ? (
        <Box style={mobcontainer}>
          <Typography sx={mobtit} ref={leftTextRef}>
            Ücretler ve Limitler
          </Typography>

          <Box sx={mobbox}>
            <Box
              sx={[
                sectionSel,
                {
                  width: "168px",
                  background: state == "İşlemÜcretleri" ? "#CEB483" : "white",
                },
              ]}
              onClick={() => setState("İşlemÜcretleri")}
            >
              <Typography
                sx={[
                  regular12,
                  {
                    color: state == "İşlemÜcretleri" ? "white" : "#383838",
                  },
                ]}
              >
                İşlem Ücretleri
              </Typography>
            </Box>
            <Box
              sx={[
                sectionSel,
                {
                  width: "168px",
                  background: state == "Limitler" ? "#CEB483" : "white",
                },
              ]}
              onClick={() => setState("Limitler")}
            >
              <Typography
                sx={[
                  regular12,
                  {
                    color: state == "Limitler" ? "white" : "#383838",
                  },
                ]}
              >
                Limitler
              </Typography>
            </Box>
          </Box>

          {state == "İşlemÜcretleri" && (
            <Box
              sx={{
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                width: "343px",
              }}
            >
              <Box sx={boxStyleMobile}>
                <Box>
                  <Typography sx={titleStyleMobile}>
                    Goldtag App Üyelik Ücreti
                  </Typography>
                </Box>
                <Typography sx={amountStyle}>Ücretsiz</Typography>
              </Box>

              <Box sx={boxStyleBorderMobile}>
                <Box>
                  <Typography sx={titleStyleMobile}>
                    Etkinlik Oluşturma
                  </Typography>
                </Box>
                <Typography sx={amountStyle}>Ücretsiz</Typography>
              </Box>

              <Box sx={boxStyleMobile}>
                <Box>
                  <Typography sx={titleStyleMobile}>
                    Altın Alma İşlemleri
                  </Typography>
                </Box>
                <Typography sx={amountStyle}>Ücretsiz</Typography>
              </Box>

              <Box sx={boxStyleBorderMobile}>
                <Box>
                  <Typography sx={titleStyleMobile}>
                    Altın Bozdurma İşlemleri
                  </Typography>
                </Box>
                <Typography sx={amountStyle}>Ücretsiz</Typography>
              </Box>

              <Box sx={boxStyleMobile}>
                <Box>
                  <Typography sx={titleStyleMobile}>
                    Uygulama içi Altın Transferleri
                  </Typography>
                </Box>
                <Typography sx={amountStyle}>Ücretsiz</Typography>
              </Box>
            </Box>
          )}

          {state == "Limitler" && (
            <Box
              sx={{
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                width: "343px",
              }}
            >
              <Box sx={boxStyleBorderMobile} onClick={handleClick}>
                <Box>
                  <Typography
                    sx={[
                      regular12,
                      {
                        color: "#9C9CAB",
                        marginLeft: "16px",
                        marginTop: "8px",
                      },
                    ]}
                  >
                    Hesap Türü
                  </Typography>
                  <Typography
                    sx={[
                      titleStyleMobile,
                      {
                        fontSize: "16px",
                        marginBottom: "8px",
                        marginTop: 0,
                      },
                    ]}
                  >
                    {limitState}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginRight: "16px",
                    animation: "fadeIn 1s ease-in-out forwards",
                  }}
                >
                  {anchorEl == null ? (
                    <Box
                      sx={{
                        animation: "fadeIn 1s ease-in-out forwards",
                      }}
                    >
                      <ArrowDownK />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        animation: "fadeIn 1s ease-in-out forwards",
                      }}
                    >
                      <ArrowUpK />
                    </Box>
                  )}
                </Box>
              </Box>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => handleClose("Standart")}>
                  Doğrulanmamış Hesap
                </MenuItem>
                <MenuItem onClick={() => handleClose("Onaylı")}>
                  Doğrulanmış Hesap (KYC)
                </MenuItem>
              </Menu>

              {limitState == "Standart" && (
                <>
                  <Box sx={boxStyleMobile}>
                    <Box>
                      <Typography sx={titleStyleMobile}>
                        Altın Transferi
                      </Typography>
                      <Typography sx={subTitleStyleMobile}>
                        Goldtag kullanıcıları arası altın transferi limiti
                      </Typography>
                    </Box>

                    <Typography sx={amountStyle}>1 Gr</Typography>
                  </Box>

                  <Box sx={boxStyleBorderMobile}>
                    <Box>
                      <Typography sx={titleStyleMobile}>
                        Gümüş Transferi
                      </Typography>
                      <Typography sx={subTitleStyleMobile}>
                        Goldtag kullanıcıları arası gümüş transferi limiti
                      </Typography>
                    </Box>
                    <Typography sx={amountStyle}>50 Gr</Typography>
                  </Box>

                  <Box sx={boxStyleMobile}>
                    <Box>
                      <Typography sx={titleStyleMobile}>
                        Platin Transferi
                      </Typography>
                      <Typography sx={subTitleStyleMobile}>
                        Goldtag kullanıcıları arası patin transferi limiti
                      </Typography>
                    </Box>
                    <Typography sx={amountStyle}>2 Gr</Typography>
                  </Box>

                  <Box sx={boxStyleBorderMobile}>
                    <Box>
                      <Typography sx={titleStyleMobile}>
                        Para Yükleme
                      </Typography>
                      <Typography sx={subTitleStyleMobile}>
                        Goldtag hesabına para yükleme limiti
                      </Typography>
                    </Box>
                    <Typography sx={amountStyle}>2750₺</Typography>
                  </Box>

                  <Box sx={boxStyleMobile}>
                    <Box>
                      <Typography sx={titleStyleMobile}>Para Çekme</Typography>
                      <Typography sx={subTitleStyleMobile}>
                        Goldtag hesabından para çekme limiti
                      </Typography>
                    </Box>
                    <Typography sx={amountStyle}>2750₺</Typography>
                  </Box>

                  <Box sx={boxStyleBorderMobile}>
                    <Box>
                      <Typography sx={titleStyleMobile}>
                        Etkinlik Oluşturma
                      </Typography>
                      <Typography sx={subTitleStyleMobile}>
                        Altın günü , Düğün ve Giftbox etkinlikleri oluşturma
                        limiti
                      </Typography>
                    </Box>
                    <Typography sx={amountStyle}>SINIRSIZ</Typography>
                  </Box>

                  <Box sx={boxStyleMobile}>
                    <Box>
                      <Typography sx={titleStyleMobile}>
                        Kumbara / Ortak Kumbara Oluşturma
                      </Typography>
                      <Typography sx={subTitleStyleMobile}>
                        Kumbara ve Goldtag kullanıcıları ile ortak kumbara
                        oluşturma limiti
                      </Typography>
                    </Box>
                    <Typography sx={amountStyle}>SINIRSIZ</Typography>
                  </Box>
                </>
              )}

              {limitState == "Onaylı" && (
                <>
                  <Box sx={boxStyleMobile}>
                    <Box>
                      <Typography sx={titleStyleMobile}>
                        Altın Transferi
                      </Typography>
                      <Typography sx={subTitleStyleMobile}>
                        Goldtag kullanıcıları arası altın transferi limiti
                      </Typography>
                    </Box>

                    <Typography sx={[amountStyle]}>500 Gr</Typography>
                  </Box>

                  <Box sx={boxStyleBorderMobile}>
                    <Box>
                      <Typography sx={titleStyleMobile}>
                        Gümüş Transferi
                      </Typography>
                      <Typography sx={subTitleStyleMobile}>
                        Goldtag kullanıcıları arası gümüş transferi limiti
                      </Typography>
                    </Box>
                    <Typography sx={[amountStyle, { fontSize: 11 }]}>
                      50.000 Gr
                    </Typography>
                  </Box>

                  <Box sx={boxStyleMobile}>
                    <Box>
                      <Typography sx={titleStyleMobile}>
                        Platin Transferi
                      </Typography>
                      <Typography sx={subTitleStyleMobile}>
                        Goldtag kullanıcıları arası patin transferi limiti
                      </Typography>
                    </Box>
                    <Typography sx={[amountStyle, { fontSize: 11 }]}>
                      1.000 Gr
                    </Typography>
                  </Box>

                  <Box sx={boxStyleBorderMobile}>
                    <Box>
                      <Typography sx={titleStyleMobile}>
                        Para Yükleme
                      </Typography>
                      <Typography sx={subTitleStyleMobile}>
                        Goldtag hesabına para yükleme limiti
                      </Typography>
                    </Box>
                    <Typography sx={[amountStyle, { fontSize: 11 }]}>
                      1.000.000₺
                    </Typography>
                  </Box>

                  <Box sx={boxStyleMobile}>
                    <Box>
                      <Typography sx={titleStyleMobile}>Para Çekme</Typography>
                      <Typography sx={subTitleStyleMobile}>
                        Goldtag hesabından para çekme limiti
                      </Typography>
                    </Box>
                    <Typography sx={[amountStyle, { fontSize: 11 }]}>
                      1.000.000 ₺
                    </Typography>
                  </Box>

                  <Box sx={boxStyleBorderMobile}>
                    <Box>
                      <Typography sx={titleStyleMobile}>
                        Etkinlik Oluşturma
                      </Typography>
                      <Typography sx={subTitleStyleMobile}>
                        Altın günü , Düğün ve Giftbox etkinlikleri oluşturma
                        limiti
                      </Typography>
                    </Box>
                    <Typography sx={amountStyle}>SINIRSIZ</Typography>
                  </Box>

                  <Box sx={boxStyleMobile}>
                    <Box>
                      <Typography sx={titleStyleMobile}>
                        Kumbara / Ortak Kumbara Oluşturma
                      </Typography>
                      <Typography sx={subTitleStyleMobile}>
                        Kumbara ve Goldtag kullanıcıları ile ortak kumbara
                        oluşturma limiti
                      </Typography>
                    </Box>
                    <Typography sx={amountStyle}>SINIRSIZ</Typography>
                  </Box>
                </>
              )}
            </Box>
          )}
        </Box>
      ) : (
        <>
          <Typography sx={subPageTitle} ref={leftTextRef}>
            Ücretler ve Limitler
          </Typography>

          <Box sx={boxContainer} ref={secRef}>
            <Box
              sx={[
                sectionSel,
                {
                  background: state == "İşlemÜcretleri" ? "#CEB483" : "white",
                },
              ]}
              onClick={() => setState("İşlemÜcretleri")}
            >
              <Typography
                sx={[
                  regular16,
                  {
                    color: state == "İşlemÜcretleri" ? "white" : "#383838",
                  },
                ]}
              >
                İşlem Ücretleri
              </Typography>
            </Box>
            <Box
              sx={[
                sectionSel,
                {
                  background: state == "Limitler" ? "#CEB483" : "white",
                },
              ]}
              onClick={() => setState("Limitler")}
            >
              <Typography
                sx={[
                  regular16,
                  {
                    color: state == "Limitler" ? "white" : "#383838",
                  },
                ]}
              >
                Limitler
              </Typography>
            </Box>
          </Box>

          {state == "İşlemÜcretleri" && (
            <Box sx={bigCon}>
              <Box sx={inCon} ref={boxRef1}>
                <Box>
                  <Typography sx={intit}>Üyelik Ücreti</Typography>
                </Box>
                <Typography sx={ucrtsz}>Ücretsiz</Typography>
              </Box>

              <Box sx={incoNbor} ref={boxRef2}>
                <Box>
                  <Typography sx={intit}>Etkinlik Oluşturma</Typography>
                </Box>
                <Typography sx={ucrtsz}>Ücretsiz</Typography>
              </Box>

              <Box sx={inCon} ref={boxRef3}>
                <Box>
                  <Typography sx={intit}>Altın Alma İşlemleri</Typography>
                </Box>
                <Typography sx={ucrtsz}>Ücretsiz</Typography>
              </Box>

              <Box sx={incoNbor} ref={boxRef4}>
                <Box>
                  <Typography sx={intit}>Altın Bozdurma İşlemleri</Typography>
                </Box>
                <Typography sx={ucrtsz}>Ücretsiz</Typography>
              </Box>

              <Box sx={inCon} ref={boxRef5}>
                <Box>
                  <Typography sx={intit}>
                    Uygulama içi Altın Transferleri
                  </Typography>
                </Box>
                <Typography sx={ucrtsz}>Ücretsiz</Typography>
              </Box>
            </Box>
          )}

          {state == "Limitler" && (
            <Box sx={limcon}>
              <Box sx={frm}>
                <Box sx={{ marginBottom: "12px" }}>
                  <Typography sx={intit}>Altın Transferi</Typography>
                  <Typography sx={subtt}>
                    Goldtag kullanıcıları arası altın transferi limiti
                  </Typography>
                </Box>

                <Box sx={dvdr} />

                <Box sx={mrgn}>
                  <Typography sx={intit}>Gümüş Transferi</Typography>
                  <Typography sx={subtt}>
                    Goldtag kullanıcıları arası gümüş transferi limiti
                  </Typography>
                </Box>

                <Box sx={dvdr} />

                <Box sx={mrgn}>
                  <Typography sx={intit}>Platin Transferi</Typography>
                  <Typography sx={subtt}>
                    Goldtag kullanıcıları arası altın transferi limiti
                  </Typography>
                </Box>

                <Box sx={dvdr} />

                <Box sx={mrgn}>
                  <Typography sx={intit}>Para Yükleme</Typography>
                  <Typography sx={subtt}>
                    Goldtag hesabına para yükleme limiti
                  </Typography>
                </Box>

                <Box sx={dvdr} />

                <Box sx={mrgn}>
                  <Typography sx={intit}>Para Çekme</Typography>
                  <Typography sx={subtt}>
                    Goldtag hesabından para çekme limiti
                  </Typography>
                </Box>

                <Box sx={dvdr} />

                <Box sx={mrgn}>
                  <Typography sx={intit}>Etkinlik Oluşturma</Typography>
                  <Typography sx={subtt}>
                    Altın günü , Düğün ve Giftbox etkinlikleri oluşturma limiti
                  </Typography>
                </Box>

                <Box sx={dvdr} />

                <Box sx={mrgn}>
                  <Typography sx={intit}>
                    Kumbara / Ortak Kumbara Oluşturma
                  </Typography>
                  <Typography sx={subtt}>
                    Kumbara ve Goldtag kullanıcıları ile ortak kumbara oluşturma
                    limiti
                  </Typography>
                </Box>
              </Box>
              {/* ******** SÜTUNLAR ********/}
              <Box sx={{ display: "flex", gap: "19px" }}>
                <Box sx={stn}>
                  <Typography sx={titccb382}>Doğrulanmamış Hesap</Typography>

                  <Box sx={stndvdr} />
                  <Box />
                  <Typography sx={regular14}>Aylık</Typography>
                  <Typography sx={intit}>1 Gr</Typography>

                  <Box sx={stnframe}>
                    <Typography sx={regular14}>Aylık</Typography>
                    <Typography sx={intit}>100 Gr</Typography>
                  </Box>

                  <Box sx={stnframe2}>
                    <Typography sx={regular14}>Aylık</Typography>
                    <Typography sx={intit}>2 Gr</Typography>
                  </Box>

                  <Box sx={stnframe2}>
                    <Typography sx={regular14}>Aylık</Typography>
                    <Typography sx={intit}>2750₺</Typography>
                  </Box>

                  <Box sx={stnframe}>
                    <Typography sx={regular14}>Aylık</Typography>
                    <Typography sx={intit}>2750₺</Typography>
                  </Box>

                  <Box sx={stnframe3}>
                    <Typography sx={intit}>SINIRSIZ</Typography>
                  </Box>

                  <Box sx={stnframe3}>
                    <Typography sx={intit}>SINIRSIZ</Typography>
                  </Box>
                </Box>

                <Box sx={stn}>
                  <Typography sx={titccb382}>
                    Doğrulanmış Hesap (KYC)
                  </Typography>

                  <Box sx={stndvdr} />
                  <Box />
                  <Typography sx={regular14}>Aylık</Typography>
                  <Typography sx={intit}>500 Gr</Typography>

                  <Box sx={stnframe}>
                    <Typography sx={regular14}>Aylık</Typography>
                    <Typography sx={intit}>50.000 Gr</Typography>
                  </Box>

                  <Box sx={stnframe2}>
                    <Typography sx={regular14}>Aylık</Typography>
                    <Typography sx={intit}>1.000 Gr</Typography>
                  </Box>

                  <Box sx={stnframe2}>
                    <Typography sx={regular14}>Aylık</Typography>
                    <Typography sx={intit}>1.000.000 ₺</Typography>
                  </Box>

                  <Box sx={stnframe}>
                    <Typography sx={regular14}>Aylık</Typography>
                    <Typography sx={intit}>1.000.000 ₺</Typography>
                  </Box>

                  <Box sx={stnframe3}>
                    <Typography sx={intit}>SINIRSIZ</Typography>
                  </Box>

                  <Box sx={regular14}>
                    <Typography sx={intit}>SINIRSIZ</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
}

export default UcretlerLimitler;
