import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { HelmetProvider } from "react-helmet-async";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBOP9liL1dYoeGXPsA5uKhbmGU1Mp9gQnU",
  authDomain: "goldtagappweb.firebaseapp.com",
  projectId: "goldtagappweb",
  storageBucket: "goldtagappweb.appspot.com",
  messagingSenderId: "148607976903",
  appId: "1:148607976903:web:d980fa55ade6c49e4405e2",
  measurementId: "G-0R7DD3Y51S",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>
);
