import * as React from "react";
const KT = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={103.012}
    height={23.998}
    {...props}
  >
    <path
      fill="#00684b"
      d="M0 12a12.06 12.06 0 0 1 2.007-6.668 11.858 11.858 0 0 1 18.327-1.818 12.046 12.046 0 0 1-1.805 18.459 11.846 11.846 0 0 1-15.04-1.492A12.042 12.042 0 0 1 0 12ZM46.187 9.137V12.2a3.169 3.169 0 0 0 .937 2.444l.292.32.306-.32a3.212 3.212 0 0 0 .919-2.444V9.137h2V12.2a5.624 5.624 0 0 1-2.119 4.479H46.3a5.624 5.624 0 0 1-2.119-4.479V9.137ZM27.817 7.387h2V11.1h.554l2.092-3.711h2.315l-2.131 3.782c1.784.224 2 1.006 2 2.507v3.007h-2v-2.971c0-.854-.476-.866-1.317-.866h-1.517v3.839h-2V7.393ZM72.294 10.887h-1.917v5.792h-2v-5.792h-1.915v-1.75H72.3ZM40.648 9.137h2v7.544h-6.455V9.137h1.993v5.895h2.455ZM57.594 10.786h-3.405v1.28h3.2v1.649h-3.2v1.309h3.475v1.649h-5.475V9.137h5.4ZM63.306 11.069V9.137h2V11.2c0 1.708-.676 2.372-2.223 2.532v2.948h-2v-2.95c-1.99-.186-2.223-.969-2.223-2.531V9.137h2v2.023c0 .854.476.864 1.32.864s1.137-.07 1.137-.96M84.543 14.035v-4.9h2v5.031c0 2.011-.939 2.576-3.137 2.576-3 0-3.314-.766-3.314-2.576V9.137h2v4.989c0 .854.477.865 1.318.865s1.14-.07 1.14-.96"
    />
    <path
      fill="#00684b"
      d="M84.543 14.035v-4.9h2v5.031c0 2.011-.939 2.576-3.137 2.576-3 0-3.314-.766-3.314-2.576V9.137h2v4.989c0 .854.477.865 1.318.865s1.14-.07 1.14-.96M96.065 9.137h2v2.836h.6l2.016-2.836h2.328l-2.065 2.911c1.747.23 1.962 1.013 1.962 2.5v2.132h-2v-2.092c0-.854-.477-.865-1.317-.865h-1.521v2.958h-2V9.137ZM92.5 16.681l-1.577-2.214h-.834v2.214h-2V9.137h6.431v5.33h-1.272l1.572 2.214Zm.025-5.794h-2.441v1.828h2.439ZM79.587 9.137h-2.395v7.544h-2V9.137H72.8v-1.75h6.79ZM84.543 8.44l1.048-1.055-1.048-1.056L83.5 7.385ZM82.083 8.438l1.048-1.055-1.048-1.056-1.048 1.056Z"
    />
    <path
      fill="#c99c00"
      d="m10.633 23.924 3.869-2.3-.738 2.24a12 12 0 0 1-1.816.136 11.745 11.745 0 0 1-1.315-.076Zm.81-11.766L5.837 8.543a3.754 3.754 0 0 0-2.074-.7H1.89l3.737-2.267a1.785 1.785 0 0 1 2.5.584Zm-10.807.535 1.2-1.5c.981-1.224 1.872-2.39 4.558-1l4.817 2.5Zm11.752-.534 3.322-6a1.791 1.791 0 0 1 1.127-.823 1.779 1.779 0 0 1 1.371.239l3.734 2.26H20.07a3.753 3.753 0 0 0-2.074.7Zm10.807.534h-10.58l4.815-2.5c2.686-1.392 3.576-.226 4.558 1Zm-11.275-.662s-2-5.8-2.377-6.955a1.272 1.272 0 0 1 .08-1.276l2.29-3.02L14.2 3.8a1.274 1.274 0 0 1 .081 1.28c-.38 1.16-2.368 6.954-2.368 6.954m.654 8.638 1.969-1.23-.767 2.02Zm-1.888-7.229h2.474l-1.175.934Zm.114 2.151 3.708-2.24-.672 1.92-1.807 1.1Zm-1.39-.133 3.723 2.156-1.226.843-1.856-1.133Zm1.444 4.2 3.653-2.227-.586 1.788-1.906 1.3Zm-1.445-.131 3.686 2.259-1.112.728-1.918-1.083Zm3.165-2.879 1.969-1.232-.767 2.022ZM9.371 13.3l2.019 1.227-1.183.859Zm.064 4.159 1.923 1.2-1.137.776Zm1.906 5.3-1.2.755-.794-1.934Z"
    />
  </svg>
);
export default KT;
