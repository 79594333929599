import * as React from "react";
const Mail = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#D6B87C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.222}
      d="M2 6.445a2.222 2.222 0 0 1 2.222-2.222h15.556A2.222 2.222 0 0 1 22 6.445v11.11a2.222 2.222 0 0 1-2.222 2.223H4.222A2.222 2.222 0 0 1 2 17.556V6.445Z"
    />
    <path
      stroke="#D6B87C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.222}
      d="m2 6.445 10 6.666 10-6.666"
    />
  </svg>
);
export default Mail;
