import footerbg from "../../assets/images/Footer.jpeg";

export const ftrcont = {
  width: "100%",
  height: "478px",
  backgroundImage: `url(${footerbg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  webkitBoxShadow: "30px 30px 0px  10px rgba(9, 9, 9, 1)",
  mozBoxShadow: "30px 30px 0px 0px rgba(0, 0, 0, 1)",
  boxShadow: "30px 30px 0px 0px rgba(9, 9, 9, 1)",
};

export const flx = {
  flexDirection: "row",
  display: "flex",
};

export const flxclmn = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
};

export const flxclmn88 = {
  display: "flex",
  marginRight: "88px",
  flexDirection: "column",
};

export const flxmarg = {
  display: "flex",
  flexDirection: "column",
  marginRight: "197px",
};

export const flxmarg2 = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginBottom: "40px",
  marginTop: "48px",
};

export const flxtop = {
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  marginTop: "12px",
};

export const mrgnpont = { marginRight: "24px", cursor: "pointer" };

export const dvdr = {
  backgroundColor: "lightgray",
  width: "100%",
  height: "0.01rem",
  marginTop: "9rem",
  marginBottom: "1rem",
  alignSelf: "center",
};

export const ftrcntmob = {
  width: "100%",
  height: "100%",
  backgroundImage: `url(${footerbg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  paddingTop: "72px",
  overflow: "hidden",
  webkitBoxShadow: "30px 30px 0px  10px rgba(9, 9, 9, 1)",
  mozBoxShadow: "30px 30px 0px 0px rgba(0, 0, 0, 1)",
  boxShadow: "30px 30px 0px 0px rgba(9, 9, 9, 1)",
};

export const brdrtp = {
  display: "flex",
  flexDirection: "row",
  marginTop: "40px",
  alignSelf: "flex-start",
  marginLeft: "16px",
  borderTop: "1px solid #EEEBE4",
  paddingTop: "24px",
  width: "93.5%",
};

export const margnRg = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginRight: "40px",
};

export const bsy = {
  marginRight: "16px",
  paddingBottom: "1.5rem",
};

export const flxbsy = {
  alignSelf: "flex-start",
  marginLeft: "16px",
  marginTop: "24px",
  cursor: "pointer",
  borderBottom: "1px solid #EEEBE4",
  borderTop: "1px solid #EEEBE4",
  paddingTop: "24px",
  paddingBottom: "24px",
};

export const flxbsy2 = {
  alignSelf: "flex-start",
  marginLeft: "16px",
  marginTop: "24px",
  cursor: "pointer",
  borderBottom: "1px solid #EEEBE4",
  marginBottom: "24px",
  paddingBottom: "24px",
};

export const brysl = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "90vw",
};
