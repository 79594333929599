export const regular643838 = {
  fontFamily: "Regular",
  color: "#383838",
  marginBottom: "8px",
  textAlign: "center",
  fontSize: { xs: "32px", sm: "64px", md: "64px" },
  lineHeight: { xs: "41.6px", sm: "83.2px", md: "83.2px" },
};

export const customGoldtag = {
  backgroundcolor: "primary",
  backgroundImage: `linear-gradient(90deg, #CFB582, #DAC396, #CFB582)`,
  backgroundSize: "100%",
  backgroundRepeat: "repeat",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textAlign: "center",
  fontFamily: "GoldtagFont",
  fontSize: { xs: "56px", sm: "96px", md: "96px" },
  lineHeight: { xs: "72.8px", sm: "124.8px", md: "124.8px" },
  marginBottom: "1rem",
};

export const customSubTitle = {
  fontFamily: "Regular",
  textAlign: "center",
  fontSize: { xs: "16px", sm: "20px", md: "20px" },
  width: { xs: "343px", sm: "570px", md: "570px" },
  lineHeight: { xs: "28px", sm: "32px", md: "32px" },
};

export const boxFrame = {
  display: "flex",
  flexDirection: "row",
  marginTop: "2.5rem",
  gap: "2rem",
};

export const indir = {
  width: "270px",
  bgcolor: "#D6B87C",
  height: "48px",
  borderRadius: "12px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  marginTop: "32px",
};
