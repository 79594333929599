export const mapContainer = {
  alignSelf: "center",
  marginBottom: "72px",
  borderRadius: "36px",
  marginTop: "32px",
  cursor: "pointer",
  display: "flex",
  width: "40%",
  height: "450px",
  alignItems: "center",
  justifyContent: "center",
  border: "0.224px solid #EAE5DE",
  boxShadow:
    "0px 0px 21.95122px 0px rgba(182, 162, 117, 0.15) inset, 0.4878px 0.4878px 4.87805px 0px rgba(173, 154, 117, 0.12)",
};

export const frame213 = {
  display: "flex",
  gap: "7.5rem",
  paddingLeft: "6rem",
  alignSelf: "center",
  width: "323px",
};
export const frame432 = {
  display: "flex",
  flexDirection: "row",
  gap: "1rem",
  marginBottom: "40px",
  alignSelf: "center",
};
