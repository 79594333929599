import React, { Suspense, useRef, useState } from "react";

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
  blurStyle,
  frame,
  imgBox,
  imgMobileSt,
  imgStyle,
  phonetextFrame,
  subText,
  subTitle,
  title,
} from "./ThreePhonesCSS";
import Tik from "../../../assets/svgs/GoldtagAppSvgs/Tik";

gsap.registerPlugin(ScrollTrigger);

function ThreePhones(props) {
  const [gold3, setGold3] = useState(
    "https://goldtagimages.s3.eu-north-1.amazonaws.com/gold+3+(1).webp"
  );

  const [gold32, setGold32] = useState(
    "https://goldtagimages.s3.eu-north-1.amazonaws.com/gold+3+(1).webp"
  );

  const [tarim, setTarim] = useState(
    "https://goldtagimages.s3.eu-north-1.amazonaws.com/tarim+1.webp"
  );
  const [tarimMob, setTarimMob] = useState(
    "https://goldtagimages.s3.eu-north-1.amazonaws.com/tarim+1.webp"
  );

  const [etkinlik, setEtkinlik] = useState(
    "https://goldtagimages.s3.eu-north-1.amazonaws.com/event+1.webp"
  );
  const [etkinlik2, setEtkinlik2] = useState(
    "https://goldtagimages.s3.eu-north-1.amazonaws.com/event+1.webp"
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const boxRef1 = useRef(null);
  const boxRef2 = useRef(null);
  const boxRef3 = useRef(null);
  const boxRef4 = useRef(null);
  const boxRef5 = useRef(null);
  const boxRef6 = useRef(null);

  useGSAP(() => {
    gsap.from(boxRef1.current, {
      opacity: 0,
      x: 280,
      duration: 1.5,
      delay: 0.5,
      scrollTrigger: {
        trigger: boxRef1.current,
        start: "center 100%",
        toggleActions: "play none none none",
      },
    });

    gsap.from(boxRef2.current, {
      opacity: 0,
      x: 200,
      duration: 0.5,
      scrollTrigger: {
        trigger: boxRef2.current,
        start: "center 100%",
        toggleActions: "play none none none",
      },
    });

    gsap.from(boxRef3.current, {
      opacity: 0,
      x: -250,
      duration: 1.5,
      delay: 0.5,
      scrollTrigger: {
        trigger: boxRef4.current,
        start: "center 100%",
        toggleActions: "play none none none",
      },
    });

    gsap.from(boxRef4.current, {
      opacity: 0,
      x: -200,
      duration: 0.5,
      scrollTrigger: {
        trigger: boxRef4.current,
        start: "center 100%",
        toggleActions: "play none none none",
      },
    });

    gsap.from(boxRef5.current, {
      opacity: 0,
      x: 280,
      duration: 1.5,
      delay: 0.5,
      scrollTrigger: {
        trigger: boxRef5.current,
        start: "center 100%",
        toggleActions: "play none none none",
      },
    });

    gsap.from(boxRef6.current, {
      opacity: 0,
      x: 200,
      duration: 0.5,
      scrollTrigger: {
        trigger: boxRef6.current,
        start: "center 100%",
        toggleActions: "play none none none",
      },
    });
  }, []);

  return (
    <>
      {/* -------------------------altın emtia işlemleri------------------------------- */}
      <Box sx={phonetextFrame}>
        {isMobile && (
          <Box ref={boxRef2} style={imgBox}>
            <img
              src={gold3}
              alt="Goldtag Win"
              style={imgMobileSt}
              width="287"
              height="275"
            />

            <Box sx={blurStyle} />
          </Box>
        )}
        <Box ref={boxRef1}>
          <Typography sx={title}>Altın Emtia İşlemleri</Typography>

          <Typography sx={subText}>
            Altın, yatırımcılar için güvenli bir liman olarak kabul edilir.
            Çünkü altının, diğer yatırım araçlarından farklı olarak ekonomik
            kriz ve belirsizlik dönemlerinde fiyat değişikliği düşük seviyede
            olur. Altın, diğer yatırım araçlarına kıyasla daha iyi bir enflasyon
            koruması sağlar. Altın, yatırımcılar için portföy çeşitlendirmesi
            yapmak için ideal bir araçtır.
          </Typography>
          <Typography sx={subTitle}>Altın Emtiasının Faydaları;</Typography>

          <Box sx={frame}>
            <Box sx={{ marginRight: "16px" }}>
              <Tik />
            </Box>

            <Typography sx={subTitle}>Güvenli Liman</Typography>
          </Box>

          <Box sx={frame}>
            <Box sx={{ marginRight: "16px" }}>
              <Tik />
            </Box>
            <Typography sx={subTitle}>Enflasyon Koruması</Typography>
          </Box>

          <Box sx={frame}>
            <Box sx={{ marginRight: "16px" }}>
              <Tik />
            </Box>
            <Typography sx={subTitle}>Çeşitlendirme</Typography>
          </Box>
        </Box>
        {!isMobile && (
          <Box style={{ marginLeft: 200 }} ref={boxRef2}>
            <img src={gold32} alt="Goldtag Win" width="329" height="677" />
          </Box>
        )}
      </Box>

      {/* ----------------------------Tarım emtia işlemleri------------------------------------ */}
      <Box sx={phonetextFrame}>
        {!isMobile && (
          <Box style={{ marginRight: 200 }} ref={boxRef4}>
            <img
              src={tarim}
              alt="Goldtag Win"
              style={imgStyle}
              width="329"
              height="677"
            />
          </Box>
        )}
        {isMobile && (
          <Box ref={boxRef4} style={imgBox}>
            <img
              src={tarimMob}
              alt="Goldtag Win"
              style={imgMobileSt}
              width="287"
              height="275"
            />

            <Box sx={blurStyle} />
          </Box>
        )}

        <Box ref={isMobile ? null : boxRef3}>
          <Typography sx={title}>Tarım Emtia İşlemleri</Typography>
          <Typography sx={subText}>
            Arpa ve buğday gibi tarım emtiaları, düşük riskli yatırım
            araçlarıdır. Bu nedenle, yatırımcılar portföylerini çeşitlendirerek
            risklerini azaltabilirler. Tarım emtiaları, genellikle diğer yatırım
            araçlarından daha iyi bir enflasyon koruması sağlar. Buğday ve arpa
            gibi tahıl ürünleri, dünya nüfusunun temel gıda maddeleridir. Bu
            nedenle fiyatları talep ile doğrudan ilişkilidir.
          </Typography>
          <Typography sx={subTitle}>Tarım Emtiasının Faydaları;</Typography>
          <Box sx={frame}>
            {!isMobile ? (
              <Box sx={{ marginRight: "16px" }}>
                <Tik />
              </Box>
            ) : (
              <Box sx={{ marginRight: "16px" }}>
                <Tik />
              </Box>
            )}
            <Typography sx={subTitle}>Düşük Risk</Typography>
          </Box>
          <Box sx={frame}>
            {!isMobile ? (
              <Box sx={{ marginRight: "16px" }}>
                <Tik />
              </Box>
            ) : (
              <Box sx={{ marginRight: "16px" }}>
                <Tik />
              </Box>
            )}
            <Typography sx={subTitle}>Enflasyon Koruması</Typography>
          </Box>
          <Box sx={frame}>
            {!isMobile ? (
              <Box sx={{ marginRight: "16px" }}>
                <Tik />
              </Box>
            ) : (
              <Box sx={{ marginRight: "16px" }}>
                <Tik />
              </Box>
            )}
            <Typography sx={subTitle}>Küresel Ticaret</Typography>
          </Box>
        </Box>
      </Box>
      {/* ----------------------etkinlikler------------------------- */}
      <Box sx={phonetextFrame}>
        {isMobile && (
          <Box ref={boxRef6} style={imgBox}>
            <img
              src={etkinlik}
              alt="Goldtag Win"
              style={imgMobileSt}
              width="287"
              height="275"
            />

            <Box sx={blurStyle} />
          </Box>
        )}
        <Box ref={boxRef5}>
          <Typography sx={title}>Etkinlikler</Typography>
          <Typography sx={subText}>
            Goldtag kullanıcıları, biriktirdikleri altınları düğün, nişan gibi
            özel günlerde uzaktan takı olarak arkadaşlarına gönderebilirler.
            Altın günü gibi özel günlerde arkadaşları veya aile üyeleriyle
            birlikte altın biriktirmek de mümkündür. Ayrıca Goldtag,
            müşterilerine hediye amaçlı kullanılabilecek “Giftbox” seçenekleri
            sunar. Bu seçenekler sayesinde, müşteriler biriktirdikleri hediyelik
            ürünler ve değerli maden hediye edebilirler.
          </Typography>
          <Typography sx={subTitle}>Etkinlik Türleri;</Typography>
          <Box sx={frame}>
            <Box sx={{ marginRight: "16px" }}>
              <Tik />
            </Box>

            <Typography sx={subTitle}>Genel Etkinlik</Typography>
          </Box>
          <Box sx={frame}>
            <Box sx={{ marginRight: "16px" }}>
              <Tik />
            </Box>

            <Typography sx={subTitle}>Düğün Etkinliği</Typography>
          </Box>
          <Box sx={frame}>
            <Box sx={{ marginRight: "16px" }}>
              <Tik />
            </Box>

            <Typography sx={subTitle}>Altın Günü Etkinliği</Typography>
          </Box>
          <Box sx={frame}>
            <Box sx={{ marginRight: "16px" }}>
              <Tik />
            </Box>

            <Typography sx={subTitle}>Giftbox Etkinliği</Typography>
          </Box>
        </Box>

        {!isMobile && (
          <Box style={{ marginLeft: 200 }} ref={boxRef6}>
            <img
              src={
                "https://goldtagimages.s3.eu-north-1.amazonaws.com/goldtag_web_mockup_4+1.png"
              }
              alt="Goldtag Win"
              style={imgStyle}
              width="329"
              height="677"
            />
          </Box>
        )}
      </Box>
    </>
  );
}

export default ThreePhones;
