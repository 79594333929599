// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../src/assets/fonts/IBMPlexSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../src/assets/fonts/IBMPlexSans-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../src/assets/fonts/IBMPlexSans-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../src/assets/fonts/IBMPlexSans-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../src/assets/fonts/Gilroy-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Regular"; /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

@font-face {
  font-family: "Bold"; /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}

@font-face {
  font-family: "SemiBold"; /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}
@font-face {
  font-family: "Medium"; /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}

@font-face {
  font-family: "GoldtagFont"; /*Can be any text*/
  src: local("AirbnbCerealLight"),
    url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("truetype");
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB,EAAE,kBAAkB;EAC1C;8DACuE;AACzE;;AAEA;EACE,mBAAmB,EAAE,kBAAkB;EACvC;8DACoE;AACtE;;AAEA;EACE,uBAAuB,EAAE,kBAAkB;EAC3C;8DACwE;AAC1E;AACA;EACE,qBAAqB,EAAE,kBAAkB;EACzC;8DACsE;AACxE;;AAEA;EACE,0BAA0B,EAAE,kBAAkB;EAC9C;8DACoE;AACtE","sourcesContent":["@font-face {\n  font-family: \"Regular\"; /*Can be any text*/\n  src: local(\"AirbnbCerealLight\"),\n    url(\"../src/assets/fonts/IBMPlexSans-Regular.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Bold\"; /*Can be any text*/\n  src: local(\"AirbnbCerealLight\"),\n    url(\"../src/assets/fonts/IBMPlexSans-Bold.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"SemiBold\"; /*Can be any text*/\n  src: local(\"AirbnbCerealLight\"),\n    url(\"../src/assets/fonts/IBMPlexSans-SemiBold.ttf\") format(\"truetype\");\n}\n@font-face {\n  font-family: \"Medium\"; /*Can be any text*/\n  src: local(\"AirbnbCerealLight\"),\n    url(\"../src/assets/fonts/IBMPlexSans-Medium.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"GoldtagFont\"; /*Can be any text*/\n  src: local(\"AirbnbCerealLight\"),\n    url(\"../src/assets/fonts/Gilroy-ExtraBold.ttf\") format(\"truetype\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
