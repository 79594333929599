import React, { Suspense, useState } from "react";

import { Box, Typography } from "@mui/material";
import { frame1, frame2, text, threecontainer, title } from "./ThreeBoxesCSS";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"; // ScrollTrigger eklentisini import et
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger); // ScrollTrigger eklentisini kaydet

function ThreeBoxes() {
  const [win, setWin] = useState(
    "https://goldtagimages.s3.eu-north-1.amazonaws.com/win.webp"
  );

  const [save, setSave] = useState(
    "https://goldtagimages.s3.eu-north-1.amazonaws.com/save.webp"
  );
  const [like, setLike] = useState(
    "https://goldtagimages.s3.eu-north-1.amazonaws.com/like.webp"
  );

  const margn = { marginLeft: { xs: "18px", sm: "0px", md: "0px" } };

  useGSAP(() => {
    const boxes = gsap.utils.toArray(".frame"); // Kutuları seç ve diziye yerleştir

    // Her kutu için animasyon tanımla
    boxes.forEach((box, index) => {
      gsap.from(box, {
        opacity: 0,
        y: 50,
        duration: 1,
        ease: "sine.out",
        delay: 0.4,
        scrollTrigger: {
          trigger: box,
          start: "top bottom", // Scrollun üstünden başlayarak altına inene kadar tetikle
          toggleActions: "play none none none", // Tetikleme noktasında animasyonu başlat
        },
      });
    });
  }, []);

  return (
    <Box sx={threecontainer}>
      <Box sx={frame1} className="frame">
        <img src={save} alt="Goldtag Win" width="112" height="112" />

        <Box sx={margn}>
          <Typography sx={title}>Biriktir</Typography>
          <Typography sx={text}>
            Türk liranızı altın, gümüş, platin, hisse senedi veya tarım emtiası
            olarak biriktirin.
          </Typography>
        </Box>
      </Box>

      <Box sx={frame1} className="frame">
        <img src={win} alt="Goldtag Win" width="112" height="112" />

        <Box sx={margn}>
          <Typography sx={title}>Kazan</Typography>
          <Typography sx={text}>
            Değerli maden, hisse senedi ve tarım emtiası ile birikimlerinize
            değer katın.
          </Typography>
        </Box>
      </Box>

      <Box sx={frame2} className="frame">
        <img src={like} alt="Goldtag Win" width="112" height="112" />
        <Box sx={margn}>
          <Typography sx={title}>Güvenle Sakla</Typography>
          <Typography sx={text}>
            Birikimleriniz Kuveyt Türk ile güven altında saklanmaktadır.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ThreeBoxes;
