export const sssbox = {
  borderRadius: "12px",
  cursor: "pointer",
  border: "0.244px solid #EAE5DE",
  background: "#FFF",
  width: "970px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  marginBottom: "16px",
  padding: "16px",
  boxShadow:
    "0px 0px 21.95122px 0px rgba(184, 184, 184, 0.15) inset, 0.4878px 0.4878px 4.87805px 0px rgba(173, 154, 117, 0.12)",
};

export const sssinbox = {
  display: "flex",
  flexDirection: "row",
  width: "970px",
  alignItems: "center",
};

export const cvp = {
  marginTop: "16px",
  fontSize: "14px",
  fontFamily: "Regular",
  lineHeight: "24px",
  color: "#383838",
};

export const mobConIn = {
  borderRadius: "12px",
  border: "0.244px solid #EAE5DE",
  background: "#FFF",
  width: "343px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  marginBottom: "16px",
  padding: "16px",
  boxShadow:
    "0px 0px 21.95122px 0px rgba(184, 184, 184, 0.15) inset, 0.4878px 0.4878px 4.87805px 0px rgba(173, 154, 117, 0.12)",
};
