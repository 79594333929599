import * as React from "react";
const ArrowUpK = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#D6B87C"
      d="M9 4.6c.191 0 .377.036.557.107.18.072.329.168.447.287l6.601 6.602c.264.263.395.598.395 1.005 0 .406-.131.741-.395 1.004-.263.263-.598.395-1.004.395-.407 0-.742-.132-1.005-.395L9 8.008l-5.596 5.597c-.263.263-.598.395-1.005.395-.406 0-.741-.132-1.004-.395S1 13.007 1 12.6c0-.406.132-.741.395-1.004l6.6-6.602c.144-.143.3-.245.467-.305.167-.06.347-.09.538-.09Z"
    />
  </svg>
);
export default ArrowUpK;
