import { LinkedIn } from "@mui/icons-material";
import * as React from "react";
const Linkedin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="#383838"
    {...props}
  >
    <circle cx={24} cy={24} r={23.25} stroke="#383838" strokeWidth={1.5} />
    <path
      fill="#fff"
      d="M32.556 13A2.444 2.444 0 0 1 35 15.444v17.112A2.444 2.444 0 0 1 32.556 35H15.444A2.444 2.444 0 0 1 13 32.556V15.444A2.444 2.444 0 0 1 15.444 13h17.112Zm-.612 18.944v-6.477a3.984 3.984 0 0 0-3.984-3.985c-1.039 0-2.249.636-2.836 1.59v-1.358h-3.41v10.23h3.41V25.92c0-.941.758-1.711 1.7-1.711a1.711 1.711 0 0 1 1.71 1.71v6.026h3.41ZM17.742 19.796a2.053 2.053 0 0 0 2.054-2.054 2.06 2.06 0 0 0-2.054-2.065 2.065 2.065 0 0 0-2.065 2.065 2.06 2.06 0 0 0 2.065 2.054Zm1.7 12.148v-10.23h-3.386v10.23h3.385Z"
    />
  </svg>
);
export default Linkedin;
