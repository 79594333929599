export const meettitle = {
  fontSize: { xs: "24px", sm: "56px", md: "56px" },
  alignSelf: "center",
  display: "flex",
  textAlign: "center",
  width: { xs: "343px", sm: "970px", md: "970px" },
  height: { xs: "87px", sm: "134px", md: "134px" },
  marginTop: { xs: "24px", sm: "56px", md: "56px" },
  lineHeight: { xs: "34px", sm: "67px", md: "67px" },
  letterSpacing: { xs: "-1px", sm: "-2px", md: "-2px" },
  fontFamily: "SemiBold",
  color: "##383838",
};

export const meetsub = {
  fontSize: { xs: "14px", sm: "18px", md: "18px" },
  alignSelf: "center",
  display: "flex",
  textAlign: "center",
  width: { xs: "343px", sm: "970px", md: "970px" },
  height: { xs: null, sm: "224px", md: "224px" },
  marginTop: { xs: "32px", sm: "84px", md: "84px" },
  lineHeight: "32px",
  fontFamily: "Regular",
};
