import * as React from "react";
const ArrowDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : "#383838"}
      d="M2.823 7.013a1.46 1.46 0 0 1 1.02-.413c.383 0 .75.148 1.02.413l7.146 6.982 7.145-6.982c.272-.257.637-.4 1.015-.396.378.003.74.151 1.008.413.268.261.42.615.423.985.003.37-.142.726-.405.992l-8.166 7.98a1.46 1.46 0 0 1-1.02.413 1.46 1.46 0 0 1-1.02-.413l-8.166-7.98A1.394 1.394 0 0 1 2.4 8.01c0-.374.152-.733.423-.997Z"
    />
  </svg>
);
export default ArrowDown;
