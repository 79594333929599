export const marqmob = {
  borderRadius: "20px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  width: "99%",
  marginBottom: "1rem",
  padding: "0.3rem",
  marginTop: { xs: "56px", sm: "72px", md: "72px" },
  gap: "61px",
  whiteSpace: "nowrap",
  height:"100px",
};

export const maqrCon = {
  display: "flex",
  flexDirection: "row",
  width: "99%",
};
