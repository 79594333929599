export const contactcon = {
  alignSelf: "center",
  position: "relative",
  marginBottom: "64px",
};

export const abs = {
  position: "absolute",
  flexDirection: "column",
  display: "flex",
  justifyContent: "center",
  top: 61,
  left: 145,
  zIndex: 100,
};

export const tit2438 = {
  color: "#383838",
  fontFamily: "Regular",
  fontSize: "24px",
  lineHeight: "20px",
};

export const semi203838 = {
  color: "#383838",
  fontFamily: "Semibold",
  fontSize: "20px",
};

export const uls = {
  bgcolor: "#D6B87C",
  color: "white",
  width: "270px",
  height: "48px",
  borderRadius: "12px",
  marginTop: "32px",
  cursor: "pointer",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  fontFamily: "Semibold",
  lineHeight: "28px",
  fontSize: "16px",
};

export const cntain = {
  width: "343px",
  height: "220px",
  borderRadius: "24px",
  bgcolor: "#F3EEE5",
  alignSelf: "center",
  flexDirection: "column",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "72px",
};
