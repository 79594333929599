import * as React from "react";
const Call1 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#D6B87C"
        d="M6.844 2.428c1.265.923 2.223 2.219 3.038 3.41l.64.94a1.507 1.507 0 0 1-.18 1.916l-1.358 1.358.144.305c.35.71.955 1.733 1.866 2.645a9.887 9.887 0 0 0 2.108 1.582l.367.198.328.162.146.067 1.357-1.358a1.505 1.505 0 0 1 1.922-.171l1.016.704c1.154.811 2.359 1.736 3.295 2.936a1.472 1.472 0 0 1 .19 1.488c-.839 1.956-2.96 3.622-5.167 3.54l-.3-.016-.234-.02-.259-.03-.281-.038-.306-.052-.326-.064-.347-.077-.367-.094-.385-.11-.403-.13c-1.85-.627-4.196-1.86-6.604-4.268-2.408-2.407-3.64-4.754-4.267-6.604l-.13-.402-.11-.386-.094-.367-.079-.346a11.804 11.804 0 0 1-.063-.327l-.05-.305-.04-.282-.029-.258-.02-.234-.016-.3c-.081-2.2 1.603-4.337 3.55-5.171a1.473 1.473 0 0 1 1.448.16ZM5.915 4.24C4.841 4.8 3.801 6.083 3.85 7.366l.02.328.022.206.029.23.04.254.05.277.066.299.08.32.096.34.114.358c.043.123.087.248.135.376l.154.393.176.407c.63 1.385 1.655 3.006 3.331 4.68 1.675 1.676 3.296 2.702 4.68 3.332l.408.176.393.154c.128.048.253.092.375.134l.359.114.34.097.32.08.298.065.278.051.254.04.23.03.205.02.329.02c1.266.047 2.558-.987 3.117-2.046-.713-.837-1.599-1.523-2.575-2.214l-.75-.522-.19.2-.407.443-.215.226c-.587.598-1.272 1.106-2.094.775l-.226-.096-.277-.124-.154-.073-.338-.17-.372-.2a11.885 11.885 0 0 1-2.571-1.928 11.888 11.888 0 0 1-1.929-2.571l-.2-.371-.17-.34-.197-.43-.096-.229c-.312-.77.117-1.42.665-1.983l.225-.22.558-.515.2-.19-.474-.694C7.457 5.853 6.77 4.932 5.914 4.238l.001.001Zm9.079 1.79.116.013a3.506 3.506 0 0 1 2.863 2.966 1.002 1.002 0 0 1-1.961.393l-.023-.115a1.503 1.503 0 0 0-1.072-1.235l-.155-.035a1.002 1.002 0 0 1 .232-1.987ZM15 2.984a6.01 6.01 0 0 1 6.01 6.01 1.002 1.002 0 0 1-1.996.118l-.007-.117a4.005 4.005 0 0 0-3.745-3.998l-.262-.01a1.002 1.002 0 0 1 0-2.003Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Call1;
