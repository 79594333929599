export const partcont = {
  display: "flex",
  alignSelf: "center",
  marginTop: "5rem",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: { xs: "12px", sm: "72px", md: "72px" },
};

export const parttit = {
  fontSize: { xs: "24px", sm: "48px", md: "48px" },
  fontFamily: "SemiBold",
  letterSpacing: "-0.96px",
  color: "##383838",
};

export const partsub = {
  fontFamily: "Regular",
  width: { xs: "343px", sm: "570px", md: "570px" },
  lineHeight: "28px",
  textAlign: "center",
  marginTop: { xs: "8px", sm: "16px", md: "16px" },
};
