import React, { useRef } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { ArrowDown } from "../../assets/svgs/GoldtagGatewaySvgs";
import {
  mobcontainer,
  mobtit,
  subPageTitle,
} from "../UcretlerLimitler/UcretlerVeLimitlerCSS";
import { cvp, mobConIn, sssbox, sssinbox } from "./SSSCSS";
import { title16 } from "../../assets/styles/defaultFonts";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
function SikcaSorulanSorularComp(props) {
  const MOCK_DATA = [
    {
      id: 1,
      soru: "Goldtag’i kullanmaya nasıl başlayabilirim?",
      cevap:
        "Tek yapmanız gereken Goldtag uygulamasını cep telefonunuza indirmek ve kayıt olmak. Kayıt olduktan sonra cep telefonunuza ve e-posta adresinize gelen doğrulama mesajını onaylayıp Goldtag’de altın biriktirmeye başlayabilirsiniz.",
    },
    {
      id: 2,
      soru: "Altınlarım nerede tutuluyor?",
      cevap:
        "Altınlarınız Kuveyttürk Bankasında 995/1000 saflıkta tutulmaktadır.",
    },

    {
      id: 4,
      soru: "Goldtag hesabımda bulunan altınların fiziksel olarak karşılığı var mı?",
      cevap:
        "Goldtag platformunda satılan altınların fiziksel olarak karşılıkları anlaşmalı olunan bankaların kasalarında güvenli bir şekilde saklanmaktadır.",
    },
    {
      id: 5,
      soru: "Goldtag hesabımı nasıl kapatırım?",
      cevap:
        "Hesabınızı kapatmanızı istemeyiz fakat kapatmak isterseniz “Profil” bölümünden “Hesabımı Kapat” menüsünden işleminizi yapabilirsiniz. Hesabınızı kapatmadan önce tüm altınlarınızı bozdurup, kullandığınız banka hesabınıza aktarmış olmanız gerekmektedir.",
    },
  ];

  const [openItemIndex, setOpenItemIndex] = React.useState(-1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleItemClick = (index) => {
    // Tıklanan öğenin indeksi, açık olanı kapatmak için kullanılır
    if (openItemIndex === index) {
      setOpenItemIndex(-1);
    } else {
      setOpenItemIndex(index);
    }
  };

  const boxRef2 = useRef(null);
  const boxRef3 = useRef(null);
  const boxRef4 = useRef(null);
  const boxRef5 = useRef(null);

  const leftTextRef = useRef(null);

  useGSAP(() => {
    gsap.from(leftTextRef.current, {
      opacity: 0,
      x: -200,
      duration: 0.6,
      ease: "circ.inOut",
    });

    gsap.from(boxRef2.current, {
      opacity: 0,
      x: -300,
      duration: 1,
      ease: "power3.in",
    });

    gsap.from(boxRef3.current, {
      opacity: 0,
      x: 300,
      duration: 1,
      ease: "power3.in",
    });

    gsap.from(boxRef4.current, {
      opacity: 0,
      x: -300,
      duration: 1,
      ease: "power3.in",
    });

    gsap.from(boxRef5.current, {
      opacity: 0,
      x: 300,
      duration: 1,
      ease: "power3.in",
    });
  });

  return (
    <>
      {isMobile ? (
        <>
          <Box style={mobcontainer}>
            <Typography sx={mobtit}>Sıkça Sorulan Sorular</Typography>

            <Box
              style={{
                alignSelf: "center",
                marginTop: "12px",
                marginBottom: "24px",
              }}
            >
              {MOCK_DATA.map((item, index) => (
                <Box
                  key={index}
                  sx={mobConIn}
                  onClick={() => handleItemClick(index)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "343px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        marginRight: "auto",
                        color: openItemIndex == index ? "#D5B77C" : "#383838",
                        fontFamily: "Bold",
                        fontSize: "14px",
                      }}
                    >
                      {item.soru}
                    </Typography>
                    <Box
                      onClick={() => handleItemClick(index)}
                      style={{ cursor: "pointer" }}
                    >
                      <ArrowDown />
                    </Box>
                  </Box>

                  {openItemIndex === index && (
                    <Typography
                      sx={{
                        marginTop: "16px",
                        fontSize: "12px",
                        fontFamily: "Regular",
                        lineHeight: "20px",
                        color: "#383838",
                      }}
                    >
                      {item.cevap}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Typography sx={[subPageTitle, { width: "100%" }]} ref={leftTextRef}>
            Sıkça Sorulan Sorular
          </Typography>

          <Box
            style={{
              alignSelf: "center",
              marginTop: "56px",
              marginBottom: "56px",
            }}
          >
            {MOCK_DATA.map((item, index) => (
              <Box
                key={index}
                sx={sssbox}
                onClick={() => handleItemClick(index)}
                ref={
                  index === 0
                    ? boxRef2
                    : index === 1
                    ? boxRef3
                    : index === 2
                    ? boxRef4
                    : boxRef5
                }
              >
                <Box sx={sssinbox}>
                  <Typography
                    sx={[
                      title16,
                      {
                        marginRight: "auto",

                        color: openItemIndex == index ? "#D5B77C" : "#383838",
                      },
                    ]}
                  >
                    {item.soru}
                  </Typography>
                  <Box
                    onClick={() => handleItemClick(index)}
                    style={{ cursor: "pointer" }}
                  >
                    <ArrowDown />
                  </Box>
                </Box>

                {openItemIndex === index && (
                  <Typography sx={cvp}>{item.cevap}</Typography>
                )}
              </Box>
            ))}
          </Box>
        </>
      )}
    </>
  );
}

export default SikcaSorulanSorularComp;
