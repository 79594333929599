import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef } from "react";
import {
  customGoldtag,
  customSubTitle,
  regular643838,
} from "../../goldtagAppComponents/TopTypoComponent/TopTypoCSS";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

function TopTypo(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const leftTextRef = useRef(null);
  const rightTextRef = useRef(null);

  const goldtagTypo = useRef(null);
  const imageX = useRef(null);

  useGSAP(() => {
    gsap.from(leftTextRef.current, {
      opacity: 0,
      x: -200,
      duration: 0.6,
      ease: "circ.inOut",
    });

    gsap.from(rightTextRef.current, {
      opacity: 0,
      x: 200,
      duration: 0.6,
      ease: "circ.inOut",
    });

    gsap.from(goldtagTypo.current, {
      opacity: 0,
      y: -100,
      duration: 2,
      ease: "circ.inOut",
    });

    gsap.from(imageX.current, {
      opacity: 0,
      y: 200,
      duration: 2,
      ease: "circ.inOut",
    });
  }, []);

  return (
    <>
      <Typography
        sx={[regular643838, { marginTop: "200px" }]}
        ref={leftTextRef}
      >
        Birikim Partneri
      </Typography>
      <Typography sx={customGoldtag} ref={goldtagTypo}>
        Goldtag Gateway
      </Typography>
      <Typography
        sx={[customSubTitle, { marginBottom: "64px" }]}
        ref={rightTextRef}
      >
        Goldtag ile ışık hızında sadece bir kaç satır kod ile kıymetli maden ve
        tarım emtia gibi varlıkların API’lerine erişebilirsiniz.
      </Typography>

      {/* -------------------Resim------------------ */}
      {!isMobile ? (
        <Box ref={imageX} style={{ marginBottom: "128px" }}>
          <img
            src="https://goldtagimages.s3.eu-north-1.amazonaws.com/MockUpGateway.webp"
            alt="Goldtag Win"
            width="1070"
            height="626"
          />{" "}
        </Box>
      ) : (
        <Box ref={imageX} style={{ marginBottom: "64px" }}>
          <img
            src="https://goldtagimages.s3.eu-north-1.amazonaws.com/MockUpGateway.webp"
            alt="Goldtag Win"
            width="343"
            height="200"
          />{" "}
        </Box>
      )}
    </>
  );
}

export default TopTypo;
