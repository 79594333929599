import React, { useRef } from "react";
import { partcont, partsub, parttit } from "./PartnerlerimizCSS";
import Marque from "../../marquee/Marque";
import { Box, Typography } from "@mui/material";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

function Partnerlerimiz(props) {
  const title = useRef(null);

  useGSAP(() => {
    gsap.from(title.current, {
      opacity: 0,
      y: -100,
      duration: 1,
      ease: "circ.inOut",
    });
  }, []);

  return (
    <>
      <Box sx={partcont} ref={title}>
        <Typography sx={parttit}>Partnerlerimiz</Typography>
        <Typography sx={partsub}>
          İş ortaklarımız ve partnerlerimizle tanışın.
        </Typography>
      </Box>

      <Marque />
    </>
  );
}

export default Partnerlerimiz;
