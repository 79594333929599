import * as React from "react";
const Call = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#D6B87C"
        d="M9.123 3.237c1.687 1.23 2.964 2.958 4.051 4.546l.853 1.253a2.01 2.01 0 0 1-.238 2.555l-1.811 1.811.19.406c.468.947 1.275 2.311 2.489 3.527a13.183 13.183 0 0 0 2.81 2.11l.49.263.437.216.195.09 1.81-1.811a2.007 2.007 0 0 1 2.562-.229l1.354.94c1.539 1.081 3.146 2.314 4.394 3.914a1.963 1.963 0 0 1 .253 1.984c-1.118 2.608-3.947 4.83-6.89 4.72l-.4-.021-.311-.027-.345-.04-.375-.051-.408-.068-.435-.086-.462-.102-.49-.126-.513-.147-.537-.174C15.33 27.855 12.2 26.212 8.99 23c-3.21-3.21-4.853-6.339-5.689-8.804l-.173-.537-.147-.514-.126-.49-.104-.461c-.03-.145-.058-.29-.084-.436l-.067-.407-.053-.376-.04-.344-.026-.311-.021-.401c-.108-2.933 2.137-5.782 4.733-6.895a1.963 1.963 0 0 1 1.93.213ZM7.885 5.65C6.453 6.4 5.067 8.11 5.13 9.821l.026.437.03.274.039.307.053.338.068.37.087.398.107.428.128.452.152.479c.056.162.115.33.18.5l.205.524.235.544c.839 1.846 2.207 4.007 4.441 6.24 2.233 2.233 4.394 3.602 6.24 4.441l.544.235.524.206c.17.064.337.123.5.179l.479.152.454.128.426.107.398.087.37.068.339.053.307.04.273.027.438.027c1.688.063 3.411-1.316 4.156-2.729-.95-1.115-2.131-2.03-3.434-2.952l-.999-.696-.254.266-.542.592-.287.302c-.783.797-1.696 1.474-2.791 1.032l-.302-.127-.369-.165-.206-.098-.451-.226-.496-.267a15.851 15.851 0 0 1-3.428-2.57 15.85 15.85 0 0 1-2.571-3.43L9.93 15.3l-.227-.453-.261-.574-.129-.304c-.415-1.027.157-1.894.887-2.645l.3-.294.743-.685.268-.254-.632-.925C9.94 7.803 9.023 6.574 7.884 5.65l.001.001ZM19.99 8.038l.155.018a4.675 4.675 0 0 1 3.817 3.953 1.337 1.337 0 0 1-2.615.525l-.03-.154a2.004 2.004 0 0 0-1.43-1.646l-.207-.047a1.336 1.336 0 0 1 .31-2.649Zm.01-4.06a8.014 8.014 0 0 1 8.014 8.014 1.336 1.336 0 0 1-2.662.156l-.01-.156a5.34 5.34 0 0 0-4.992-5.33L20 6.648a1.335 1.335 0 1 1 0-2.671Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Call;
