import { Typography, useMediaQuery, useTheme, Box } from "@mui/material";
import React from "react";

import {
  text,
  threecontainer,
  title,
} from "../../goldtagAppComponents/ThreeBoxes/ThreeBoxesCSS";

import { frame45 } from "./FourBoxCSS";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger"; // ScrollTrigger eklentisini import et

gsap.registerPlugin(ScrollTrigger); // ScrollTrigger eklentisini kaydet

function FourBox() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const margn = { marginLeft: { xs: "18px", sm: "0px", md: "0px" } };
  const margn2 = {
    marginRight: isMobile ? null : "36px",
    marginBottom: isMobile ? "40px" : null,
  };

  useGSAP(() => {
    const boxes = gsap.utils.toArray(".frame"); // Kutuları seç ve diziye yerleştir

    // Her kutu için animasyon tanımla
    boxes.forEach((box, index) => {
      gsap.from(box, {
        opacity: 0,
        y: 50,
        duration: 1,
        ease: "sine.out",
        delay: 0.4,
        scrollTrigger: {
          trigger: box,
          start: "top bottom", // Scrollun üstünden başlayarak altına inene kadar tetikle
          toggleActions: "play none none none", // Tetikleme noktasında animasyonu başlat
        },
      });
    });
  }, []);

  return (
    <>
      {/* -----------------4lü kutu------------------ */}
      <Box sx={threecontainer}>
        <Box sx={margn2}>
          <Box sx={frame45} className="frame">
            <img
              src={
                "https://goldtagimages.s3.eu-north-1.amazonaws.com/Mutabakat.webp"
              }
              alt="Goldtag Win"
              width="112"
              height="112"
            />
            <Box sx={margn}>
              <Typography sx={title}>Kolay Mütabakat</Typography>
              <Typography sx={text}>
                Goldtag Gateway paneli sayesinde anlık olarak platformunuzdaki
                alım-satım işlemlerini görüntüleyebilirsiniz.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={margn2}>
          <Box sx={frame45} className="frame">
            <img
              src={"https://goldtagimages.s3.eu-north-1.amazonaws.com/API.webp"}
              alt="Goldtag Win"
              width="112"
              height="112"
            />{" "}
            <Box sx={margn}>
              <Typography sx={title}>Hızlı Entegrasyon</Typography>
              <Typography sx={text}>
                Alanında uzman destek ekibimiz gözetiminde bir kaç satır kod ile
                Goldtag Gateway’e hızlı bir şekilde entegrasyon
                sağlayabilirsiniz.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={margn2}>
          <Box sx={frame45} className="frame">
            <img
              src={"https://goldtagimages.s3.eu-north-1.amazonaws.com/win.webp"}
              alt="Goldtag Win"
              width="112"
              height="112"
            />{" "}
            <Box sx={margn}>
              <Typography sx={title}>Uygun Fiyat</Typography>
              <Typography sx={text}>
                Stratejik iş ortaklarımızın katkılarıyla 7/24 uygun fiyatlara
                erişebilirsiniz.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <Box sx={frame45} className="frame">
            <img
              src={
                "https://goldtagimages.s3.eu-north-1.amazonaws.com/Secure.webp"
              }
              alt="Goldtag Win"
              width="112"
              height="112"
            />{" "}
            <Box sx={margn}>
              <Typography sx={title}>Yüksek Güvenlik</Typography>
              <Typography sx={text}>
                Goldtag Gateway siber ataklara karşı sürekli önlem almaktadır.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default FourBox;
