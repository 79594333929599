import * as React from "react";
const Phone = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#D6B87C"
      d="M2.772.439 3.849.095c1.008-.322 2.086.199 2.518 1.217l.86 2.028c.375.883.167 1.922-.514 2.568L4.82 7.706c.117 1.076.478 2.135 1.084 3.177a8.68 8.68 0 0 0 2.27 2.595l2.277-.76c.862-.287 1.8.044 2.33.82l1.232 1.81c.616.905.505 2.15-.258 2.917l-.818.82c-.814.818-1.976 1.115-3.052.779-2.54-.792-4.873-3.143-7.003-7.053C.749 8.895-.003 5.57.624 2.843.888 1.695 1.706.78 2.774.439h-.002Z"
    />
  </svg>
);
export default Phone;
