import * as React from "react";
const Instagram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <circle cx={24} cy={24} r={23.25} stroke="#383838" strokeWidth={1.5} />
    <path
      fill="#383838"
      d="M19.38 13h9.24c3.52 0 6.38 2.86 6.38 6.38v9.24A6.38 6.38 0 0 1 28.62 35h-9.24C15.86 35 13 32.14 13 28.62v-9.24A6.38 6.38 0 0 1 19.38 13Zm-.22 2.2a3.96 3.96 0 0 0-3.96 3.96v9.68a3.958 3.958 0 0 0 3.96 3.96h9.68a3.96 3.96 0 0 0 3.96-3.96v-9.68a3.958 3.958 0 0 0-3.96-3.96h-9.68Zm10.615 1.65a1.375 1.375 0 1 1 0 2.75 1.375 1.375 0 0 1 0-2.75ZM24 18.5a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11Zm0 2.2a3.3 3.3 0 1 0 0 6.6 3.3 3.3 0 0 0 0-6.6Z"
    />
  </svg>
);
export default Instagram;
