import React, { lazy, Suspense } from "react";
import { Box, useMediaQuery, useTheme, Typography } from "@mui/material";
import {
  frame32,
  frm,
  mobfram,
  mobsmal,
  qrbrdr,
  qrcontainer,
  qrwhite,
  smi,
  ucrt,
  ulas,
} from "./QRCSS";
import {
  appStrGgleStrBox,
  regular163838,
  semibold163838,
} from "../../assets/styles/GeneralStyles";
import { useNavigate } from "react-router-dom";

// Lazy load SVG components
const Yildiz = lazy(() =>
  import("../../assets/svgs/GoldtagAppSvgs").then((module) => ({
    default: module.Yildiz,
  }))
);
const AppStore = lazy(() =>
  import("../../assets/svgs/GoldtagAppSvgs").then((module) => ({
    default: module.AppStore,
  }))
);

function QR(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  return (
    <>
      {/* --------------QR-------------- */}
      {!isMobile ? (
        <Box style={qrcontainer}>
          <Box sx={frame32}>
            <Suspense fallback={<div>Loading...</div>}>
              <Yildiz />
            </Suspense>

            <Box style={{ marginLeft: "24px" }}>
              <Typography sx={ucrt}>
                Ücretsiz Goldtag hesabını hemen oluştur,
              </Typography>
              <Typography sx={smi}>
                birikim hedeflerine ulaşmaya başla!
              </Typography>
              <Box sx={appStrGgleStrBox}>
                <Suspense fallback={<div>Loading...</div>}>
                  <AppStore />
                </Suspense>
              </Box>
            </Box>
          </Box>

          <Box sx={frm}>
            <Box sx={qrbrdr}>
              <Box sx={qrwhite}></Box>
            </Box>
            <Typography sx={[smi, { marginLeft: "24px", width: "192px" }]}>
              QR Kodu okut, uygulamayı indir
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box sx={mobfram}>
          <Box sx={mobsmal}>
            <Suspense fallback={<div>Loading...</div>}>
              <Yildiz />
            </Suspense>
            <Typography sx={[regular163838, { width: "243px" }]}>
              Goldtag Gateway kolay entegrasyon ile ilgili{" "}
              <span style={semibold163838}>
                {" "}
                daha fazla bilgiye mi ihtiyacınız var?{" "}
              </span>
            </Typography>
          </Box>
          <Typography sx={ulas} onClick={() => navigate("/Iletisim")}>
            Bize Ulaşın
          </Typography>
        </Box>
      )}
    </>
  );
}

export default QR;
