import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import {
  antSubFrame,
  antTabFrame,
  appBarMobile,
  appBarStyle,
  frame80,
  headerFrame,
  inFrameMobile,
  logoFrame,
  logoFrameMobile,
  rightIconMobile,
  drawerPaperMobile,
  boxesFrameMobile,
  boxMobileStyle,
} from "./HeaderCSS";
import {
  listItemFrame,
  regular14383838cursor,
  regular163838cursor,
  semibold14D6B87Ccursor,
  semibold163838cursor,
} from "../../assets/styles/GeneralStyles";
import LogoMobile from "../../assets/svgs/LogoMobile";
import Hamburger from "../../assets/svgs/Hamburger";
import Cancel from "../../assets/svgs/Cancel";

function Header() {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [click, setClick] = useState("/GoldtagApp");
  const [clickTab, setClickTab] = useState(location.pathname);
  const [value, setValue] = React.useState("/");
  const [clicky, setClicky] = useState(null);
  const [clickySub, setClickySub] = useState("/GoldtagApp");
  const [tabStatus, setTabStatus] = useState(true);

  useEffect(() => {
    setClicky(location.pathname);
    console.log(location.pathname);
    switch (location.pathname) {
      case "/GoldtagGateway":
        setValue("goldtagGateway");
        setTabStatus(false);

        break;

      case "/GoldtagApp":
        setValue("goldtagApp");
        setTabStatus(true);

        break;
      case "/Hakkimizda":
        setValue("hakkimizda");
        setTabStatus(false);

        break;
      case "/UcretlerVeLimitler":
        setValue("ücretlerVeLimitler");
        setTabStatus(false);
        setClicky("/GoldtagApp");

        break;
      case "/SikcaSorulanSorular":
        setValue("sss");
        setTabStatus(false);
        setClicky("/GoldtagApp");

        break;
      case "/Iletisim":
        setValue("iletisim");
        setTabStatus(false);
        setClicky("/GoldtagApp");
        break;
      default:
        setValue("goldtagApp");

        break;
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == "goldtagApp") {
      setClick("/GoldtagApp");
      setTabStatus(true);
      navigate("GoldtagApp");
    } else if (newValue == "goldtagGateway") {
      setClickTab("");
      setTabStatus(false);
      setClick("/GoldtagGateway");
      navigate("GoldtagGateway");
    } else if (newValue == "ücretlerVeLimitler") {
      setTabStatus(false);
      setClick("/ücretlerVeLimitler");
      navigate("UcretlerVeLimitler");
    } else if (newValue == "sss") {
      setTabStatus(false);
      setClick("/sss");
      navigate("SikcaSorulanSorular");
    } else if (newValue == "hakkimizda") {
      setTabStatus(false);
      setClick("/Hakkimizda");
      navigate("Hakkimizda");
    } else if (newValue == "iletisim") {
      setTabStatus(false);
      setClick("/iletisim");
      navigate("/iletisim");
    }
    // else if (newValue == "blogvepodcastler") {
    //   setTabStatus(false);
    //   setClick("/blogvepodcastler");
    //   navigate("BloglarVePodcastler");
    // }
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const AntTabs = styled(Tabs)({
    "& .MuiTabs-indicator": {
      backgroundColor: "#CFB582",
    },
  });

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      marginRight: theme.spacing(1),
      color: "rgba(0, 0, 0, 0.85)",
      fontFamily: "Regular",
      "&:hover": {
        color: "#CFB582",
        opacity: 1,
      },
      "&.Mui-selected": {
        color: "#CFB582",
        fontFamily: "Semibold",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "#CFB582",
      },
    })
  );

  const handleTab = (tabName, scrollToPosition) => {
    setClickTab(tabName);
    if (click !== "/GoldtagApp") {
      setClick("/GoldtagApp");
      if (value != "goldtagApp") {
        navigate("GoldtagApp");
      }
    }
    setTimeout(() => {
      window.scrollTo({ top: scrollToPosition, behavior: "smooth" });
    }, 0);
  };

  const handleSmallTab = (route) => {
    setClicky(route);
    navigate(route);
  };

  const handleSub = (subRoute, scrollPosition, mainRoute) => {
    toggleDrawer();
    setClickySub(subRoute);
    navigate(mainRoute);
    setTimeout(() => {
      window.scrollTo({ top: scrollPosition, behavior: "smooth" });
    }, 0);
  };

  return !isMobile ? (
    <AppBar
      position="fixed"
      sx={[
        appBarStyle,
        {
          height: tabStatus == true ? "148px" : "84px",
        },
      ]}
    >
      <Box sx={headerFrame}>
        <Box
          sx={logoFrame}
          onClick={() => {
            setTabStatus(true);
            navigate("GoldtagApp");
          }}
        >
          <LogoMobile />
        </Box>

        <AntTabs
          value={value}
          onChange={handleChange}
          aria-label="secondary tabs example"
          sx={antTabFrame}
        >
          <AntTab value="goldtagApp" label="Goldtag App (Bireysel)" />
          <AntTab value="goldtagGateway" label="Goldtag Gateway (Kurumsal)" />
          <AntTab
            value="hakkimizda"
            label="Hakkımızda"
            sx={{ marginLeft: "auto" }}
          />

          <AntTab value="ücretlerVeLimitler" label="Ücretler ve Limitler" />
          <AntTab value="sss" label="Sıkça Sorulan Sorular" />

          <AntTab
            value="iletisim"
            label="İletişim"
            sx={{ marginRight: "auto" }}
          />
        </AntTabs>
      </Box>
      {tabStatus == true ? (
        <Box sx={antSubFrame}>
          <Typography
            sx={
              clickTab == "Altin" ? semibold163838cursor : regular163838cursor
            }
            onClick={() => {
              handleTab("Altin", 4770);
            }}
          >
            Altın Emtia
          </Typography>
          <Typography
            sx={
              clickTab == "Tarim" ? semibold163838cursor : regular163838cursor
            }
            onClick={() => handleTab("Tarim", 5610)}
          >
            Tarım Emtia
          </Typography>
          <Typography
            sx={
              clickTab == "Etkinlikler"
                ? semibold163838cursor
                : regular163838cursor
            }
            onClick={() => handleTab("Etkinlikler", 6450)}
          >
            Etkinlikler
          </Typography>
        </Box>
      ) : (
        <Box sx={frame80} />
      )}
    </AppBar>
  ) : (
    <AppBar position="fixed" sx={appBarMobile}>
      <Box sx={inFrameMobile}>
        <Box style={logoFrameMobile} onClick={() => navigate("/")}>
          <LogoMobile />
        </Box>

        {!isDrawerOpen ? (
          <Box onClick={toggleDrawer} sx={rightIconMobile}>
            <Hamburger />
          </Box>
        ) : (
          <Box onClick={toggleDrawer} sx={rightIconMobile}>
            <Cancel />
          </Box>
        )}

        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer}
          ModalProps={{
            BackdropProps: {
              invisible: true, // Arkaplanı görünmez yapar, böylece kararmaz
            },
          }}
          PaperProps={{
            sx: drawerPaperMobile,
          }}
        >
          <Box sx={boxesFrameMobile}>
            <Box
              sx={[
                boxMobileStyle,
                {
                  bgcolor: clicky == "/GoldtagApp" ? "#FFFFFF" : null,
                  boxShadow:
                    clicky == "/GoldtagApp"
                      ? "0px 4px 20px rgba(0, 0, 0, 0.2)"
                      : null,
                },
              ]}
              onClick={() => {
                handleSmallTab("/GoldtagApp");
                toggleDrawer();
              }}
            >
              <Typography
                sx={
                  clicky == "/GoldtagApp"
                    ? semibold14D6B87Ccursor
                    : regular14383838cursor
                }
              >
                Goldtag App
              </Typography>
              <Typography
                sx={[
                  clicky == "/GoldtagApp"
                    ? semibold14D6B87Ccursor
                    : regular14383838cursor,
                  { fontWeight: 600 },
                ]}
              >
                Bireysel
              </Typography>
            </Box>

            <Box
              sx={[
                boxMobileStyle,
                {
                  bgcolor: clicky == "/GoldtagGateway" ? "#FFFFFF" : null,
                  boxShadow:
                    clicky == "/GoldtagGateway"
                      ? "0px 4px 20px rgba(0, 0, 0, 0.2)"
                      : null,
                },
              ]}
              onClick={() => {
                handleSmallTab("/GoldtagGateway");
                toggleDrawer();
              }}
            >
              <Typography
                sx={
                  clicky == "/GoldtagGateway"
                    ? semibold14D6B87Ccursor
                    : regular14383838cursor
                }
              >
                Goldtag Gateway
              </Typography>
              <Typography
                sx={[
                  clicky == "/GoldtagGateway"
                    ? semibold14D6B87Ccursor
                    : regular14383838cursor,
                  { fontWeight: 600 },
                ]}
              >
                Kurumsal
              </Typography>
            </Box>
          </Box>
          {clicky == "/GoldtagApp" ? (
            <List>
              <ListItem
                onClick={() => {
                  handleSub("Altin", 6550, "GoldtagApp");
                  setClicky("/GoldtagApp");
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                    cursor: "pointer",
                  },
                }}
              >
                <Box>
                  <ListItemText
                    primaryTypographyProps={{
                      sx: {
                        regular163838cursor,
                      },
                    }}
                    primary="Altın Emtia"
                  />
                  <Box style={listItemFrame} />
                </Box>
              </ListItem>

              <ListItem
                button
                onClick={() => {
                  handleSub("Tarim", 7380, "GoldtagApp");
                  setClicky("/GoldtagApp");
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Box>
                  <ListItemText
                    primaryTypographyProps={{
                      sx: {
                        regular163838cursor,
                      },
                    }}
                    primary="Tarım Emtia"
                  />

                  <Box style={listItemFrame} />
                </Box>
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  handleSub("Etkinlik", 8250, "GoldtagApp");
                  setClicky("/GoldtagApp");
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Box>
                  <ListItemText
                    primaryTypographyProps={{
                      sx: {
                        regular163838cursor,
                      },
                    }}
                    primary="Etkinlikler"
                  />
                  <Box style={listItemFrame} />
                </Box>
              </ListItem>

              <ListItem
                button
                onClick={() => [toggleDrawer(), navigate("/Iletisim")]}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Box>
                  <ListItemText
                    primaryTypographyProps={{
                      sx: {
                        regular163838cursor,
                      },
                    }}
                    primary="İletişim"
                  />
                  <Box style={listItemFrame} />
                </Box>
              </ListItem>

              <ListItem
                button
                onClick={() => [toggleDrawer(), navigate("UcretlerVeLimitler")]}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Box>
                  <ListItemText
                    primaryTypographyProps={{
                      sx: {
                        regular163838cursor,
                      },
                    }}
                    primary="Ücretler ve Limitler"
                  />
                  <Box style={listItemFrame} />
                </Box>
              </ListItem>
              <ListItem
                button
                onClick={() => [
                  toggleDrawer(),

                  navigate("/SikcaSorulanSorular"),
                ]}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    sx: {
                      regular163838cursor,
                    },
                  }}
                  primary="Sıkça Sorulan Sorular"
                />
              </ListItem>
            </List>
          ) : (
            <List>
              <ListItem
                button
                onClick={() => [toggleDrawer(), navigate("/Hakkimizda")]}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Box>
                  <ListItemText
                    primaryTypographyProps={{
                      sx: {
                        regular163838cursor,
                      },
                    }}
                    primary="Hakkımızda"
                  />
                  <Box style={listItemFrame} />
                </Box>
              </ListItem>

              <ListItem
                button
                onClick={() =>
                  handleSub("Çözümlerimiz", 2675, "GoldtagGateway")
                }
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Box>
                  <ListItemText
                    primaryTypographyProps={{
                      sx: {
                        regular163838cursor,
                      },
                    }}
                    primary="Çözümlerimiz"
                  />
                  <Box
                    style={{ width: "343px", border: "1px solid #F5F5F5" }}
                  />
                </Box>
              </ListItem>
              <ListItem
                button
                onClick={() => handleSub("Calisma", 4160, "GoldtagGateway")}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Box>
                  <ListItemText
                    primaryTypographyProps={{
                      sx: {
                        regular163838cursor,
                      },
                    }}
                    primary="Çalışma Alanları"
                  />
                  <Box style={listItemFrame} />
                </Box>
              </ListItem>

              <ListItem
                button
                onClick={() => [
                  toggleDrawer(),
                  setClick("/Iletisim"),
                  navigate("/Iletisim"),
                ]}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    sx: {
                      regular163838cursor,
                    },
                  }}
                  primary="İletişim"
                />
              </ListItem>
            </List>
          )}
        </Drawer>
      </Box>
    </AppBar>
  );
}
export default Header;
