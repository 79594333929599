import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef } from "react";
import {
  Hisse,
  Kasa,
  TarimEmtia,
} from "../../../assets/svgs/GoldtagGatewaySvgs";
import {
  boxes3,
  featcontainer,
  inBoxes,
  subTextBox,
  title,
  titleBox,
} from "../../goldtagAppComponents/GoldtagFeatures/GoldtagFeaturesCSS";
import { svgFrameGat } from "./GatewayFeatuesCSS";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger); // ScrollTrigger'ı kaydedin

const BoxD = ({ delay, children }) => {
  const boxRef = useRef(null);

  useGSAP(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: boxRef.current,
        start: "top bottom", // Kutu, sayfanın üst kısmının %80'inde görünürse animasyonu başlat
      },
    });

    tl.from(boxRef.current, {
      duration: 1.5,
      opacity: 0,
      y: 150,
      ease: "expo",
      force3D: true,
      delay: delay,
    });
  }, [delay]);

  return (
    <Box ref={boxRef} sx={inBoxes}>
      {children}
    </Box>
  );
};

function GatewayFeatures(props) {
  const typo = useRef(null);

  useGSAP(() => {
    gsap.from(typo.current, {
      opacity: 0,
      y: -200,
      duration: 1.5,
      ease: "circ.in",
      scrollTrigger: {
        trigger: typo.current,
        start: "top bottom", // Kutu, sayfanın üst kısmının %80'inde görünürse animasyonu başlat
      },
    });
  }, []);

  return (
    <Box sx={featcontainer}>
      {/* ----------------Çözümlerimiz---------------- */}
      <Typography sx={title} ref={typo}>
        Çözümlerimiz
      </Typography>
      <Box sx={boxes3}>
        <BoxD sx={[inBoxes, { padding: "16px 0px" }]}>
          <Box style={svgFrameGat}>
            <img
              src={
                "https://goldtagimages.s3.eu-north-1.amazonaws.com/Kasa.webp          "
              }
              alt="Goldtag Win"
              width="370"
              height="152"
            />
          </Box>

          <Typography sx={[titleBox, { marginTop: "auto" }]}>
            Kıymetli Madenler
          </Typography>
          <Typography sx={[subTextBox, { marginBottom: "24px" }]}>
            Bankalar ile işbirliği içinde geliştirdiğimiz anlık alım-satım,
            hesap görüntüleme ve mütabakat API'lerini sunarak, kıymetli maden
            satışına başlamanıza olanak sağlar. Tüm emtialar, banka kasalarında
            1-1 oranında korunmaktadır.
          </Typography>
        </BoxD>
        <BoxD sx={[inBoxes, { padding: "16px 0px" }]}>
          <Box style={svgFrameGat}>
            <img
              src={
                "https://goldtagimages.s3.eu-north-1.amazonaws.com/Tarim.webp          "
              }
              alt="Goldtag Win"
              width="370"
              height="152"
            />
          </Box>

          <Typography sx={[titleBox, { marginTop: "auto" }]}>
            Tarım Emtiaları
          </Typography>
          <Typography sx={[subTextBox, { marginBottom: "24px" }]}>
            Platformumuz üzerinden Türkiye'nin 81 ilinde, buğday, mısır ve arpa
            gibi önemli tarım ürünlerini satma imkanına sahipsiniz. Bu kıymetli
            tarım emtiaları, alanında uzman ve anlaşmalı kurumlar tarafından son
            derece profesyonel bir şekilde depolanmakta ve korunmaktadır.
          </Typography>
        </BoxD>
        <BoxD sx={[inBoxes, { padding: "16px 0px" }]}>
          <Box style={svgFrameGat}>
            <img
              src={
                "https://goldtagimages.s3.eu-north-1.amazonaws.com/Hisse.webp          "
              }
              alt="Goldtag Win"
              width="370"
              height="152"
            />{" "}
          </Box>

          <Typography sx={[titleBox, { marginTop: "auto" }]}>
            Hisse Senetleri
          </Typography>
          <Typography sx={[subTextBox, { marginBottom: "24px" }]}>
            Goldtag Gateway ile, kullanıcılarınıza API aracılığıyla Nasdaq ve
            BIST hisse senedi alım-satım işlemlerinizi kolaylıkla gerçekleştirme
            fırsatı sunmaktayız. Yüksek güvenlik önlemleriyle desteklenen satış
            işlemlerine anında başlayabilirsiniz.
          </Typography>
        </BoxD>
      </Box>
    </Box>
  );
}

export default GatewayFeatures;
