import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { Suspense, useRef } from "react";
import {
  boxes3,
  cokYakndaText,
  cokyakindaBox,
  featcontainer,
  inBoxes,
  subTextBox,
  svgFrame,
  title,
  titleBox,
} from "./GoldtagFeaturesCSS";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"; // ScrollTrigger'ı içeri aktarın

gsap.registerPlugin(ScrollTrigger); // ScrollTrigger'ı kaydedin

const BoxD = ({ delay, children }) => {
  const boxRef = useRef(null);

  useGSAP(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: boxRef.current,
        start: "top bottom", // Kutu, sayfanın üst kısmının %80'inde görünürse animasyonu başlat
      },
    });

    tl.from(boxRef.current, {
      duration: 1.5,
      opacity: 0,
      y: 150,
      ease: "expo",
      force3D: true,
      delay: delay,
    });
  }, [delay]);

  return (
    <Box ref={boxRef} sx={inBoxes}>
      {children}
    </Box>
  );
};

function GoldtagFeatures(props) {
  const typo = useRef(null);

  useGSAP(() => {
    gsap.from(typo.current, {
      opacity: 0,
      y: -200,
      duration: 1.5,
      ease: "circ.in",
      scrollTrigger: {
        trigger: typo.current,
        start: "top bottom", // Kutu, sayfanın üst kısmının %80'inde görünürse animasyonu başlat
      },
    });
  }, []);

  return (
    <>
      <Box sx={featcontainer}>
        <Typography sx={title} ref={typo}>
          Goldtag ile Neler Yapabilirsin?
        </Typography>

        <Box sx={boxes3}>
          <BoxD sx={inBoxes} delay={0.5}>
            <Box sx={svgFrame}>
              <img
                src={
                  "https://goldtagimages.s3.eu-north-1.amazonaws.com/Group+43882.webp          "
                }
                alt="Goldtag Win"
                width="322"
                height="196"
              />
            </Box>

            <Typography sx={titleBox}>Hemen Birikime Başla</Typography>
            <Typography sx={subTextBox}>
              Goldtag kullanıcıları, değerli maden biriktirme hesabı açarak
              birikimlerini değerli maden olarak saklayabilirler. Böylece
              enflasyona karşı korunma sağlayabilir, uzun vadeli yatırım
              yapabilirler.
            </Typography>
          </BoxD>

          <BoxD sx={inBoxes} delay={0.8}>
            <Box sx={svgFrame}>
              <img
                src={
                  "https://goldtagimages.s3.eu-north-1.amazonaws.com/Group+43883.webp          "
                }
                alt="Goldtag Win"
                width="306"
                height="210"
              />
            </Box>

            <Typography sx={titleBox}>
              Farklı Türlerde Etkinlik Oluştur
            </Typography>
            <Typography sx={subTextBox}>
              Altın günü gibi özel günlerde arkadaşları veya aile üyeleriyle
              birlikte altın biriktirmek de mümkündür. Ayrıca Goldtag,
              kullanıcılarına hediye amaçlı kullanabileceği “Giftbox”
              seçenekleri sunar.
            </Typography>
          </BoxD>

          <BoxD sx={inBoxes} delay={1.1}>
            <Box sx={svgFrame}>
              <img
                src={
                  "https://goldtagimages.s3.eu-north-1.amazonaws.com/Group+43903.webp          "
                }
                alt="Goldtag Win"
                width="322"
                height="194"
              />
            </Box>

            <Typography sx={titleBox}>Anlık Bildirimler</Typography>
            <Typography sx={subTextBox}>
              Goldtag kullanıcıları, mobil uygulama üzerinden anlık bildirimler
              alarak, altın fiyatlarının değişimlerini takip edebilirler. Bu
              sayede, altın fiyatları değişirken alış-satış yaparak kazanç
              sağlayabilirler.
            </Typography>
          </BoxD>
        </Box>

        <Box sx={boxes3}>
          <BoxD sx={inBoxes} delay={0.5}>
            <>
              <Box sx={svgFrame}>
                <img
                  src={
                    "https://goldtagimages.s3.eu-north-1.amazonaws.com/Group+43910.webp          "
                  }
                  alt="Goldtag Win"
                  width="176"
                  height="176"
                />
              </Box>

              <Box sx={cokyakindaBox}>
                <Typography sx={cokYakndaText}>Çok Yakında</Typography>
              </Box>
            </>
            <Typography sx={titleBox}>Tarım Emtia Borsası</Typography>
            <Typography sx={subTextBox}>
              Goldtag kullanıcıları, mobil uygulama üzerinden tarım emtia
              borsalarında fiyat değişimlerini takip edebilirler. Bu sayede,
              birikimlerini buğday, arpa gibi tarım emtiaları şeklinde
              yapabilirler.
            </Typography>
          </BoxD>

          <BoxD sx={inBoxes} delay={0.8}>
            <Box style={svgFrame}>
              <img
                src={
                  "https://goldtagimages.s3.eu-north-1.amazonaws.com/Group+43911.webp          "
                }
                alt="Goldtag Win"
                width="292"
                height="194"
              />
            </Box>
            <Typography sx={titleBox}>Kumbara ile Hedeflerine Ulaş</Typography>
            <Typography sx={subTextBox}>
              Kullanıcılar, uygulamadan oluşturdukları kumbara hesapları ile
              birlikte düzenli birikimler yapabilir. Hedef belirleyerek
              topladıkları miktara ulaşıp birikimlerini kolayca yönetebilirler.
            </Typography>
          </BoxD>

          <BoxD sx={inBoxes} delay={1.1}>
            <Box sx={svgFrame}>
              <img
                src={
                  "https://goldtagimages.s3.eu-north-1.amazonaws.com/Group+427320235+(3)+(convert.io).webp     "
                }
                alt="Goldtag Win"
                width="292"
                height="194"
              />
            </Box>

            <Typography sx={titleBox}>Ortak Kumbara</Typography>
            <Typography sx={subTextBox}>
              Kullanıcılar, uygulamadan oluşturdukları kumbara hesapları ile
              birlikte paylaşımlı olarak düzenli birikimler yapabilir. Hedef
              belirleyerek topladıkları miktara ulaşıp birikimlerini kolayca
              yönetebilirler.
            </Typography>
          </BoxD>
        </Box>
        <Box sx={boxes3}>
          <BoxD sx={inBoxes} delay={0.5}>
            <>
              <Box sx={svgFrame}>
                <img
                  src={
                    "https://goldtagimages.s3.eu-north-1.amazonaws.com/Group+427320233.webp          "
                  }
                  alt="Goldtag Win"
                  width="176"
                  height="176"
                />
              </Box>
            </>
            <Typography sx={titleBox}>
              Fiziki Teslimat ile Altının Kapında
            </Typography>
            <Typography sx={subTextBox}>
              Goldtag Altın Hesabında bulunan dijital altınını fiziki olarak
              dönüştürerek adresine sipariş edebilirsin.
            </Typography>
          </BoxD>
          <BoxD sx={inBoxes} delay={0.8}>
            <Box style={svgFrame}>
              <img
                src={
                  "https://goldtagimages.s3.eu-north-1.amazonaws.com/Group+427320236.webp          "
                }
                alt="Goldtag Win"
                width="302"
                height="170"
              />
            </Box>
            <Typography sx={titleBox}>
              Portföy ile Birikimini Takip Et
            </Typography>
            <Typography sx={subTextBox}>
              Kullanıcılar, uygulamadan oluşturdukları portföy ile yaptıkları
              birikimleri tek bir yerde görebilir, değerlerinin artış ve
              azalışlarını kolayca takip edebilirler.
            </Typography>
          </BoxD>
          <BoxD sx={inBoxes} delay={1.1}>
            <>
              <Box sx={svgFrame}>
                <img
                  src={
                    "https://goldtagimages.s3.eu-north-1.amazonaws.com/Group+43912.webp          "
                  }
                  alt="Goldtag Win"
                  width="311"
                  height="166"
                />
              </Box>
            </>

            <Box sx={cokyakindaBox}>
              <Typography sx={cokYakndaText}>Çok Yakında</Typography>
            </Box>
            <Typography sx={titleBox}>
              Nasdaq & Borsa İstanbul Hisseleri
            </Typography>
            <Typography sx={subTextBox}>
              Borsa İstanbul hisseleri yanı sıra Nasdaq borsasından da hisse
              senetleri alıp birikim hedeflerine erişebilirsin.
            </Typography>
          </BoxD>
        </Box>
      </Box>
    </>
  );
}

export default GoldtagFeatures;
