import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { pageContainer } from "../../assets/styles/GeneralStyles";
import PageHeader from "../../components/PageHeader/PageHeader";
import QR from "../../components/QR/QR";
import SikcaSorulanSorularComp from "../../components/SikcaSorulanSorular/SikcaSorulanSorular";
import { Helmet } from "react-helmet-async";

function SikcaSorulanSorular(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Goldtag | SSS</title>
        <meta
          name="keywords"
          content="Goldtag,App,SSS,Sıkça Sorulan Sorular,Sıkça,Soru,Sorular,Problem,Hata,Nasıl,Yapılır,Neden"
        />
      </Helmet>
      <Box sx={pageContainer}>
        <PageHeader
          title={"Anasayfa > Sıkça Sorulan Sorular"}
          width={"300px"}
        />
        <SikcaSorulanSorularComp />
        <QR />
      </Box>
    </>
  );
}

export default SikcaSorulanSorular;
