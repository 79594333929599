import React, { useEffect } from "react";
import { pageContainer } from "../../assets/styles/GeneralStyles";
import { Box } from "@mui/material";
import LogoVeKullanimComp from "../../components/LogoVeKullanim/LogoVeKullanim";
import QR from "../../components/QR/QR";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Helmet } from "react-helmet-async";

function LogoVeKullanim(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Sadece bileşen yüklendiğinde çağrılması için boş bir bağımlılık dizisi kullanılır

  return (
    <>
      <Helmet>
        <title>Goldtag | Marka Kimliği </title>
        <meta name="keywords" content="Logo,Marka,Goldtag,Kılavuz" />
      </Helmet>
      <Box sx={pageContainer}>
        <PageHeader title={"Anasayfa > Marka Kılavuzu"} />

        <LogoVeKullanimComp />
        <QR />
      </Box>
    </>
  );
}

export default LogoVeKullanim;
