export const title20 = {
  color: "#383838",
  fontFamily: "Bold",
  fontSize: "20px",
  fontWeight: "600",
};

export const title18 = {
  color: "#383838",
  fontFamily: "Bold",
  fontSize: "18px",
  fontWeight: "600",
};

export const title16 = {
  color: "#383838",
  fontFamily: "Bold",
  fontSize: "16px",
  fontWeight: "600",
};

export const regular16 = {
  color: "#383838",
  fontFamily: "Regular",
  fontSize: "14px",
  fontWeight: "400",
};

export const regular14 = {
  color: "#383838",
  fontFamily: "Regular",
  fontSize: "14px",
  fontWeight: "400",
};

export const regular12 = {
  color: "#383838",
  fontFamily: "Regular",
  fontSize: "12px",
  fontWeight: "400",
};
