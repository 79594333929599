import bgdownload from "../../assets/images/bgdownload.svg";

export const qrcontainer = {
  height: "246px",
  width: "1120px",
  display: "flex",
  backgroundImage: `url(${bgdownload})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  alignSelf: "center",
  marginBottom: "64px",
};

export const frame32 = {
  display: "flex",
  marginTop: "47px",
  marginLeft: "56px",
  alignItems: "flex-start",
};

export const ucrt = {
  fontFamily: "Regular",
  fontSize: "24px",
  letterSpacing: "-0.49px",
};

export const smi = {
  fontFamily: "SemiBold",
  color: "#383838",
  fontSize: "24px",
  letterSpacing: "-0.48px",
  marginBottom: "24px",
};

export const frm = {
  display: "flex",
  marginLeft: "150px",
  alignItems: "center",
};

export const qrbrdr = {
  display: "flex",
  borderRadius: "24px",
  width: "140px",
  height: "140px",
  alignItems: "center",
  justifyContent: "center",
  background: "linear-gradient(135deg, #D2C8B5 24.71%, #F6F0E2 100%)",
};

export const qrwhite = {
  backgroundColor: "white",
  borderRadius: "16px",
  width: "122px",
  height: "122px",
};

export const mobfram = {
  width: "343px",
  height: "220px",
  borderRadius: "24px",
  bgcolor: "#F3EEE5",
  alignSelf: "center",
  flexDirection: "column",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const mobsmal = {
  flexDirection: "row",
  display: "flex",
  justifyContent: "center",
};

export const ulas = {
  bgcolor: "#D6B87C",
  color: "white",
  width: "270px",
  height: "48px",
  borderRadius: "12px",
  marginTop: "32px",
  cursor: "pointer",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  fontFamily: "Semibold",
  lineHeight: "28px",
  fontSize: "16px",
};
