import { Typography } from "@mui/material";
import React, { useRef } from "react";
import { meetsub, meettitle } from "./MeetOurTeamCSS";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

function MeetOurTeam(props) {
  const leftTextRef = useRef(null);
  const rightTextRef = useRef(null);

  useGSAP(() => {
    gsap.from(leftTextRef.current, {
      opacity: 0,
      x: -200,
      duration: 0.6,
      ease: "circ.inOut",
    });

    gsap.from(rightTextRef.current, {
      opacity: 0,
      x: 200,
      duration: 0.6,
      ease: "circ.inOut",
    });
  }, []);

  return (
    <>
      <Typography sx={meettitle} ref={rightTextRef}>
        Ekip üyelerimizi, tasarımcılarımızı ve dünya standartlarındaki
        mühendislerimizi tanıyın
      </Typography>

      <Typography sx={meetsub} ref={leftTextRef}>
        Goldtag projesi Fintag Yazılım Danışmanlık A.Ş. şirketinin bir
        projesidir. Fintag Yazılım Danışmanlık A.Ş, 2019 yılında kurulmuş olup
        yüzlerce firma arasından KOSGEB'in Arge ve İnovasyon desteğini almaya
        hak kazanmıştır. Aynı zamanda Goldtag, Türkiye'nin altın alanında lider
        bankası Kuveyt Türk tarafından kurulan Lonca Girişimcilik Merkezi'ne
        kabul almış olup bu alanda deneyimli kişilerle yakın çalışma fırsatı
        bulmuştur. Bu çalışmalar sırasında MASAK, BDDK ve TCMB gibi kurumlardan
        görüş alınmış olup gerekli güvenlik önlemleri bu hususlara göre dizayn
        edilmiştir. Goldtag, "Liranıza altın değer katın" sloganıyla çıktığı bu
        yolda siz değerli müşterilerinin beklentilerini amaç zincirinin
        merkezine almış olmaktan büyük onur duymaktadır.
      </Typography>
    </>
  );
}

export default MeetOurTeam;
