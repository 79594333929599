import * as React from "react";
const Tik = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <circle cx={16} cy={16} r={16} fill="#FFF3D6" fillOpacity={0.3} />
    <path
      fill="#D6B87C"
      stroke="#FFFCF3"
      strokeWidth={0.5}
      d="M23.184 13.705a1.78 1.78 0 0 0 0-2.518v2.518Zm0 0-8.297 8.296m8.297-8.296-8.297 8.296m0 0a1.778 1.778 0 0 1-2.518 0h2.518Zm8.12-10.638a1.527 1.527 0 0 1 0 2.165l-8.296 8.297a1.53 1.53 0 0 1-2.165 0L8.99 18.269a1.53 1.53 0 1 1 2.165-2.165l2.298 2.295.177.177.176-.177 7.039-7.04a1.528 1.528 0 0 1 2.162.004ZM28.694 16A12.694 12.694 0 1 0 16 28.694 12.707 12.707 0 0 0 28.694 16Zm3.056 0A15.75 15.75 0 1 1 16 .25 15.768 15.768 0 0 1 31.75 16Z"
    />
  </svg>
);
export default Tik;
