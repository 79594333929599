import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";

import Cookies from "../../assets/pdfs/Cookies.pdf";
import DownloadIcon from "@mui/icons-material/Download";

import { pageContainer } from "../../assets/styles/GeneralStyles";
import PageHeader from "../../components/PageHeader/PageHeader";
import { title20 } from "../../assets/styles/defaultFonts";
import { Helmet } from "react-helmet-async";
function GizlilikPolitikası(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Goldtag | Gizlilik Politikası </title>
        <meta name="keywords" content="Goldtag,App,Gizlilik,Politika,App" />
      </Helmet>{" "}
      <Box sx={pageContainer}>
        <PageHeader title={"Anasayfa > Gizlilik Politikası"} />
        {isMobile ? (
          <>
            <Box
              sx={{
                display: "flex",
                marginTop: "24px",
                flexDirection: "column",
                paddingRight: "20px",
                paddingLeft: "20px",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignSelf: "center",
                  fontFamily: "Regular",
                  color: "#383838",
                  marginBottom: "24px",
                  fontSize: "14px",
                  width: "343px",
                  textAlign: "center",
                }}
              >
                Goldtag uygulamamızı güvenle kullanabilmemiz için sizlere
                gizlilik ve çerez politikamızı sunuyoruz.
              </Typography>
            </Box>
            <a
              style={{
                borderRadius: "8px",
                border: "1px solid #ebebeb",
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "12px",
                marginTop: "16px",
                cursor: "pointer",
                textDecoration: "none",
                padding: "16px",
                width: "240px",
                alignSelf: "center",
              }}
              href={Cookies}
              download="Cookies"
              target="_blank"
              rel="noreferrer"
            >
              <a
                href={Cookies}
                download="KVKK"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "#383838",
                  fontFamily: "Regular",
                  fontSize: "14px",
                }}
                rel="noreferrer"
              >
                Gizlilik ve Çerez Politikası
              </a>
              <Box style={{ display: "flex" }}>
                <Typography
                  sx={{
                    color: "#383838",
                    fontSize: "12px",
                    fontFamily: "Regular",
                    marginRight: "0.5rem",
                  }}
                >
                  İndir
                </Typography>
                <DownloadIcon
                  sx={[
                    title20,
                    {
                      color: "#D6B87C",
                    },
                  ]}
                />{" "}
              </Box>
            </a>
          </>
        ) : (
          <Box sx={{ marginBottom: "72px" }}>
            <Typography
              sx={{
                fontSize: "56px",
                alignSelf: "center",
                textAlign: "center",
                marginTop: "56px",
                lineHeight: "67.2px",
                letterSpacing: "-2px",
                fontFamily: "SemiBold",
                color: "##383838",
              }}
            >
              Gizlilik ve Çerez Politikası
            </Typography>

            <Box
              sx={{
                display: "flex",
                margin: "0px 390px",
                marginTop: "32px",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignSelf: "center",
                  textAlign: "flex-start",
                  fontFamily: "Regular",
                  color: "#383838",
                  marginBottom: "24px",
                }}
              >
                Goldtag uygulamamızı güvenle kullanabilmemiz için sizlere
                gizlilik ve çerez politikamızı sunuyoruz.
              </Typography>

              <Box
                style={{
                  alignSelf: "center",
                  backgroundColor: "#F8F5F0",
                  paddingTop: "2.3rem",
                  paddingBottom: "3.2rem",
                  paddingRight: "2rem",
                  paddingLeft: "2rem",
                  borderRadius: "24px",
                  boxShadow: "1px 15px 16px rgba(173, 154, 117, 0.5)",
                }}
              >
                <Typography variant="h6" paragraph>
                  Fintag Yazılım Danışmanlık A.Ş.(“Goldtag”) olarak,
                  kullanıcılarımızın hizmetlerimizden güvenli ve eksiksiz
                  şekilde faydalanmalarını sağlamak amacıyla sitemizi kullanan
                  kişilerin gizliliğini korumak için çalışıyoruz.
                </Typography>

                <Typography variant="body1" paragraph>
                  Çoğu web sitesinde olduğu gibi, goldtag.org (“Site”) ile mobil
                  uygulamanın (hepsi birlikte “Platform” olarak anılacaktır)
                  ziyaretçilere kişisel içerik ve reklamlar göstermek, site
                  içinde analitik faaliyetler gerçekleştirmek ve ziyaretçi
                  kullanım alışkanlıklarını takip etmek amacıyla Çerezler
                  kullanılmaktadır.
                </Typography>

                <Typography variant="body1" paragraph>
                  İşbu Çerez Politikası Goldtag Kullanıcı Aydınlatma ve Gizlilik
                  Politikası’nın ayrılmaz bir parçasıdır. Goldtag, bu Çerez
                  Politikası’nı (“Politika”) Site’de hangi Çerezlerin
                  kullanıldığını ve kullanıcıların bu konudaki tercihlerini
                  nasıl yönetebileceğini açıklamak amacıyla hazırlamıştır.
                  Goldtag tarafından kişisel verilerinizin işlenmesine ilişkin
                  daha detaylı bilgi için goldtag.org adlı sitenin Gizlilik
                  Politikası ve Çerezler adlı bölümünden incelemenizi tavsiye
                  ederiz.
                </Typography>

                <Typography variant="h6" paragraph>
                  Çerez (“Cookie”) Nedir?
                </Typography>

                <Typography variant="body1" paragraph>
                  Çerezler, ziyaret ettiğiniz internet siteleri tarafından
                  tarayıcılar aracılığıyla cihazınıza veya ağ sunucusuna
                  depolanan küçük metin dosyalarıdır. Çerezler, ziyaret
                  ettiğiniz web sitesiyle ilişkili sunucular tarafından
                  oluşturulurlar. Böylelikle ziyaretçi aynı siteyi ziyaret
                  ettiğinde sunucu bunu anlayabilir.
                </Typography>

                <Typography variant="body1" paragraph>
                  Çerezler, ziyaretçilere ilişkin isim, cinsiyet veya adres gibi
                  kişisel verileri içermezler. Çerezler konusunda daha detaylı
                  bilgi için www.aboutcookies.org ve www.allaboutcookies.org
                  adreslerini ziyaret edebilirisiniz.
                </Typography>

                <Typography variant="h6" paragraph>
                  Hangi Çerezler Kullanılmaktadır?
                </Typography>

                <Typography variant="body1" paragraph>
                  Çerezler, sahipleri, kullanım ömürleri ve kullanım amaçları
                  açısından kategorize edilebilir:
                </Typography>

                <Typography variant="body1" paragraph>
                  <strong>Çerezi yerleştiren tarafa göre:</strong> Platform
                  çerezleri ve üçüncü taraf Çerezler kullanılmaktadır. Platform
                  çerezleri, Goldtag tarafından oluşturulurken, üçüncü taraf
                  çerezlerini Goldtag ile iş birlikteliği olan farklı firmalar
                  yönetmektedir.
                </Typography>

                <Typography variant="body1" paragraph>
                  <strong>Aktif olduğu süreye göre:</strong> Oturum çerezleri ve
                  kalıcı çerezler kullanılmaktadır. Oturum çerezleri
                  ziyaretçinin Platform’u terk etmesiyle birlikte silinirken,
                  kalıcı çerezler ise kullanım alanına bağlı olarak çeşitli
                  sürelerle ziyaretçilerin cihazlarında kalabilmektedir.
                </Typography>

                <Typography variant="body1" paragraph>
                  <strong>Kullanım amaçlarına göre:</strong> Platform’da teknik
                  çerezler, doğrulama çerezleri, hedefleme/reklam çerezleri,
                  kişiselleştirme çerezleri ve analitik çerezler
                  kullanılmaktadır.
                </Typography>

                <Typography variant="h6" paragraph>
                  Neden Çerezler Kullanılmaktadır?
                </Typography>

                <Typography variant="body1" paragraph>
                  Platform’da, Çerezler aşağıdaki amaçlar kapsamında
                  kullanılmaktadır:
                </Typography>

                <Typography variant="body1" paragraph>
                  <strong>
                    Platform’un çalışması için gerekli temel fonksiyonları
                    gerçekleştirmek:
                  </strong>{" "}
                  Örneğin, Goldtag üyelerinin, Goldtag hesaplarında etkinlik
                  oluşturma süresince tekrar giriş yapmalarının engellenmesi.
                  Oturum açan üyelerin Platform’da farklı sayfaları ziyaret
                  ederken tekrar şifre girmelerine gerek kalmaması.
                </Typography>

                <Typography variant="body1" paragraph>
                  <strong>
                    Platform’u analiz etmek ve Platform’un performansını
                    arttırmak:
                  </strong>{" "}
                  Örneğin, Platform’un üzerinde çalıştığı farklı sunucuların
                  entegrasyonu, Platform’u ziyaret edenlerin sayısının tespit
                  edilmesi ve buna göre performans ayarlarının yapılması ya da
                  ziyaretçilerin aradıklarını bulmalarının kolaylaştırılması.
                </Typography>

                <Typography variant="body1" paragraph>
                  <strong>
                    Platform’un işlevselliğini arttırmak ve kullanım kolaylığı
                    sağlamak:
                  </strong>{" "}
                  Örneğin, Platform üzerinden üçüncü taraf sosyal medya
                  mecralarına paylaşımda bulunmak, Platform’u ziyaret eden
                  ziyaretçinin daha sonraki ziyaretinde kullanıcı adı bilgisinin
                  ya da arama sorgularının hatırlanması.
                </Typography>

                <Typography variant="h6" paragraph>
                  Çerez Tercihlerinizi Nasıl Yönetebilirsiniz?
                </Typography>

                <Typography variant="body1" paragraph>
                  Goldtag, kullanıcıların kendilerine ait kişisel veriler
                  üzerindeki tercihlerini kullanabilmelerini son derece
                  önemsemektedir. Bununla birlikte, site ve uygulamanın
                  çalışması için zorunlu olan bazı Çerezler konusunda tercih
                  yönetimi mümkün olmamaktadır. Ayrıca bazı Çerezlerin
                  kapatılması halinde Site’nin çeşitli fonksiyonlarının
                  çalışmayabileceğini hatırlatma isteriz.
                </Typography>

                <Typography variant="body1" paragraph>
                  Platform’da kullanılan Çerezlere dair tercihlerin ne şekilde
                  yönetebileceğine dair bilgiler aşağıdaki gibidir:
                </Typography>

                <Typography variant="body1" paragraph>
                  <strong>
                    Ziyaretçiler, Platform’u görüntüledikleri tarayıcı
                    ayarlarını değiştirerek çerezlere ilişkin tercihlerini
                    kişiselleştirme imkanına sahiptir.
                  </strong>{" "}
                  Eğer kullanılmakta olan tarayıcı bu imkânı sunmaktaysa,
                  tarayıcı ayarları üzerinden Çerezlere ilişkin tercihleri
                  değiştirmek mümkündür. Böylelikle, tarayıcının sunmuş olduğu
                  imkanlara göre farklılık gösterebilmekle birlikte, veri
                  sahiplerinin çerezlerin kullanılmasını engelleme, çerez
                  kullanılmadan önce uyarı almayı tercih etme veya sadece bazı
                  Çerezleri devre dışı bırakma ya da silme imkanları
                  bulunmaktadır. Bu konudaki tercihler kullanılan tarayıcıya
                  göre değişiklik göstermekle birlikte genel açıklamaya{" "}
                  <a
                    href="https://www.aboutcookies.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    buradan ulaşmak
                  </a>
                  mümkündür. Çerezlere ilişkin tercihlerin, ziyaretçinin
                  Platform’a erişim sağladığı her bir cihaz özelinde ayrı ayrı
                  yapılması gerekebilecektir.
                </Typography>

                <Typography variant="body1" paragraph>
                  <strong>
                    <a
                      href="https://tools.google.com/dlpage/gaoptout"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Google Analytics tarafından yönetilen Çerezleri kapatmak
                      için tıklayınız.
                    </a>
                  </strong>
                </Typography>

                <Typography variant="body1" paragraph>
                  <strong>
                    <a
                      href="https://adssettings.google.com/authenticated"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Google tarafından sağlanan kişiselleştirilmiş reklam
                      deneyimini yönetmek için tıklayınız.
                    </a>
                  </strong>
                </Typography>

                <Typography variant="body1" paragraph>
                  <strong>
                    <a
                      href="https://www.youronlinechoices.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Your Online Choices
                    </a>
                  </strong>{" "}
                  üzerinden birçok firmanın reklam faaliyetleri için kullandığı
                  çerezler bakımından tercihler yönetilebilir.
                </Typography>

                <Typography variant="body1" paragraph>
                  <strong>
                    Mobil cihazlar üzerinden Çerezleri yönetmek için mobil
                    cihaza ait ayarlar menüsü kullanılabilir.
                  </strong>
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}

export default GizlilikPolitikası;
