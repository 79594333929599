export const whycontainer = {
  marginTop: "144px",
  alignSelf: "center",
  display: "flex",
  flexDirection: { xs: "column", sm: "row", md: "row" },
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "128px",
};

export const whytitle = {
  fontFamily: "Semibold",
  fontSize: { xs: "24px", sm: "48px", md: "48px" },
  lineHeight: { xs: "31.2px", sm: "64px", md: "64px" },
  color: "#383838",
  marginTop: { xs: "24px", sm: null, md: null },
};

export const subtext = {
  fontFamily: "Regular",
  fontSize: { xs: "14px", sm: "18px", md: "18px" },
  lineHeight: "28px",
  color: "#383838",
  width: { xs: "311px", sm: "570px", md: "570px" },
  marginTop: "16px",
  marginBottom: "56px",
};

export const infos = {
  fontFamily: "Medium",
  fontSize: { xs: "20px", sm: "32px", md: "32px" },
  lineHeight: { xs: "16px", sm: "26px", md: "26px" },
  color: "#383838",
  textAlign: "center",
  marginBottom: "8px",
};

export const subInfo = {
  fontFamily: "Regular",
  fontSize: { xs: "12px", sm: "16px", md: "16px" },
  fontWeight: 400,
  color: "#383838",
  textAlign: "center",
};

export const cntrcntr = {
  justifyContent: "center",
  alignItems: "center",
};

export const frm = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};
