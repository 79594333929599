import { Box } from "@mui/material";
import React, { useEffect } from "react";
import {
  mainContainer,
  pageContainer,
} from "../../assets/styles/GeneralStyles";
import PageHeader from "../../components/PageHeader/PageHeader";
import background from "../../assets/images/background.jpeg";
import MeetOurTeam from "../../components/Hakkimizda/MeetOurTeam/MeetOurTeam";
import Partnerlerimiz from "../../components/Hakkimizda/Partnerlerimiz/Partnerlerimiz";
import HappyCustomer from "../../components/HappyCustomers/HappyCustomer";
import ContactUs from "../../components/ContactUs/ContactUs";
import PaparaMini from "../../assets/svgs/Minilogos/PaparaMini";
import { useGSAP } from "@gsap/react";
import { Helmet } from "react-helmet-async";

function Hakkimizda(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Goldtag | Hakkımızda </title>
        <meta
          name="keywords"
          content="Goldtag,App,Müşteriler,Partnerlerimiz,Tasarımcılarımız,Ekip,Yazılım,Takım,Finansal,Teknoloji"
        />
      </Helmet>

      <Box sx={pageContainer}>
        <PageHeader title={"Anasayfa > Hakkımızda"} />
        <MeetOurTeam />
        <Partnerlerimiz />
        <HappyCustomer />
        <ContactUs />
      </Box>
    </>
  );
}

export default Hakkimizda;
