import React, { useRef, useState, useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const flyIconAnimation = (element, x, y) => {
  gsap.fromTo(
    element,
    { opacity: 0, x: 360, y: -650, zIndex: 1 },
    {
      x,
      y,
      opacity: 1,
      z: 11,
      ease: "circ.inOut",
      duration: 2,
      scrollTrigger: {
        trigger: element,
      },
    }
  );
};

function PhoneWithAnimate(props) {
  const [images] = useState({
    image2: "https://goldtagimages.s3.eu-north-1.amazonaws.com/FirstIMG.webp",
    currency: "https://goldtagimages.s3.eu-north-1.amazonaws.com/currency.webp",
    growth: "https://goldtagimages.s3.eu-north-1.amazonaws.com/growth.webp",
    gold: "https://goldtagimages.s3.eu-north-1.amazonaws.com/gold.webp",
    money: "https://goldtagimages.s3.eu-north-1.amazonaws.com/money.webp",
    mobileImage:
      "https://goldtagimages.s3.eu-north-1.amazonaws.com/goldtag_web_mockup_1%2B1%2B(1).webp",
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const boxes = useRef();

  const marginFrm = {
    position: "relative",
    marginTop: isMobile ? "40px" : "88px",
    marginBottom: !isMobile ? "6rem" : null,
  };

  useEffect(() => {
    if (!isMobile) {
      flyIconAnimation(".fly-icon-1", 525, -800);
      flyIconAnimation(".fly-icon-2", 760, -1130);
      flyIconAnimation(".fly-icon-3", -200, -807);
      flyIconAnimation(".fly-icon-4", -435, -1135);

      gsap.from(boxes.current, {
        duration: 1,
        rotation: 360,
        opacity: 1,
        ease: "sine.out",
        force3D: true,
      });
    }
  }, [isMobile]);

  return (
    <Box sx={marginFrm}>
      {isMobile ? (
        <Box ref={boxes}>
          <img
            src={images.mobileImage}
            alt="Goldtag Mockup"
            style={{ marginBottom: "24px" }}
            width="331"
            height="440"
          />
        </Box>
      ) : (
        <div style={marginFrm}>
          <Box ref={boxes}>
            <img
              src={images.mobileImage}
              alt="Goldtag Mockup"
              width="598"
              height="665"
            />
          </Box>

          <div className="fly-icon-1" style={{ position: "absolute" }}>
            <img
              src={images.currency}
              alt="Goldtag Mockup"
              width="140"
              height="140"
            />
          </div>
          <div className="fly-icon-2" style={{ position: "absolute" }}>
            <img
              src={images.growth}
              alt="Goldtag Mockup"
              width="140"
              height="140"
            />
          </div>
          <div className="fly-icon-3" style={{ position: "absolute" }}>
            <img
              src={images.gold}
              alt="Goldtag Mockup"
              width="140"
              height="140"
            />
          </div>
          <div className="fly-icon-4" style={{ position: "absolute" }}>
            <img
              src={images.money}
              alt="Goldtag Mockup"
              width="140"
              height="140"
            />
          </div>
        </div>
      )}
    </Box>
  );
}

export default PhoneWithAnimate;
