import React, { useEffect } from "react";
import { mainContainer } from "../../assets/styles/GeneralStyles";
import { Box } from "@mui/material";
import Marque from "../../components/marquee/Marque";
import QR from "../../components/QR/QR";
import {
  GoldtagFeatures,
  TopTypoComponent,
  ThreeBoxes,
  PhoneWithAnimate,
  ThreePhones,
  GoldtagSecurity,
} from "../../components/goldtagAppComponents";
import { Helmet } from "react-helmet-async";

function GoldtagApp() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Sadece bileşen yüklendiğinde çağrılması için boş bir bağımlılık dizisi kullanılır
  return (
    <>
      <Helmet>
        <title>Goldtag App | Bireysel</title>
        <meta
          name="keywords"
          content="Goldtag,App,Altın,Gümüş,Biriktir,Kazan,Birikim,Tarım,Emtia,Etkinlik,Düğün,Altın Günü, Bildirimler,Bireysel,Paycell,Papara,Goldtag,RePie,Collendi,Moneypay,Kuveyttürk"
        />
      </Helmet>

      <Box sx={mainContainer}>
        <TopTypoComponent />
        <PhoneWithAnimate />
        <Marque />
        <ThreeBoxes />
        <GoldtagSecurity />
        <GoldtagFeatures />
        <ThreePhones />
        <QR />
      </Box>
    </>
  );
}

export default GoldtagApp;
