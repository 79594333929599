import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { Suspense } from "react";
import { Contact } from "../../assets/svgs/FooterSvgs";
import { Yildiz } from "../../assets/svgs/GoldtagAppSvgs";
import { useNavigate } from "react-router-dom";
import {
  abs,
  cntain,
  contactcon,
  semi203838,
  tit2438,
  uls,
} from "./ContactUsCSS";
import { mobfram, mobsmal, ulas } from "../QR/QRCSS";
import { flexrowcnt } from "../HappyCustomers/HappyCustomerCSS";
import { regular16 } from "../../assets/styles/defaultFonts";
import {
  regular163838,
  semibold163838,
} from "../../assets/styles/GeneralStyles";

function ContactUs(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const Yildiz = React.lazy(() =>
    import("../../assets/svgs/GoldtagAppSvgs").then((module) => ({
      default: module.Yildiz,
    }))
  );

  return (
    <>
      {/* -------------bize ulaşın görsel----------- */}
      {!isMobile ? (
        <Box style={contactcon}>
          <img
            src={
              "https://goldtagimages.s3.eu-north-1.amazonaws.com/contactbg.webp"
            }
            alt="Goldtag Win"
            width="1120"
            height="246"
          />
          <Box sx={abs}>
            <Typography style={tit2438}>
              Goldtag Gateway kolay entegrasyon ile ilgili
            </Typography>
            <Typography style={semi203838}>
              daha fazla bilgiye mi ihtiyacınız var?
            </Typography>
            <Typography sx={ulas} onClick={() => navigate("/Iletisim")}>
              Bize Ulaşın
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box sx={mobfram}>
          <Box sx={mobsmal}>
            <Suspense fallback={<div>Loading...</div>}>
              <Yildiz />
            </Suspense>
            <Typography sx={[regular163838, { width: "243px" }]}>
              Goldtag Gateway kolay entegrasyon ile ilgili{" "}
              <span style={semibold163838}>
                {" "}
                daha fazla bilgiye mi ihtiyacınız var?{" "}
              </span>
            </Typography>
          </Box>
          <Typography sx={ulas} onClick={() => navigate("/Iletisim")}>
            Bize Ulaşın
          </Typography>
        </Box>
      )}
    </>
  );
}

export default ContactUs;
