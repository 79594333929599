import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { pageContainer } from "../../assets/styles/GeneralStyles";
import UcretlerLimitler from "../../components/UcretlerLimitler/UcretlerLimitler";
import PageHeader from "../../components/PageHeader/PageHeader";
import ContactUs from "../../components/ContactUs/ContactUs";
import { Helmet } from "react-helmet-async";

function UcretlerVeLimitler(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Goldtag | Ücretler ve Limitler </title>
        <meta
          name="keywords"
          content="Goldtag,App,Ücretler,Limitler,Fiyat,Fiyatlandırma,Ne,Kadar"
        />
      </Helmet>
      <Box sx={pageContainer}>
        <PageHeader title={"Anasayfa > Ücretler ve Limitler"} width={"300px"} />
        <UcretlerLimitler />
        <ContactUs />
      </Box>
    </>
  );
}

export default UcretlerVeLimitler;
