import * as React from "react";
const ArrowDownK = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#383838"
      d="M9 13.4a1.49 1.49 0 0 1-.557-.107 1.3 1.3 0 0 1-.447-.287L1.395 6.403C1.132 6.14 1 5.806 1 5.399c0-.406.132-.741.395-1.004S1.993 4 2.399 4c.407 0 .742.132 1.005.395L9 9.992l5.596-5.597c.264-.263.598-.395 1.005-.395.406 0 .741.132 1.004.395.264.263.395.598.395 1.004 0 .407-.131.742-.395 1.005l-6.6 6.601a1.266 1.266 0 0 1-.467.306c-.167.06-.347.09-.538.09Z"
    />
  </svg>
);
export default ArrowDownK;
