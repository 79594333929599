import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { mainContainer } from "../../assets/styles/GeneralStyles";
import Marque from "../../components/marquee/Marque";
import { FourBox, TopTypo } from "../../components/goldtagGateway";
import WhyGateway from "../../components/goldtagGateway/WhyGateway/WhyGateway";
import GatewayFeatures from "../../components/goldtagGateway/GatewayFeatures/GatewayFeatures";
import DropDownInfo from "../../components/goldtagGateway/DropdownInfo/DropDownInfo";
import ContactUs from "../../components/ContactUs/ContactUs";
import { Helmet } from "react-helmet-async";

function GoldtagGateway(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Sadece bileşen yüklendiğinde çağrılması için boş bir bağımlılık dizisi kullanılır

  return (
    <>
      <Helmet>
        <title>Goldtag Gateway | Kurumsal </title>
        <meta
          name="keywords"
          content="Goldtag,App,Partner,Gateway,Kurumsal,API,Paycell,Papara,Goldtag,RePie,Collendi,Moneypay,Kuveyttürk,Entegrasyon,Güvenlik,"
        />
      </Helmet>

      <Box style={mainContainer}>
        <TopTypo />
        <Marque />
        <FourBox />
        <WhyGateway />
        <GatewayFeatures />
        <DropDownInfo />
        <ContactUs />
      </Box>
    </>
  );
}

export default GoldtagGateway;
