export const titleStyleMobile = {
  color: "#383838",
  fontSize: "14px",
  fontFamily: "SemiBold",
  letterSpacing: "-0.28px",
  marginLeft: "16px",
};

export const boxStyleMobile = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "12px",
  width: "343px",
  marginTop: "16px",
};

export const boxStyleBorderMobile = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "white",
  borderRadius: "12px",
  border: "0.244px solid #EAE5DE",
  width: "343px",
  marginTop: "16px",
};

export const subTitleStyleMobile = {
  color: "#9C9CAB",
  fontSize: "10px",
  fontFamily: "Regular",
  width: "249px",
  marginTop: "4px",
  marginBottom: "16px",
  marginLeft: "16px",
};

export const amountStyle = {
  fontFamily: "SemiBold",
  color: "#383838",
  fontsize: "14px",
  letterSpacing: "-0.28px",
  marginRight: "16px",
};

export const subPageTitle = {
  fontSize: "56px",
  alignSelf: "center",
  textAlign: "center",
  width: "500px",
  lineHeight: "67.2px",
  letterSpacing: "-2px",
  fontFamily: "SemiBold",
  color: "##383838",
  marginBottom: "40px",
  marginTop: "56px",
};

export const boxContainer = {
  display: "flex",
  alignItems: "center",
  alignSelf: "center",
  width: "470px",
  height: "56px",
  borderRadius: " 12px",
  border: "0.244px solid #EAE5DE",
  marginBottom: "60px",
  background: "#FFF",

  boxShadow:
    "0px 0px 21.95122px 0px rgba(184, 184, 184, 0.15) inset, 0.4878px 0.4878px 4.87805px 0px rgba(173, 154, 117, 0.12)",
};

export const sectionSel = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "285px",
  height: "48px",
  border: "0.244px solid #EAE5DE",
  borderRadius: "8px",
  cursor: "pointer",
  boxShadow:
    "0px 0px 21.95122px 0px rgba(184, 184, 184, 0.15) inset, 0.4878px 0.4878px 4.87805px 0px rgba(173, 154, 117, 0.12)",
};

export const bigCon = {
  alignSelf: "center",
  display: "flex",
  flexDirection: "column",
  width: "928px",
  marginBottom: "56px",
};

export const inCon = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "16px",
  padding: "20px 14px",
};
export const incoNbor = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "white",
  borderRadius: "16px",
  border: "0.244px solid #EAE5DE",
  padding: "20px 14px",
};

export const intit = {
  color: "#383838",
  fontSize: "20px",
  letterSpacing: "-0.4px",
  fontFamily: "SemiBold",
  letterSpacing: "-0.4px",
};

export const ucrtsz = {
  fontFamily: "SemiBold",
  color: "#383838",
  fontsize: "24px",
  letterSpacing: "-0.4px",
};

export const limcon = {
  width: "928px",
  height: "762px",
  color: "#383838",
  alignSelf: "center",
  display: "flex",
};

export const frm = { width: "400px", paddingTop: "72px", marginRight: "50px" };

export const subtt = {
  color: "#9C9CAB",
  fontSize: "14px",
  fontFamily: "Regular",
  letterSpacing: " -0.4px",
};

export const dvdr = {
  width: "928px",
  height: "0.5px",
  background: "#E4E1D9",
  position: "absolute",
};

export const mrgn = { marginTop: "24px", marginBottom: "12px" };

export const stn = {
  width: "228px",
  height: "605px",
  backgroundColor: "#fff",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  border: "0.244px solid #EAE5DE",
  boxShadow:
    " 0px 0px 21.95122px 0px rgba(184, 184, 184, 0.15) inset, 0.4878px 0.4878px 4.87805px 0px rgba(173, 154, 117, 0.12)",
};

export const titccb382 = {
  fontFamily: "SemiBold",
  letterSpacing: "-0.36px",
  color: "#CCB382",
  marginTop: "24px",
};

export const stndvdr = {
  width: "228px",
  height: "0.5px",
  background: "#CCB382",
  marginTop: "4px",
  marginBottom: "24px",
};

export const stnframe = {
  marginTop: "18px",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
};

export const stnframe2 = {
  marginTop: "26px",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
};

export const stnframe3 = {
  marginTop: "40px",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
};

// MOBILE style

export const mobcontainer = {
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  backgroundSize: "cover",
  backgroundPosition: "center",
  paddingTop: "1.5rem",
  marginTop: "71px",
  alignItems: "center",
  overflow: "hidden",
};

export const mobtit = {
  fontSize: "24px",
  alignSelf: "center",
  textAlign: "center",
  width: "500px",
  lineHeight: "28.8px",
  letterSpacing: "-1px",
  fontFamily: "SemiBold",
  color: "##383838",
  marginBottom: "40px",
};

export const mobbox = {
  display: "flex",
  alignItems: "center",
  alignSelf: "center",
  width: "343px",
  height: "56px",
  borderRadius: " 8px",
  border: "0.178px solid #EAE5DE",
  marginBottom: "60px",
  background: "#FFF",
  boxShadow:
    "0px 0px 21.95122px 0px rgba(184, 184, 184, 0.15) inset, 0.4878px 0.4878px 4.87805px 0px rgba(173, 154, 117, 0.12)",
};
