import * as React from "react";
const VectorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={55}
    height={55}
    fill="none"
    {...props}
  >
    <path
      stroke="#D6B87C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={1.5}
      strokeWidth={3}
      d="M43.723 44.565c1.19-9.21.052-18.648-6.185-26.094-5.63-6.72-13.819-8.59-18.455.23-2.195 4.174-2.007 10.59 1.95 13.698 4.408 3.461 8.083-2.902 8.214-6.62.306-8.682-6.134-17.41-15.59-18.506C2.456 5.976 2.92 17.564 2.991 17.536"
    />
    <path
      stroke="#D6B87C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={1.5}
      strokeWidth={3}
      d="M52.076 38.463c-1.007.2-1.821.794-2.664 1.345-2.767 1.81-5.169 4.644-5.947 7.932-.314-3.647-1.952-8.277-5.4-9.981"
    />
  </svg>
);
export default VectorIcon;
