import background from "../../assets/images/background.jpeg";

export const mainContainer = {
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
  overflow: "hidden",
  justifyContent: "center",
  paddingTop: { xs: "8rem", sm: "4rem", md: "4rem" },
  marginTop: { xs: null, sm: "150px", md: "150px" },
  alignItems: "center",
};

export const pageContainer = {
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  paddingTop: "1.5rem",
  marginTop: "71px",
  overflow: "hidden",
};

export const regular163838cursor = {
  color: "#383838",
  fontSize: "16px",
  fontFamily: "Regular",
  cursor: "pointer",
};

export const regular163838 = {
  color: "#383838",
  fontSize: "16px",
  fontFamily: "Regular",
  cursor: "pointer",
};

export const semibold163838cursor = {
  color: "#383838",
  fontSize: "16px",
  fontFamily: "Semibold",
  cursor: "pointer",
};

export const semibold163838 = {
  color: "#383838",
  fontSize: "16px",
  fontFamily: "Semibold",
  cursor: "pointer",
};

export const regular14383838cursor = {
  color: "#383838",
  fontSize: "14px",
  fontFamily: "Regular",
  cursor: "pointer",
};

export const semibold14D6B87Ccursor = {
  color: "#D6B87C",
  fontSize: "14px",
  fontFamily: "Semibold",
  cursor: "pointer",
};

export const whitesemibold16 = {
  color: "white",
  fontFamily: "Semibold",
  fontSize: "16px",
  lineHeight: "28px",
};

export const listItemFrame = {
  width: "343px",
  border: "1px solid #F5F5F5",
};

export const appStrGgleStrBox = {
  bgcolor: "black",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: 170,
  height: 64,
};

export const title16semibold = {
  fontFamily: "Semibold",
  fontSize: "16px",
  lineHeight: "20.8px",
};

export const titlte20regular = {
  color: "#383838",
  fontFamily: "Regular",
  fontSize: "14px",
  lineHeight: "18.2px",
};
