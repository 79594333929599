import React, { useEffect } from "react";
import { pageContainer } from "../../assets/styles/GeneralStyles";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Box } from "@mui/material";
import Iletisim from "../../components/Iletisim/Iletisim";
import QR from "../../components/QR/QR";
import { Helmet } from "react-helmet-async";

function Contact(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Sadece bileşen yüklendiğinde çağrılması için boş bir bağımlılık dizisi kullanılır

  return (
    <>
      <Helmet>
        <title>Goldtag | İletişim </title>
        <meta
          name="keywords"
          content="Goldtag,App,Ofis,Telefon,E-Posta,Ankara"
        />
      </Helmet>

      <Box sx={pageContainer}>
        <PageHeader title={"Anasayfa > Bize Ulaşın"} />
        <Iletisim />
        <QR />
      </Box>
    </>
  );
}

export default Contact;
