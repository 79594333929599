export const featcontainer = {
  alignSelf: "center",
  marginBottom: { xs: "72px", sm: "180px", md: "180px" },
  display: { xs: "flex", sm: null, md: null },
  alignItems: "center",
  width: "100%",
  flexDirection: "column",
  marginTop: "0px",
};

export const title = {
  fontFamily: "Semibold",
  lineHeight: { xs: "31.2px", sm: "62.4px", md: "62.4px" },
  color: "#383838",
  fontSize: { xs: "24px", sm: "48px", md: "48px" },
  marginTop: "16px",
  marginBottom: { xs: "32px", sm: "80px", md: "80px" },
};

export const boxes3 = {
  display: "flex",
  flexDirection: { xs: "column", sm: "row", md: "row" },
  marginBottom: { xs: "0px", sm: "30px", md: "30px" },
  justifyContent: { xs: null, sm: "center", md: "center" },
  alignItems: { xs: "center", sm: null, md: null },
};

export const inBoxes = {
  width: { xs: "343px", sm: "370px", md: "370px" },
  height: { xs: "437px", sm: "472px", md: "472px" },
  borderRadius: "16px",
  border: "0.244px solid #EAE5DE",
  boxShadow:
    "0px 0px 22px 0px rgba(182,162,117,0.15) inset, 0.4878px 0.4878px 4.87805px 0px rgba(173, 154, 117, 0.12)",
  marginRight: { xs: null, sm: null, md: "30px" },
  display: "flex",
  flexDirection: "column",
  marginBottom: { xs: "24px", sm: "0px", md: "0px" },
};

export const svgFrame = {
  width: "322px",
  height: "196px",
  alignSelf: "center",
  marginTop: "32px",
  marginBottom: "38px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const titleBox = {
  fontFamily: "Semibold",
  lineHeight: { xs: "24.1px", sm: "26px", md: "26px" },
  color: "#383838",
  fontSize: { xs: "18px", sm: "20px", md: "20px" },
  marginBottom: "12px",
  textAlign: "left",
  marginLeft: "24px",
};

export const subTextBox = {
  fontFamily: "Regular",
  lineHeight: { xs: "24.1px", sm: "26px", md: "26px" },
  color: "#383838",
  fontSize: { xs: "14px", sm: "16px", md: "16px" },
  textAlign: "left",
  marginLeft: "24px",
  width: { xs: "298.5px", sm: "322px", md: "322px" },
  textAlign: "justify",
};

export const cokyakindaBox = {
  marginRight: "30px",
  position: "absolute",
  marginTop: "220px",
  marginLeft: "24px",
  bgcolor: "#CFB582",
  borderRadius: "4px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "90.85px",
  height: "22.25px",
};

export const cokYakndaText = {
  fontFamily: "Semibold",
  color: "#fff",
  fontSize: "12.98px",
  lineHeight: "24.1px",
};
