import drawertop from "../../assets/images/drawertop.png";

export const appBarStyle = {
  backgroundColor: "#FBF9F1",
  paddingTop: "1.5rem",
  width: "100%",
  boxShadow: "0px 2px 4px rgba(33, 33, 33, 0.2)", // Add a subtle shadow,
};

export const headerFrame = {
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  paddingLeft: "135px",
  paddingRight: "8.375rem",
  height: "67.5px",
  borderBottom: "1px solid rgba(33, 33, 33, 0.1)",
};

export const logoFrame = {
  marginBottom: "1rem",
  cursor: "pointer",
  marginRight: "3.6875rem",
};

export const antTabFrame = {
  height: "56px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
};

export const antSubFrame = {
  display: "flex",
  flexDirection: "row",
  paddingLeft: "135px",
  height: "80.5px",
  width: "100%",
  backgroundColor: "#FBF9F1",
  marginTop: "0px",
  alignItems: "center",
  gap: "2rem",
};

export const frame80 = {
  height: "80.5px",
  width: "100%",
};

export const appBarMobile = {
  backgroundImage: `url(${drawertop})`,
  justifyContent: "flex-start",
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  height: "72px",
  boxShadow: "0px 2px 4px rgba(33, 33, 33, 0.2)", // Add a subtle shadow,
  overflow: "hidden",
};

export const inFrameMobile = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: "16px",
};

export const logoFrameMobile = {
  marginBottom: "1rem",
  paddingLeft: "1.5rem",
};

export const rightIconMobile = {
  marginBottom: "1rem",
  marginRight: "12px",
};
export const drawerPaperMobile = {
  marginTop: "70px",
  backgroundColor: "#fff",
  width: "100%", // İstediğiniz genişliği buradan ayarlayabilirsiniz
  boxShadow: "none",
};

export const boxesFrameMobile = {
  display: "flex",
  flexDirection: "row",
  borderRadius: "12px",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fafafa",
  width: "343px",
  height: "48px",
  alignSelf: "center",
  marginTop: "24px",
};

export const boxMobileStyle = {
  borderRadius: "8px",
  width: "163px",
  height: "40px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  border: "0.001px solid rgba(255,255,255,0)",
  margin: "4px",
};
