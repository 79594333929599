export const threecontainer = {
  display: "flex",
  flexDirection: { xs: "column", sm: "row", md: "row" },
  marginTop: { xs: "56px", sm: "158px", md: "158px" },
  alignSelf: { xs: "flex-start", sm: "center", md: "center" },
  marginLeft: { xs: "18px", sm: null, md: null },
};

export const frame1 = {
  marginRight: { xs: "0px", sm: "189px", md: "189px" },
  marginBottom: { xs: "40px", sm: null, md: null },
  display: { xs: "flex", sm: null, md: null },
  flexDirection: { xs: "row", sm: "column", md: "column" },
  alignItems: { xs: "center", sm: "flex-start", md: "flex-start" },
};

export const frame2 = {
  display: { xs: "flex", sm: null, md: null },
  flexDirection: { xs: "row", sm: "column", md: "column" },
  alignItems: { xs: "center", sm: "flex-start", md: "flex-start" },
};

export const title = {
  fontFamily: "Semibold",
  lineHeight: { xs: "12px", sm: "36.4px", md: "36.4px" },
  color: "#383838",
  fontSize: { xs: "20px", sm: "28px", md: "28px" },
  marginTop: { xs: "0px", sm: "28px", md: "28px" },
};

export const text = {
  fontFamily: "Regular",
  color: "#383838",
  width: { xs: "200px", sm: "264px", md: "264px" },
  lineHeight: "28px",
  fontSize: { xs: "14px", sm: "18px", md: "18px" },
  marginTop: "12px",
  textAlign: "justify",
};
