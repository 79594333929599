export const phonetextFrame = {
  display: "flex",
  flexDirection: { xs: "column", sm: "row", md: "row" },
  justifyContent: "center",
  alignItems: "center",
  marginBottom: 20,
};

export const title = {
  fontFamily: "Semibold",
  color: "#383838",
  marginBottom: "16px",
  lineHeight: { xs: "31.2px", sm: "64px", md: "64px" },
  fontSize: { xs: "24px", sm: "48px", md: "48px" },
};

export const subText = {
  fontFamily: "Regular",
  lineHeight: { xs: "20px", sm: "28px", md: "28px" },
  color: "#383838",
  fontSize: { xs: "14px", sm: "18px", md: "18px" },
  width: { xs: "343px", sm: "570px", md: "570px" },
  marginBottom: "24px",
  textAlign: "justify",
};

export const subTitle = {
  fontFamily: "Medium",
  lineHeight: "26px",
  color: "#383838",
  fontSize: { xs: "16px", sm: "18px", md: "18px" },
};

export const frame = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginTop: "24px",
};

export const imgBox = {
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  marginTop: "275px",
  position: "relative",
};

export const imgStyle = { width: "329px", height: "677px" };
export const imgMobileSt = {
  position: "absolute",
  alignSelf: "center",
  marginBottom: "350px",
  objectFit: "cover",
  objectPosition: "top",
  zIndex: 1,
};

export const blurStyle = {
  height: "40px",
  width: "373px",
  display: "flex",
  zIndex: 44,
  top: "-60px",
  position: "absolute",
  backgroundColor: "white",
  filter: "blur(10px)",
};
