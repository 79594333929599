import * as React from "react";
const BlackCheck = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#383838"
      stroke="#FFFCF3"
      strokeWidth={0.375}
      d="M17.255 8.522c.107.107.192.234.25.373l.173-.072-.173.072a1.145 1.145 0 0 1-.25 1.251l-6.222 6.223a1.146 1.146 0 0 1-1.251.25l-.072.172.072-.173a1.147 1.147 0 0 1-.373-.25l-2.666-2.666a1.149 1.149 0 0 1 1.623-1.624L10.09 13.8l.132.133.133-.133 5.278-5.28a1.146 1.146 0 0 1 1.622.003ZM21.52 12A9.521 9.521 0 1 0 12 21.52 9.53 9.53 0 0 0 21.52 12Zm2.291 0A11.812 11.812 0 1 1 12 .187 11.826 11.826 0 0 1 23.813 12Z"
    />
  </svg>
);
export default BlackCheck;
