import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import {
  ArrowDown,
  BlackCheck,
  VectorIcon,
  JobOpport,
} from "../../../assets/svgs/GoldtagGatewaySvgs";
import { pad, subBox, title, titleContainer } from "./DropDownInfoCSS";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

function DropDownInfo(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);

  const boxRef1 = useRef(null);

  const boxRef2 = useRef(null);
  const boxRef3 = useRef(null);
  const boxRef4 = useRef(null);
  const boxRef5 = useRef(null);
  const boxRef6 = useRef(null);
  const boxRef7 = useRef(null);

  useGSAP(() => {
    gsap.from(boxRef1.current, {
      opacity: 0,
      x: 300,
      duration: 1,
      delay: 0.5,
      scrollTrigger: {
        trigger: boxRef1.current,
        start: "center 100%",
        toggleActions: "play none none none",
      },
    });

    gsap.from(boxRef7.current, {
      opacity: 0,
      y: 200,
      duration: 1,
      delay: 1,
      ease: "power4.out",
      scrollTrigger: {
        trigger: boxRef7.current,
        start: "center bottom",
        toggleActions: "play none none none",
      },
    });

    gsap.from(boxRef2.current, {
      opacity: 0,
      x: -300,
      delay: 0.2,
      ease: "power3.in",
      scrollTrigger: {
        trigger: boxRef2.current,
        start: "center bottom",
        toggleActions: "play none none none",
      },
    });
    gsap.from(boxRef3.current, {
      opacity: 0,
      x: -300,
      delay: 0.4,
      ease: "power3.in",

      scrollTrigger: {
        trigger: boxRef4.current,
        start: "center bottom",
        toggleActions: "play none none none",
      },
    });
    gsap.from(boxRef4.current, {
      opacity: 0,
      x: -300,
      delay: 0.6,
      ease: "power3.in",
      scrollTrigger: {
        trigger: boxRef4.current,
        start: "center bottom",
        toggleActions: "play none none none",
      },
    });
    gsap.from(boxRef5.current, {
      opacity: 0,
      x: -300,
      delay: 0.8,
      ease: "power3.in",

      scrollTrigger: {
        trigger: boxRef5.current,
        start: "center bottom",
        toggleActions: "play none none none",
      },
    });
    gsap.from(boxRef6.current, {
      opacity: 0,
      x: -300,
      delay: 1,
      ease: "power3.in",
      scrollTrigger: {
        trigger: boxRef6.current,
        start: "center bottom",
        toggleActions: "play none none none",
      },
    });
  });

  return (
    <>
      <Box
        sx={{
          width: isMobile ? "343px" : null,
          marginTop: isMobile ? "56px" : "0px",
          alignSelf: "center",
        }}
      >
        <Box sx={titleContainer} ref={boxRef1}>
          <Typography sx={title}>
            Goldtag Gateway ile size çeşitli iş alanları sunarak farklı çalışma
            imkanları sağlıyoruz
          </Typography>
          <Box style={{ paddingRight: isMobile ? 5 : 0 }}>
            <VectorIcon />
          </Box>
        </Box>
        <Box sx={subBox}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: isMobile ? "343px" : null,
            }}
          >
            {/* kıymetli madenler */}
            <Box sx={pad} ref={boxRef2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid #E8E8E8",
                  width: isMobile ? "343px" : "570px",
                  cursor: "pointer",
                }}
                onClick={() => setOpen1(!open1)}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: isMobile ? "343px" : null,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Semibold",
                      fontSize: isMobile ? "18px" : "20px",
                      lineHeight: "24px",
                      color: open1 == true ? "#D6B87C" : "#383838",
                      marginBottom: isMobile ? "16px" : "24px",
                    }}
                  >
                    Kıymetli Madenler
                  </Typography>
                  {open1 == false ? (
                    <Box sx={{ marginLeft: "24px", cursor: "pointer" }}>
                      <ArrowDown />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        marginLeft: "24px",
                        cursor: "pointer",
                        transform: "rotate(180deg)",

                        height: 24,
                      }}
                    >
                      <ArrowDown color={"#D6B87C"} />
                    </Box>
                  )}
                </Box>

                {open1 == true && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "20px",
                      }}
                    >
                      <BlackCheck />
                      <Typography
                        sx={{
                          fontFamily: "Medium",
                          fontSize: isMobile ? "14px" : "18px",
                          lineHeight: isMobile ? "20px" : "24px",
                          color: "#383838",
                          marginLeft: isMobile ? "12px" : "20px",
                        }}
                      >
                        7/24 Uygun Kâr
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "20px",
                      }}
                    >
                      <BlackCheck />

                      <Typography
                        sx={{
                          fontFamily: "Medium",
                          fontSize: isMobile ? "14px" : "18px",
                          lineHeight: isMobile ? "20px" : "24px",
                          color: "#383838",
                          marginLeft: isMobile ? "12px" : "20px",
                        }}
                      >
                        Bankacılık Altyapısı
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "20px",
                      }}
                    >
                      <BlackCheck />

                      <Typography
                        sx={{
                          fontFamily: "Medium",
                          fontSize: isMobile ? "14px" : "18px",
                          lineHeight: isMobile ? "20px" : "24px",
                          color: "#383838",
                          marginLeft: isMobile ? "12px" : "20px",
                        }}
                      >
                        Parçalı Alım-Satım
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "20px",
                      }}
                    >
                      <BlackCheck />

                      <Typography
                        sx={{
                          fontFamily: "Medium",
                          fontSize: isMobile ? "14px" : "18px",
                          lineHeight: isMobile ? "20px" : "24px",
                          color: "#383838",
                          marginLeft: isMobile ? "12px" : "20px",
                        }}
                      >
                        Alım-Satım ve Mütabakat API’leri
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "20px",
                      }}
                    >
                      <BlackCheck />

                      <Typography
                        sx={{
                          fontFamily: "Medium",
                          fontSize: isMobile ? "14px" : "18px",
                          lineHeight: isMobile ? "20px" : "24px",
                          color: "#383838",
                          marginLeft: isMobile ? "12px" : "20px",
                        }}
                      >
                        1-1 Oranda Saklama
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Box>

            {/* Tarım Emtiaları */}
            <Box sx={pad} ref={boxRef3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid #E8E8E8",
                  width: isMobile ? "343px" : "570px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpen1(false);
                  setOpen3(false);
                  setOpen4(false);
                  setOpen5(false);
                  setOpen2(!open2);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Semibold",
                      fontSize: isMobile ? "18px" : "20px",
                      lineHeight: "24px",
                      color: open2 == true ? "#D6B87C" : "#383838",
                      marginBottom: isMobile ? "16px" : "24px",
                    }}
                  >
                    Tarım Emtiaları
                  </Typography>
                  {/* {open2 == false ? (
                    <Box sx={{ marginLeft: "24px", cursor: "pointer" }}>
                      <ArrowDown />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        marginLeft: "24px",
                        cursor: "pointer",
                        transform: "rotate(180deg)",

                        height: 24,
                      }}
                    >
                      <ArrowDown color={"#D6B87C"} />
                    </Box>
                  )} */}
                </Box>

                {open2 == true && <></>}
              </Box>
            </Box>

            {/* Yapay Zeka */}
            <Box sx={pad} ref={boxRef4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid #E8E8E8",
                  width: isMobile ? "343px" : "570px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpen1(false);
                  setOpen3(!open3);
                  setOpen4(false);
                  setOpen5(false);
                  setOpen2(false);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Semibold",
                      fontSize: isMobile ? "18px" : "20px",
                      lineHeight: "24px",
                      color: open3 == true ? "#D6B87C" : "#383838",
                      marginBottom: isMobile ? "16px" : "24px",
                    }}
                  >
                    Yapay Zeka
                  </Typography>
                  {/* {open3 == false ? (
                    <Box sx={{ marginLeft: "24px", cursor: "pointer" }}>
                      <ArrowDown />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        marginLeft: "24px",
                        cursor: "pointer",
                        transform: "rotate(180deg)",

                        height: 24,
                      }}
                    >
                      <ArrowDown color={"#D6B87C"} />
                    </Box>
                  )} */}
                </Box>

                {open3 == true && <></>}
              </Box>
            </Box>

            {/* Blockchain */}
            <Box sx={pad} ref={boxRef5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid #E8E8E8",
                  width: isMobile ? "343px" : "570px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpen1(false);
                  setOpen3(false);
                  setOpen4(!open4);
                  setOpen5(false);
                  setOpen2(false);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Semibold",
                      fontSize: isMobile ? "18px" : "20px",
                      lineHeight: "24px",
                      color: open4 == true ? "#D6B87C" : "#383838",
                      marginBottom: isMobile ? "16px" : "24px",
                    }}
                  >
                    Blockchain - Tokenizasyon
                  </Typography>
                  {/* {open4 == false ? (
                    <Box sx={{ marginLeft: "24px", cursor: "pointer" }}>
                      <ArrowDown />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        marginLeft: "24px",
                        cursor: "pointer",
                        transform: "rotate(180deg)",

                        height: 24,
                      }}
                    >
                      <ArrowDown color={"#D6B87C"} />
                    </Box>
                  )} */}
                </Box>

                {open4 == true && <></>}
              </Box>
            </Box>

            {/* Oyun */}
            <Box sx={pad} ref={boxRef6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom: "1px solid #E8E8E8",
                  width: isMobile ? "343px" : "570px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpen1(false);
                  setOpen3(false);
                  setOpen4(false);
                  setOpen2(false);
                  setOpen5(!open5);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Semibold",
                      fontSize: isMobile ? "18px" : "20px",
                      lineHeight: "24px",
                      color: open5 == true ? "#D6B87C" : "#383838",
                      marginBottom: isMobile ? "16px" : "24px",
                    }}
                  >
                    Oyun
                  </Typography>
                  {/* {open5 == false ? (
                    <Box sx={{ marginLeft: "24px", cursor: "pointer" }}>
                      <ArrowDown />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        marginLeft: "24px",
                        cursor: "pointer",
                        transform: "rotate(180deg)",

                        height: 24,
                      }}
                    >
                      <ArrowDown color={"#D6B87C"} />
                    </Box>
                  )} */}
                </Box>

                {open5 == true && <></>}
              </Box>
            </Box>
          </Box>
          {!isMobile && (
            <Box style={{ marginLeft: "112px" }} ref={boxRef7}>
              <img
                src={
                  "https://goldtagimages.s3.eu-north-1.amazonaws.com/EnalT.webp"
                }
                alt="Goldtag Win"
                width="488"
                height="351"
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default DropDownInfo;
