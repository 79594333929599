export const aboutcont = {
  marginTop: "156px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "96px",
};

export const happyC = {
  fontFamily: "Semibold",
  fontSize: "48px",
  fontWeight: 600,
  lineHeight: "62.4px",
  color: "#383838",
  marginBottom: "48px",
  alignSelf: "center",
};

export const boxs = {
  height: "284px",
  marginBottom: "48px",
};

export const boxsT = {
  width: "323px",
  height: "284px",
  borderRadius: "16px",
  border: "0.244px solid #EAE5DE",
  boxShadow:
    "0px 0px 22px 0px rgba(182,162,117,0.15) inset, 0.4878px 0.4878px 4.87805px 0px rgba(173, 154, 117, 0.12)",
  paddingLeft: "32px",
};

export const boxti = {
  fontSize: "18px",
  fontFamily: "Semibold",
  lineHeight: "28px",
  color: "#383838",
  marginBottom: "8px",
  marginTop: "40px",
};

export const subt = {
  fontSize: "16px",
  fontFamily: "Regular",
  lineHeight: "28px",
  color: "#383838",
  width: "298px",
  marginBottom: "24px",
};

export const nextIcon = {
  width: "40px",
  height: "40px",
  cursor: "pointer",
  borderRadius: "20px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
};

export const mobConS = {
  marginTop: "88px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: "96px",
};

export const biz = {
  fontFamily: "Semibold",
  fontSize: "20px",
  width: "323px",
  fontWeight: 600,
  lineHeight: "62.4px",
  color: "#383838",
  marginBottom: "16px",
  alignSelf: "center",
};

export const logoCon = {
  display: "flex",
  backgroundColor: "white",
  width: "60px",
  height: "60px",
  borderRadius: "99px",
  alignItems: "center",
  justifyContent: "center",
};

export const flexrowcnt = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};
