import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";

import KullaniciAydinlatmaMetni from "../../assets/pdfs/KullanıcıAydınlatmaMetnibu.pdf";
import GoldtagKullaniciSozlesmesi from "../../assets/pdfs/KullaniciSozlesmesiLatest.pdf";
import { title16, title20 } from "../../assets/styles/defaultFonts";

import DownloadIcon from "@mui/icons-material/Download";
import PageHeader from "../../components/PageHeader/PageHeader";
import { pageContainer } from "../../assets/styles/GeneralStyles";
import QR from "../../components/QR/QR";
import { Helmet } from "react-helmet-async";

function KullaniciSozlesmesi(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Goldtag | Kullanıcı Sözleşmesi </title>
        <meta
          name="keywords"
          content="Goldtag,App,Kullanıcı,Sözleşme,Mütabakat"
        />
      </Helmet>{" "}
      <Box sx={pageContainer}>
        <PageHeader title={"Anasayfa > Kullanıcı Sözleşmesi"} />
        {isMobile ? (
          <>
            <Typography
              sx={{
                fontSize: "24px",
                alignSelf: "center",
                textAlign: "center",
                marginTop: "32px",
                lineHeight: "67.2px",
                letterSpacing: "-1px",
                fontFamily: "SemiBold",
                color: "##383838",
              }}
            >
              Kullanıcı Sözleşmesi
            </Typography>

            <Box
              sx={{
                display: "flex",
                marginTop: "24px",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignSelf: "center",
                  fontFamily: "Regular",
                  color: "#383838",
                  marginBottom: "24px",
                  fontSize: "14px",
                  width: "343px",
                  textAlign: "center",
                }}
              >
                Goldtag uygulamamızı güvenle kullanabilmemiz için sizlere
                kullanıcı sözleşmemizi sunuyoruz.
              </Typography>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                  alignself: "center",
                }}
              >
                <a
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ebebeb",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "12px",
                    marginTop: "16px",
                    cursor: "pointer",
                    textDecoration: "none",
                    padding: "16px",
                    width: "240px",
                    alignSelf: "center",
                  }}
                  href={KullaniciAydinlatmaMetni}
                  download="KullaniciAydinlatmaMetni"
                  target="_blank"
                  rel="noreferrer"
                >
                  <a
                    href={KullaniciAydinlatmaMetni}
                    download="KullaniciAydinlatmaMetni"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#383838",
                      fontFamily: "Regular",
                      fontSize: "14px",
                    }}
                    rel="noreferrer"
                  >
                    Kullanıcı Aydınlatma Metni
                  </a>
                  <Box style={{ display: "flex" }}>
                    <Typography
                      sx={{
                        color: "#383838",
                        fontSize: "12px",
                        fontFamily: "Regular",
                        marginRight: "0.5rem",
                      }}
                    >
                      İndir
                    </Typography>
                    <DownloadIcon
                      sx={[
                        title20,
                        {
                          color: "#D6B87C",
                        },
                      ]}
                    />
                  </Box>
                </a>
                <a
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ebebeb",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "12px",
                    marginTop: "16px",
                    cursor: "pointer",
                    textDecoration: "none",
                    width: "240px",
                    alignSelf: "center",
                    padding: "16px",
                  }}
                  href={GoldtagKullaniciSozlesmesi}
                  download="GoldtagKullaniciSozlesmesi"
                  target="_blank"
                  rel="noreferrer"
                >
                  <a
                    href={GoldtagKullaniciSozlesmesi}
                    download="GoldtagKullaniciSozlesmesi"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#383838",
                      fontFamily: "Regular",
                      fontSize: "14px",
                    }}
                    rel="noreferrer"
                  >
                    Kullanıcı Aydınlatma Metni
                  </a>
                  <Box style={{ display: "flex" }}>
                    <Typography
                      sx={{
                        color: "#383838",
                        fontSize: "12px",
                        fontFamily: "Regular",
                        marginRight: "0.5rem",
                      }}
                    >
                      İndir
                    </Typography>
                    <DownloadIcon
                      sx={[
                        title20,
                        {
                          color: "#D6B87C",
                        },
                      ]}
                    />{" "}
                  </Box>
                </a>
              </Box>
            </Box>
          </>
        ) : (
          <Box sx={{ marginBottom: "72px" }}>
            <Typography
              sx={{
                fontSize: "56px",
                alignSelf: "center",
                textAlign: "center",
                marginTop: "56px",
                lineHeight: "67.2px",
                letterSpacing: "-2px",
                fontFamily: "SemiBold",
                color: "##383838",
              }}
            >
              Kullanıcı Sözleşmesi
            </Typography>

            <Box
              sx={{
                display: "flex",
                margin: "0px 390px",
                marginTop: "32px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignSelf: "center",
                  fontFamily: "Regular",
                  color: "#383838",
                  marginBottom: "24px",
                  textAlign: "center",
                }}
              >
                Goldtag uygulamamızı güvenle kullanabilmemiz için sizlere
                kullanıcı sözleşmemizi sunuyoruz.
              </Typography>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2rem",
                  alignself: "center",
                }}
              >
                <a
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ebebeb",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "12px",
                    marginTop: "16px",
                    cursor: "pointer",
                    textDecoration: "none",
                    width: "373px",
                    padding: "16px",
                  }}
                  href={GoldtagKullaniciSozlesmesi}
                  download="GoldtagKullaniciSozlesmesi"
                  target="_blank"
                  rel="noreferrer"
                >
                  <a
                    href={GoldtagKullaniciSozlesmesi}
                    download="GoldtagKullaniciSozlesmesi"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#383838",
                      fontFamily: "Regular",
                      fontSize: "24px",
                    }}
                    rel="noreferrer"
                  >
                    Kullanıcı Sözleşmesi
                  </a>
                  <Box style={{ display: "flex" }}>
                    <Typography
                      sx={{
                        color: "#383838",
                        fontSize: "14px",
                        fontFamily: "Regular",
                        marginRight: "0.5rem",
                      }}
                    >
                      İndir
                    </Typography>
                    <DownloadIcon
                      sx={[
                        title20,
                        {
                          color: "#D6B87C",
                        },
                      ]}
                    />{" "}
                  </Box>
                </a>
                <a
                  style={{
                    borderRadius: "8px",
                    border: "1px solid #ebebeb",
                    backgroundColor: "#fff",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "12px",
                    marginTop: "16px",
                    cursor: "pointer",
                    textDecoration: "none",
                    width: "373px",
                    padding: "16px",
                  }}
                  href={KullaniciAydinlatmaMetni}
                  download="KullaniciAydinlatmaMetni"
                  target="_blank"
                  rel="noreferrer"
                >
                  <a
                    href={KullaniciAydinlatmaMetni}
                    download="KullaniciAydinlatmaMetni"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#383838",
                      fontFamily: "Regular",
                      fontSize: "24px",
                    }}
                    rel="noreferrer"
                  >
                    Kullanıcı Aydınlatma Metni
                  </a>
                  <Box style={{ display: "flex" }}>
                    <Typography
                      sx={{
                        color: "#383838",
                        fontSize: "14px",
                        fontFamily: "Regular",
                        marginRight: "0.5rem",
                      }}
                    >
                      İndir
                    </Typography>
                    <DownloadIcon
                      sx={[
                        title20,
                        {
                          color: "#D6B87C",
                        },
                      ]}
                    />
                  </Box>
                </a>
              </Box>
            </Box>
          </Box>
        )}

        <QR />
      </Box>
    </>
  );
}

export default KullaniciSozlesmesi;
