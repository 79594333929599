export const infocontainer = {
  width: { xs: "343px", sm: "1980px", md: "null" },
  display: "flex",
};

export const titleContainer = {
  display: "flex",
  flexDirection: "row",
  alignSelf: "center",
  justifyContent: "flex-start",
  alignItems: "flex-end",
  marginBottom: "32px",
  paddingLeft: { xs: null, sm: "135px", md: "135px" },
};

export const title = {
  fontFamily: "Semibold",
  fontSize: { xs: "24px", sm: "48px", md: "48px" },
  lineHeight: { xs: "31px", sm: "62px", md: "62px" },
  color: "#383838",
  alignSelf: "center",
  width: { xs: "343px", sm: "1000px", md: "1000px" },
};

export const subBox = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexDirection: { xs: "column", sm: "row", md: "row" },
  marginBottom: { xs: "24px", sm: "48px", md: "48px" },
};

export const pad = {
  paddingLeft: { xs: null, sm: "135px", md: "135px" },
  marginBottom: "24px",
};
