import * as React from "react";
const Minilogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="#383838"
      d="M35.346 23.327H36.5a16.861 16.861 0 0 1-6.92 10.515 17.389 17.389 0 0 1-3.192 1.732c-1.03.422-2.1.74-3.194.95a16.84 16.84 0 0 1-6.387 0 16.853 16.853 0 0 1-8.704-4.623 16.836 16.836 0 0 1-4.626-8.698 16.812 16.812 0 0 1 0-6.384 16.8 16.8 0 0 1 4.62-8.704 16.815 16.815 0 0 1 8.71-4.618A16.516 16.516 0 0 1 20 3.192a16.69 16.69 0 0 1 3.115.271v1.008a18.75 18.75 0 0 0-3.205-.228 16.447 16.447 0 0 0-3.001.283 15.825 15.825 0 0 0-11.608 9.496 15.558 15.558 0 0 0-.895 3 15.97 15.97 0 0 0 0 5.987 15.784 15.784 0 0 0 12.5 12.494c.99.192 1.994.286 3.002.282a16.344 16.344 0 0 0 3.001-.282 15.796 15.796 0 0 0 12.434-12.179l.002.003Z"
    />
    <path
      fill="#D6B87C"
      d="M23.012 20.136v3.191H36.5a16.86 16.86 0 0 1-6.92 10.518 16.777 16.777 0 0 1-6.387 2.683c-2.109.407-4.277.407-6.387 0a16.837 16.837 0 0 1-13.33-13.322 16.812 16.812 0 0 1 0-6.384 16.8 16.8 0 0 1 4.621-8.704 16.815 16.815 0 0 1 8.71-4.619A16.517 16.517 0 0 1 20 3.194a16.69 16.69 0 0 1 3.114.271c.046.012.091.012.137.023L23.194.251A21.209 21.209 0 0 0 20 .001 19.949 19.949 0 0 0 5.84 5.843 19.93 19.93 0 0 0 .248 16.808 21.172 21.172 0 0 0 0 20.001c-.003 1.068.08 2.136.249 3.191a20.013 20.013 0 0 0 5.61 10.954 20.033 20.033 0 0 0 10.96 5.605c1.056.163 2.124.247 3.193.249a19.83 19.83 0 0 0 3.193-.249 18.795 18.795 0 0 0 3.194-.781 19.975 19.975 0 0 0 13.353-15.643.348.348 0 0 0 .01-.113c.154-1.02.233-2.049.238-3.08l-16.988.002Z"
    />
    <path
      fill="#383838"
      d="M23.012 11.805h-3.194v3.191h3.194v-3.191ZM29.412 23.326h-3.193v3.192h3.193v-3.192Z"
    />
    <path
      fill="#D6B87C"
      d="M36.695 18.19H18.212a1.42 1.42 0 0 1-1.427-1.427v-.214a1.54 1.54 0 0 1 1.54-1.54h17.678c.207.639.373 1.289.498 1.947.08.397.148.816.194 1.234ZM23.014 20.135v3.191H18.46a1.673 1.673 0 0 1-1.676-1.675v-.011a1.504 1.504 0 0 1 1.506-1.505h4.723Z"
    />
    <path
      fill="#383838"
      d="M29.412 11.805h-3.193v8.33h3.193v-8.33ZM23.012 18.19h-3.194v8.33h3.194v-8.33Z"
    />
  </svg>
);
export default Minilogo;
