import { Box, Typography } from "@mui/material";
import React, { useRef } from "react";
import { headerBox, headerTitle } from "./PageHeaderCSS";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

function PageHeader(props) {
  const ref = useRef(null);

  useGSAP(() => {
    gsap.from(ref.current, {
      opacity: 0,
      y: -200,
      duration: 1,
      ease: "bounce.out",
    });
  });

  return (
    <Box
      sx={[headerBox, { width: props.width ? props.width : null }]}
      ref={ref}
    >
      <Typography sx={headerTitle}>{props.title}</Typography>
    </Box>
  );
}

export default PageHeader;
