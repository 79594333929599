import * as React from "react";
const BMail = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      stroke="#D6B87C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.963}
      d="M2.67 8.593A2.963 2.963 0 0 1 5.633 5.63h20.74a2.963 2.963 0 0 1 2.964 2.963v14.815a2.963 2.963 0 0 1-2.963 2.963H5.633a2.963 2.963 0 0 1-2.963-2.963V8.593Z"
    />
    <path
      stroke="#D6B87C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.963}
      d="m2.67 8.593 13.333 8.889 13.334-8.89"
    />
  </svg>
);
export default BMail;
