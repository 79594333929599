import { Box, Typography } from "@mui/material";
import "./App.css";
import Header from "./components/header/Header";
import Router from "./routes";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Header />
      <Router />
      <Footer />
    </Box>
  );
}

export default App;
