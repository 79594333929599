import React, { useState } from "react";
import { Typography, Box } from "@mui/material";

import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  Logoo,
  LOGO,
  Call,
  Mail,
  BMail,
  Call1,
  ArrowDownK,
  ArrowUpK,
  LinkedIn,
  Instagram,
} from "../../assets/svgs/FooterSvgs";
import {
  brdrtp,
  brysl,
  bsy,
  dvdr,
  flx,
  flxbsy,
  flxbsy2,
  flxclmn,
  flxclmn88,
  flxmarg,
  flxmarg2,
  flxtop,
  ftrcntmob,
  ftrcont,
  margnRg,
  mrgnpont,
} from "./FooterCSS.js";
import {
  title16semibold,
  titlte20regular,
} from "../../assets/styles/GeneralStyles.js";
import LogoMobile from "../../assets/svgs/LogoMobile.js";

function Footer() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(false);
  const title = {
    color: "#383838",
    fontFamily: "Semibold",
    lineHeight: isMobile ? "20.8px" : "23.4px",
    fontSize: isMobile ? "16px" : "18px",
    marginBottom: "4px",
  };

  const subtitle = {
    color: "#383838",
    fontFamily: "Semibold",
    lineHeight: isMobile ? "20.8px" : "40px",
    fontSize: "16px",
    marginBottom: "8px",
  };
  const subsubtitle = {
    color: "#383838",
    fontFamily: "Regular",
    lineHeight: "40px",
    fontSize: "14px",
    marginBottom: "4px",
    cursor: "pointer",
    width: "180px",
  };

  const subtitle1 = {
    color: "#383838",
    fontFamily: "Regular",
    lineHeight: isMobile ? "18.2px" : "20.8px",
    fontSize: isMobile ? "14px" : "16px",
  };

  return (
    <>
      {!isMobile ? (
        <Box sx={ftrcont}>
          <Box component="footer" sx={flx}>
            <Box sx={flxmarg}>
              <LogoMobile />

              <Box sx={flxmarg2}>
                <Box sx={{ marginRight: "16px" }}>
                  <Call />
                </Box>

                <Box>
                  <Typography sx={title}>Telefon</Typography>
                  <Typography sx={subtitle1}>0 (312) 911 3759</Typography>
                </Box>
              </Box>
              <Box sx={flxclmn}>
                <Box sx={{ marginRight: "16px", paddingBottom: "1.5rem" }}>
                  <BMail />
                </Box>

                <Box>
                  <Typography sx={title}>E-Posta</Typography>
                  <Typography sx={subtitle1}>info@goldtagapp.com</Typography>
                </Box>
              </Box>
            </Box>

            <Box sx={flxclmn88}>
              <Box>
                <Typography sx={subtitle}>Bireysel</Typography>
              </Box>
              <Box onClick={() => navigate("/Hakkimizda")}>
                <Typography sx={subsubtitle}>Hakkımızda</Typography>
              </Box>

              <Box onClick={() => navigate("/LogoVeMarkaKullanimKilavuzu")}>
                <Typography sx={subsubtitle}>Marka Kimliği</Typography>
              </Box>
              {/* <Box onClick={() => navigate("/BloglarVePodcastler")}>
                <Typography sx={subsubtitle}>Blog ve Podcastler</Typography>
              </Box> */}
              <Box onClick={() => navigate("/SikcaSorulanSorular")}>
                <Typography sx={subsubtitle}>Sıkça Sorulan Sorular</Typography>
              </Box>
              <Box onClick={() => navigate("/iletisim")}>
                <Typography sx={subsubtitle}>Bize Ulaşın</Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginRight: "138px",
              }}
            >
              <Box style={{ width: "50px" }}>
                <Typography sx={subtitle}>Diğer</Typography>
              </Box>

              <Box onClick={() => navigate("/GizlilikPolitikası")}>
                <Typography sx={subsubtitle}>Gizlilik Politikası</Typography>
              </Box>
              <Box onClick={() => navigate("/KullaniciSozlesmesi")}>
                <Typography sx={subsubtitle}>Kullanıcı Sözleşmesi</Typography>
              </Box>
              {/* <Box
                onClick={() => navigate("/Kurumsal/DenetimVeFaaliyetRaporları")}
              >
                <Typography sx={subsubtitle}>
                  Kişisel Verilerin Korunması
                </Typography>
              </Box> */}
              {/* <Box onClick={() => navigate("/Kurumsal/TicaretSicilBilgileri")}>
                <Typography sx={subsubtitle}>Yasal Uyarılar</Typography>
              </Box> */}
            </Box>
            <Box>
              <Typography sx={subtitle}>Bizi Takip Edin</Typography>
              <Box sx={flxtop}>
                <Box
                  sx={mrgnpont}
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/goldtag_tr",
                      "_blank"
                    )
                  }
                >
                  <Instagram />
                </Box>

                <Box
                  sx={mrgnpont}
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/goldtag",
                      "_blank"
                    )
                  }
                >
                  <LinkedIn />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={dvdr} />
          <Typography
            sx={[
              subtitle1,
              {
                display: "flex",
                justifyContent: "center",
              },
            ]}
          >
            © 2023 Fintag Yazılım Danışmanlık A.Ş
          </Typography>
        </Box>
      ) : (
        <Box sx={ftrcntmob}>
          <Box sx={{ marginLeft: "16px" }}>
            <LOGO />
          </Box>
          <Box sx={brdrtp}>
            <Box sx={margnRg}>
              <Box sx={bsy}>
                <Call1 />
              </Box>

              <Box>
                <Typography sx={title}>Telefon</Typography>
                <Typography sx={subtitle1}>0 (312) 911 3759</Typography>
              </Box>
            </Box>
            {/* iletişim boxu */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Box sx={bsy}>
                <Mail />
              </Box>

              <Box>
                <Typography sx={title}>E-Posta</Typography>
                <Typography sx={subtitle1}>info@goldtagapp.com</Typography>
              </Box>
            </Box>
            {/* açılan box-diğer */}
          </Box>

          <Box sx={flxbsy}>
            <Box sx={brysl} onClick={() => setOpen1(!open1)}>
              <Typography sx={title16semibold}>Bireysel</Typography>
              {open1 == false ? <ArrowDownK /> : <ArrowUpK />}
            </Box>
            {open1 && (
              <Box sx={{ marginTop: "16px" }}>
                <Typography
                  sx={[titlte20regular, { marginBottom: "20px" }]}
                  onClick={() => navigate("Hakkimizda")}
                >
                  Hakkımızda
                </Typography>
                <Typography
                  sx={[titlte20regular, { marginBottom: "20px" }]}
                  onClick={() => navigate("UcretlerVeLimitler")}
                >
                  Ücretler ve Limitler
                </Typography>
                <Typography
                  sx={[titlte20regular, { marginBottom: "20px" }]}
                  onClick={() => navigate("LogoVeMarkaKullanimKilavuzu")}
                >
                  Marka Kimliği
                </Typography>
                {/* <Typography
                  sx={{
                    marginBottom: "20px",
                    color: "#383838",
                    fontFamily: "Regular",
                    fontSize: "14px",
                    lineHeight: "18.2px",
                  }}
                  onClick={() => navigate("BloglarVePodcastler")}
                >
                  Blog ve Podcastler
                </Typography> */}
                <Typography
                  sx={[titlte20regular, { marginBottom: "20px" }]}
                  onClick={() => navigate("SikcaSorulanSorular")}
                >
                  Sıkça Sorulan Sorular
                </Typography>
                <Typography
                  sx={[titlte20regular, { marginBottom: "20px" }]}
                  onClick={() => navigate("Iletisim")}
                >
                  Bize Ulaşın
                </Typography>
              </Box>
            )}
          </Box>
          <Box sx={flxbsy2}>
            <Box sx={brysl} onClick={() => setOpen2(!open2)}>
              <Typography
                sx={{
                  fontFamily: "Semibold",
                  fontSize: "16px",
                  lineHeight: "20.8px",
                }}
              >
                Diğer
              </Typography>
              {open2 == false ? <ArrowDownK /> : <ArrowUpK />}
            </Box>
            {open2 && (
              <Box sx={{ marginTop: "16px" }}>
                <Typography sx={[titlte20regular, { marginBottom: "20px" }]}>
                  Genel Şartlar
                </Typography>
                <Typography
                  sx={[titlte20regular, { marginBottom: "20px" }]}
                  onClick={() => navigate("GizlilikPolitikası")}
                >
                  Gizlilik Politikası
                </Typography>
                <Typography
                  sx={[titlte20regular, { marginBottom: "20px" }]}
                  onClick={() => navigate("KullaniciSozlesmesi")}
                >
                  Kullanıcı Sözleşmesi
                </Typography>
                {/* <Typography
                  sx={{
                    marginBottom: "20px",
                    color: "#383838",
                    fontFamily: "Regular",
                    fontSize: "14px",
                    lineHeight: "18.2px",
                  }}
                >
                  Kişisel Verilerin Korunması
                </Typography> */}
                {/* <Typography
                  sx={{
                    marginBottom: "16px",
                    color: "#383838",
                    fontFamily: "Regular",
                    fontSize: "14px",
                    lineHeight: "18.2px",
                  }}
                >
                  Yasal Uyarılar
                </Typography> */}
              </Box>
            )}
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "16px",
            }}
          >
            <Typography sx={title16semibold}>Bizi Takip Edin</Typography>
            <Box sx={flxtop}>
              <Box
                sx={{ marginRight: "24px" }}
                onClick={() =>
                  window.open("https://www.instagram.com/goldtag_tr", "_blank")
                }
              >
                <Instagram />
              </Box>
              <Box
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/goldtag",
                    "_blank"
                  )
                }
              >
                <LinkedIn />
              </Box>
            </Box>
          </Box>

          <Typography
            sx={[
              subtitle1,
              {
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
                marginBottom: "24px",
                marginLeft: "16px",
              },
            ]}
          >
            © 2024 Fintag Yazılım Danışmanlık A.Ş
          </Typography>
        </Box>
      )}
    </>
  );
}

export default Footer;
