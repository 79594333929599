import * as React from "react";
const Yildiz = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#BAAA8A"
      d="M16 32c-.463-15.158-.842-15.534-16-16 15.158-.463 15.534-.842 16-16 .463 15.158.842 15.534 16 16-15.158.466-15.534.837-16 16Z"
    />
  </svg>
);
export default Yildiz;
