export const title56 = {
  fontSize: "56px",
  alignSelf: "center",
  textAlign: "center",
  width: "500px",
  marginTop: "56px",
  lineHeight: "67.2px",
  letterSpacing: "-2px",
  fontFamily: "SemiBold",
  color: "##383838",
};

export const subtit = {
  fontSize: "18px",
  alignSelf: "center",
  display: "flex",
  width: "970px",
  marginTop: "36px",
  lineHeight: "32px",
  fontFamily: "Regular",
  textAlign: "center",
};
export const logocon = {
  display: "flex",
  flexDirection: "row",
  alignSelf: "center",
  marginTop: "64px",
  gap: "30px",
};

export const logobox = {
  width: "270px",
  height: "348px",
  borderRadius: "16px",
  border: "0.224px solid #EAE5DE",
  boxShadow:
    "0px 0px 21.95122px 0px rgba(182, 162, 117, 0.15) inset, 0.4878px 0.4878px 4.87805px 0px rgba(173, 154, 117, 0.12)",
};

export const btn = {
  borderRadius: "8px",
  border: "1px solid #D6B87C",
  backgroundColor: "#fff",
  display: "flex",
  width: "96px",
  height: "36px",
  alignItems: "center",
  justifyContent: "center",
  gap: "12px",
  marginTop: "16px",
  cursor: "pointer",
};

export const imgBox = {
  width: "254px",
  height: "196px",
  borderRadius: "17.514px",
  backgroundColor: "#fff",
  margin: "8px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
};

export const imgstyle = {
  width: "162px",
  height: "56px",
};

export const frame1 = {
  display: "flex",
  width: "1160px",
  justifyContent: "center",
  alignItems: "center",
  gap: "74px",
  alignSelf: "center",
  flexDirection: "row",
  marginTop: "152px",
  marginBottom: "72px",
};

export const hatalitit = {
  fontSize: "48px",
  fontFamily: "SemiBold",
  letterSpacing: "-0.96px",
  color: "#383838",
  marginBottom: "16px",
};

export const mobtit = {
  fontSize: "24px",
  alignSelf: "center",
  textAlign: "center",
  width: "343px",
  letterSpacing: "-1px",
  fontFamily: "SemiBold",
  color: "##383838",
  marginBottom: "24px",
};

export const subtext = {
  fontSize: "14px",
  alignSelf: "center",
  display: "flex",
  width: "343px",
  lineHeight: "24px",
  fontFamily: "Regular",
  textAlign: "center",
};
