import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import PaparaMini from "../../assets/svgs/Minilogos/PaparaMini";
import { Minilogo } from "../../assets/svgs/GoldtagAppSvgs";
import {
  aboutcont,
  biz,
  boxs,
  boxsT,
  boxti,
  flexrowcnt,
  happyC,
  logoCon,
  mobConS,
  nextIcon,
  subt,
} from "./HappyCustomerCSS";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import ContactUs from "../ContactUs/ContactUs";
import { regular14, title18 } from "../../assets/styles/defaultFonts";
import { title16semibold } from "../../assets/styles/GeneralStyles";
import Moneypay from "../../assets/svgs/Minilogos/Moneypay";

function HappyCustomer(props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [startIndex, setStartIndex] = useState(0);
  const [counter, setCounter] = useState(0);

  const [items, setItems] = useState([
    {
      id: 1,
      view: (
        <Box sx={boxsT}>
          <Typography sx={boxti}>Hızlı Entegrasyon</Typography>
          <Typography sx={subt}>
            Sorunsuz ve hızlı entegrasyon ile emtia alım-satım servislerimizi
            kullanıcılarımızla buluşturdukları için Goldtag ekibine teşekkür
            ederiz.
          </Typography>
          <Box sx={flexrowcnt}>
            <Box sx={logoCon}>
              <PaparaMini />
            </Box>
            <Box sx={{ marginLeft: "16px" }}>
              <Typography sx={title16semibold}>Emre Kenci</Typography>
              <Typography sx={regular14}>CEO & Papara</Typography>
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      id: 2,
      view: (
        <Box sx={boxsT}>
          <Typography sx={boxti}>Finansal Opsiyonlar</Typography>
          <Typography sx={subt}>
            Müşteri portföyümüzü genişletmek için Goldtag ile çalışmaya
            başladık. Goldtag ekibine bize sağladıkları hizmetlerden dolayı
            teşekkür ederiz.
          </Typography>
          <Box sx={flexrowcnt}>
            <Box sx={logoCon}>
              <img
                src={
                  "https://goldtagimages.s3.eu-north-1.amazonaws.com/Group+44090.webp  "
                }
                alt="Goldtag Win"
                width="32"
                height="15"
              />{" "}
            </Box>
            <Box style={{ marginLeft: "16px" }}>
              <Typography sx={title16semibold}>Bülent Tekmen</Typography>
              <Typography sx={regular14}>Co-founder Colendi</Typography>
            </Box>
          </Box>
        </Box>
      ),
    },
    {
      id: 3,
      view: (
        <Box sx={boxsT}>
          <Box style={{ height: "172.5px" }}>
            <Typography sx={boxti}>7/24 Uygun Fiyatlar</Typography>
            <Typography sx={subt}>
              Goldtag ile çalışmaya başladığımızdan beri müşterilerimize 7/24
              uygun fiyatlarla hizmet veriyoruz.
            </Typography>
          </Box>

          <Box sx={flexrowcnt}>
            <Box sx={logoCon}>
              <Moneypay />
            </Box>

            <Box style={{ marginLeft: "16px" }}>
              <Typography sx={title16semibold}>Ahmet Can Durukan</Typography>
              <Typography sx={regular14}>Invest Lead & MoneyPay</Typography>
            </Box>
          </Box>
        </Box>
      ),
    },
  ]);

  const handleNextClick = () => {
    setStartIndex((prevIndex) =>
      prevIndex === items.length - 3 ? prevIndex : prevIndex + 3
    );
  };
  const handlePrevClick = () => {
    setStartIndex((prevIndex) => (prevIndex === 0 ? prevIndex : prevIndex - 3));
  };
  const isAtEnd = startIndex >= items.length - 3;

  const visibleItems = items.slice(startIndex, startIndex + 3);

  const boxRef1 = useRef(null);
  const boxRef2 = useRef(null);
  const boxRef3 = useRef(null);

  useGSAP(() => {
    gsap.from(boxRef1.current, {
      opacity: 0,
      x: 300,
      duration: 1,
      scrollTrigger: {
        trigger: boxRef1.current,
        start: "center 80%",
        toggleActions: "play none none none",
      },
    });
    gsap.from(boxRef2.current, {
      opacity: 0,
      y: 100,
      duration: 2,
      delay: 0.5,
      scrollTrigger: {
        trigger: boxRef2.current,
        start: "center 90%",
        toggleActions: "play none none none",
      },
    });
    gsap.from(boxRef3.current, {
      opacity: 0,
      x: -300,
      duration: 1,
      scrollTrigger: {
        trigger: boxRef3.current,
        start: "center 80%",
        toggleActions: "play none none none",
      },
    });
  }, []);

  return (
    <>
      {isMobile ? (
        <Box sx={mobConS}>
          <Box sx={{ marginBottom: "12px" }}>{/* <Minilogo /> */}</Box>

          <Typography sx={biz}>Bizi Tercih Eden Mutlu Müşteriler</Typography>
          <Box
            sx={{
              width: "323px",
              marginBottom: "48px",
              alignSelf: "center",
              display: "flex",
            }}
          >
            <Box key={items.id} sx={{ marginRight: "30px" }}>
              {items[counter].view}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "center",
            }}
          >
            <Box
              sx={[
                nextIcon,
                {
                  bgcolor: counter === 0 ? "#C6C6C6" : "#D6B87C",
                  marginRight: "24px",
                },
              ]}
            >
              <IconButton
                onClick={() => {
                  if (counter != 0) {
                    setCounter(counter + -1);
                  }
                }}
              >
                <NavigateBeforeIcon sx={{ color: "white", fontSize: "32px" }} />
              </IconButton>
            </Box>
            <Box
              sx={[
                nextIcon,
                {
                  bgcolor: counter == 2 ? "#C6C6C6" : "#D6B87C",
                },
              ]}
            >
              <IconButton
                onClick={() => {
                  if (counter != 2) {
                    setCounter(counter + 1);
                  }
                }}
              >
                <NavigateNextIcon sx={{ color: "white", fontSize: "32px" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={aboutcont}>
          <Box sx={{ marginBottom: "24px" }}>
            <Minilogo />
          </Box>

          <Typography sx={happyC}>Bizi Tercih Eden Mutlu Müşteriler</Typography>

          <Box display="flex" sx={boxs}>
            {visibleItems.map((item, index) => (
              <Box
                key={item.id}
                sx={{ marginRight: "30px" }}
                ref={index === 0 ? boxRef1 : index === 1 ? boxRef2 : boxRef3}
              >
                {item.view}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
}

export default HappyCustomer;
