export { default as ArrowDownK } from "./ArrowDown";
export { default as ArrowUpK } from "./ArrowUp";
export { default as BMail } from "./BMail";
export { default as Call } from "./Call";
export { default as Call1 } from "./Call1";
export { default as LinkedIn } from "./LinkedIn";
export { default as LOGO } from "./LOGO";
export { default as Logoo } from "./Logoo";
export { default as Mail } from "./Mail";
export { default as Instagram } from "./Instagram";
export { default as Contact } from "./Contact";
