import * as React from "react";
const Cancel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        stroke="#383838"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.286}
        d="M15.998 30.857c8.206 0 14.857-6.651 14.857-14.857 0-8.205-6.651-14.857-14.857-14.857C7.793 1.143 1.141 7.795 1.141 16c0 8.206 6.652 14.857 14.857 14.857Z"
      />
      <path
        fill="#383838"
        d="m19.623 21.372-3.622-3.623-3.623 3.623c-.229.23-.52.344-.874.344s-.646-.114-.875-.344c-.229-.229-.343-.52-.343-.874s.114-.646.343-.875L14.252 16l-3.623-3.623c-.229-.23-.343-.52-.343-.875 0-.354.114-.645.343-.874.23-.23.52-.344.875-.344.354 0 .645.115.874.344l3.623 3.623 3.622-3.623c.23-.23.52-.344.875-.344.354 0 .645.115.874.344.23.229.344.52.344.874s-.115.646-.344.875L17.75 16l3.622 3.623c.23.23.344.52.344.875 0 .354-.115.645-.344.874-.229.23-.52.344-.874.344s-.646-.114-.875-.344Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Cancel;
