import * as React from "react";
const Hamburger = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <g
      stroke="#383838"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.286}
      clipPath="url(#a)"
    >
      <path d="M15.999 30.857c8.205 0 14.857-6.651 14.857-14.857 0-8.205-6.652-14.857-14.857-14.857C7.793 1.143 1.142 7.795 1.142 16c0 8.206 6.651 14.857 14.857 14.857ZM10.284 10.286h11.429M10.284 16h11.429m-11.429 5.715h11.429" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Hamburger;
