import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef } from "react";

import logo from "../../assets/images/Logos/logo.png";
import logo2 from "../../assets/images/Logos/logo2.svg";
import logo3 from "../../assets/images/Logos/loqo3.svg";
import logo4 from "../../assets/images/Logos/logo4.svg";
import logoX from "../../assets/images/Logos/logoX.png";

import wrongimg from "../../assets/images/Logos/wrong-logo-img.png";

import { useNavigate } from "react-router-dom";

import {
  btn,
  frame1,
  hatalitit,
  imgBox,
  imgstyle,
  logobox,
  logocon,
  mobtit,
  subtext,
  subtit,
  title56,
} from "./LogoCSS";
import { title16semibold } from "../../assets/styles/GeneralStyles";
import {
  regular14,
  regular16,
  title16,
} from "../../assets/styles/defaultFonts";
import DownloadIcon from "@mui/icons-material/Download";
import { mobcontainer } from "../UcretlerLimitler/UcretlerVeLimitlerCSS";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

function LogoVeKullanimComp(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const handleDownloadClick = (item) => {
    const fileUrl = require(`../../assets/images/Logos/${item}`);

    const a = document.createElement("a");
    a.href = fileUrl;

    a.download = "logo";
    a.click();

    a.remove();
  };

  return (
    <>
      {isMobile ? (
        <Box style={mobcontainer}>
          <Typography sx={mobtit}>Logo ve Marka Kullanım Kılavuzu</Typography>

          <Typography sx={subtext}>
            Bu kılavuzda, Goldtag logosunun doğru kullanımı ve uygulamaları
            hakkında detaylı bilgiler bulunmaktadır. Logonun boyutları,
            renkleri, kullanım alanları ve çeşitli formatlardaki versiyonları
            hakkında ayrıntılı talimatlar sunulmaktadır. Ayrıca, logonun diğer
            tasarım unsurlarıyla uyumlu şekilde kullanılması için yönergeler ve
            örnekler de yer almaktadır.
          </Typography>

          <Typography
            sx={[subtext, { marginTop: "32px", marginBottom: "48px" }]}
          >
            Markamızı daha da büyütmek ve müşterilerimize en iyi hizmeti sunmak
            için sürekli olarak çalışmaktayız. Bu marka ve kurumsal kimlik
            kılavuzunu kullanarak, Goldtag'in değerlerini en iyi şekilde
            yansıtan ve markamızın gücünü ortaya koyan materyaller oluşturmanızı
            dileriz.
          </Typography>

          <Typography sx={mobtit}>Genel Logo Kullanımı ve Renkleri</Typography>

          <Typography sx={subtext}>
            Goldtag logosunun doğru kullanımı, boyutları orantılı şekilde
            korunmalı, renkleri değiştirilmeden kullanılmalı, üzerine efekt veya
            değişiklik yapılmamalı ve farklı formatlardaki versiyonları uygun
            durumlarda kullanılmalıdır.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "center",
              marginTop: "64px",
              gap: "30px",
              overflow: "auto",
              width: "343px",
              marginBottom: "72px",
            }}
          >
            <Box sx={logobox}>
              <Box sx={imgBox}>
                <img src={logo} alt="img" width={194} height={56} />
              </Box>
              <Box sx={{ marginLeft: "24px" }}>
                <Typography sx={title16semibold}>
                  Beyaz Zeminde Kullanım
                </Typography>
                <Typography sx={[regular14, { marginTop: "6px" }]}>
                  Format: .SVG,.PNG,JPEG
                </Typography>
                <button
                  style={btn}
                  onClick={() => handleDownloadClick("logo.png")}
                >
                  <DownloadIcon
                    sx={[
                      title16,
                      {
                        color: "#D6B87C",
                      },
                    ]}
                  />
                  <Typography
                    sx={[
                      regular14,
                      {
                        color: "#D6B87C",
                      },
                    ]}
                  >
                    İndir
                  </Typography>
                </button>
              </Box>
            </Box>
            <Box sx={logobox}>
              <Box sx={imgBox}>
                <img src={logo2} alt="img" style={{}} />
              </Box>
              <Box sx={{ marginLeft: "24px" }}>
                <Typography sx={title16semibold}>
                  Tek Renk Kullanım (Gold)
                </Typography>
                <Typography sx={[regular14, { marginTop: "6px" }]}>
                  Format: .SVG,.PNG,JPEG
                </Typography>
                <button
                  style={btn}
                  onClick={() => handleDownloadClick("logo2.svg")}
                >
                  {/* <DownloadIcon /> */}

                  <Typography
                    sx={{
                      color: "#D6B87C",
                      fontSize: "14px",
                      fontFamily: "Regular",
                    }}
                  >
                    İndir
                  </Typography>
                </button>
              </Box>
            </Box>
            <Box sx={logobox}>
              <Box sx={imgBox}>
                <img src={logo3} alt="img" style={imgstyle} />
              </Box>
              <Box sx={{ marginLeft: "24px" }}>
                <Typography sx={title16semibold}>
                  Tek Renk Kullanım (Siyah)
                </Typography>
                <Typography sx={[regular14, { marginTop: "6px" }]}>
                  Format: .SVG,.PNG,JPEG
                </Typography>
                <button
                  style={btn}
                  onClick={() => handleDownloadClick("loqo3.svg")}
                >
                  <DownloadIcon
                    sx={[
                      title16,
                      {
                        color: "#D6B87C",
                      },
                    ]}
                  />
                  <Typography
                    sx={[
                      regular14,
                      {
                        color: "#D6B87C",
                      },
                    ]}
                  >
                    İndir
                  </Typography>
                </button>
              </Box>
            </Box>{" "}
            <Box sx={logobox}>
              <Box sx={[imgBox, { backgroundColor: "#D6B87C" }]}>
                <img src={logo4} alt="img" style={imgstyle} />
              </Box>
              <Box sx={{ marginLeft: "24px" }}>
                <Typography sx={title16semibold}>Tek Renk Kullanım</Typography>
                <Typography sx={[regular14, { marginTop: "6px" }]}>
                  Format: .SVG,.PNG,JPEG
                </Typography>
                <button
                  style={btn}
                  onClick={() => handleDownloadClick("logo4.svg")}
                >
                  <DownloadIcon
                    sx={[
                      title16,
                      {
                        color: "#D6B87C",
                      },
                    ]}
                  />
                  <Typography
                    sx={[
                      regular14,
                      {
                        color: "#D6B87C",
                      },
                    ]}
                  >
                    İndir
                  </Typography>
                </button>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Typography sx={title56}>Logo ve Marka Kullanım Kılavuzu</Typography>

          <Typography sx={subtit}>
            Bu kılavuzda, Goldtag logosunun doğru kullanımı ve uygulamaları
            hakkında detaylı bilgiler bulunmaktadır. Logonun boyutları,
            renkleri, kullanım alanları ve çeşitli formatlardaki versiyonları
            hakkında ayrıntılı talimatlar sunulmaktadır. Ayrıca, logonun diğer
            tasarım unsurlarıyla uyumlu şekilde kullanılması için yönergeler ve
            örnekler de yer almaktadır.
          </Typography>

          <Typography sx={subtit}>
            Markamızı daha da büyütmek ve müşterilerimize en iyi hizmeti sunmak
            için sürekli olarak çalışmaktayız. Bu marka ve kurumsal kimlik
            kılavuzunu kullanarak, Goldtag'in değerlerini en iyi şekilde
            yansıtan ve markamızın gücünü ortaya koyan materyaller oluşturmanızı
            dileriz.
          </Typography>

          <Typography sx={[title56, { width: "100%", marginTop: "8rem" }]}>
            Genel Logo Kullanımı ve Renkleri
          </Typography>

          <Typography sx={subtit}>
            Goldtag logosunun doğru kullanımı, boyutları orantılı şekilde
            korunmalı, renkleri değiştirilmeden kullanılmalı, üzerine efekt veya
            değişiklik yapılmamalı ve farklı formatlardaki versiyonları uygun
            durumlarda kullanılmalıdır.
          </Typography>

          <Box sx={logocon}>
            <Box sx={logobox}>
              <Box sx={imgBox}>
                <img src={logoX} alt="img" width={194} height={56} />
              </Box>
              <Box sx={{ marginLeft: "24px" }}>
                <Typography sx={title16semibold}>
                  Beyaz Zeminde Kullanım
                </Typography>
                <Typography sx={[regular14, { marginTop: "6px" }]}>
                  Format: .SVG,.PNG,JPEG
                </Typography>
                <button
                  style={btn}
                  onClick={() => handleDownloadClick("logo.png")}
                >
                  <DownloadIcon
                    sx={[
                      title16,
                      {
                        color: "#D6B87C",
                      },
                    ]}
                  />

                  <Typography
                    sx={[
                      regular14,
                      {
                        color: "#D6B87C",
                      },
                    ]}
                  >
                    İndir
                  </Typography>
                </button>
              </Box>
            </Box>
            <Box sx={logobox}>
              <Box sx={imgBox}>
                <img src={logo2} alt="img" width={194} height={56} />
              </Box>
              <Box sx={{ marginLeft: "24px" }}>
                <Typography sx={title16semibold}>
                  Tek Renk Kullanım (Gold)
                </Typography>
                <Typography sx={[regular14, { marginTop: "6px" }]}>
                  Format: .SVG,.PNG,JPEG
                </Typography>
                <button
                  style={btn}
                  onClick={() => handleDownloadClick("logo2.svg")}
                >
                  <DownloadIcon
                    sx={[
                      title16,
                      {
                        color: "#D6B87C",
                      },
                    ]}
                  />

                  <Typography
                    sx={[
                      regular14,
                      {
                        color: "#D6B87C",
                      },
                    ]}
                  >
                    İndir
                  </Typography>
                </button>
              </Box>
            </Box>
            <Box sx={logobox}>
              <Box sx={imgBox}>
                <img src={logo3} alt="img" width={194} height={56} />
              </Box>
              <Box sx={{ marginLeft: "24px" }}>
                <Typography sx={title16semibold}>
                  Tek Renk Kullanım (Siyah)
                </Typography>
                <Typography sx={[regular14, { marginTop: "6px" }]}>
                  Format: .SVG,.PNG,JPEG
                </Typography>
                <button
                  style={btn}
                  onClick={() => handleDownloadClick("loqo3.svg")}
                >
                  <DownloadIcon
                    sx={[
                      title16,
                      {
                        color: "#D6B87C",
                      },
                    ]}
                  />
                  <Typography
                    sx={[
                      regular14,
                      {
                        color: "#D6B87C",
                      },
                    ]}
                  >
                    İndir
                  </Typography>
                </button>
              </Box>
            </Box>
            <Box sx={logobox}>
              <Box
                sx={[
                  imgBox,
                  {
                    backgroundColor: "#D6B87C",
                  },
                ]}
              >
                <img src={logo4} alt="img" width={194} height={56} />
              </Box>
              <Box sx={{ marginLeft: "24px" }}>
                <Typography sx={title16semibold}>Tek Renk Kullanım</Typography>
                <Typography sx={[regular14, { marginTop: "6px" }]}>
                  Format: .SVG,.PNG,JPEG
                </Typography>
                <button
                  style={btn}
                  onClick={() => handleDownloadClick("logo4.svg")}
                >
                  {/* <DownloadIcon /> */}

                  <Typography
                    sx={[
                      regular14,
                      {
                        color: "#D6B87C",
                      },
                    ]}
                  >
                    İndir
                  </Typography>
                </button>
              </Box>
            </Box>
          </Box>

          <Box sx={frame1}>
            <Box>
              <Typography sx={hatalitit}>Hatalı Logo Kullanımı</Typography>
              <Typography
                sx={[regular16, { width: "526px", color: "#5A5A5A" }]}
              >
                Goldtag logosu, sadece belirtilen kılavuzda tanımlandığı şekilde
                kullanılabilir ve herhangi bir değişiklik veya farklılık
                kesinlikle kabul edilemez. Bu sıkı kurallara uygun bir biçimde
                logoyu kullanarak, Goldtag’in benzersiz kimliğini koruyor ve
                markamızı güçlü bir şekilde temsil ediyoruz. Böylece
                müşterilerimize güven veriyor ve iş ortaklarımız arasında
                tutarlı bir imaj oluşturuyoruz.
              </Typography>
            </Box>

            <img src={wrongimg} alt="img" />
          </Box>
        </>
      )}
    </>
  );
}

export default LogoVeKullanimComp;
